<template>

  	<TopMenu headerText="Добавить организацию" />

	<MessageDialogue ref="messageDialogue" />
	<ConfirmDialogue ref="confirmDialogue" />

  	<div class="block">
  		<div class="block__header">
  			Договор
  		</div>
  		<div class="block__body">
  			<div class="field">
  				<div class="row start-xs middle-xs">
  					<div class="col-xs-12 col-sm-6 col-md-4">
  						<div class="label">
  							Номер договора
  						</div>
  					</div>
  					<div class="col-xs-12 col-sm-6 col-md-8">
  						<div class="form-group">
  							<input class="form-control" type="text" placeholder="Номер договора" v-model="contract.number">
  						</div>
  					</div>
  				</div>
  			</div>
  			<div class="field">
  				<div class="row start-xs middle-xs">
  					<div class="col-xs-12 col-sm-6 col-md-4">
  						<div class="label">
  							Общее количество лицензий
  						</div>
  					</div>
  					<div class="col-xs-12 col-sm-6 col-md-8">
  						<div class="form-group">
  							<input class="form-control" type="number" placeholder="Общее количество лицензий" v-model="contract.number_licenses">
  						</div>
  					</div>
  				</div>
  			</div>
  			<div class="field">
  				<div class="row start-xs middle-xs">
  					<div class="col-xs-12 col-sm-6 col-md-4">
  						<div class="label">
  							Дата подписания
  						</div>
  					</div>
  					<div class="col-xs-12 col-sm-6 col-md-8">
  						<div class="form-group">
  							<DatePicker v-model="contract.date" :label="'Дата подписания'"/>
  						</div>
  					</div>
  				</div>
  			</div>
  			<div class="field">
  				<div class="row start-xs middle-xs">
  					<div class="col-xs-12 col-sm-6 col-md-4">
  						<div class="label">
  							Подписанный документ
  						</div>
  					</div>
  					<div class="col-xs-12 col-sm-6 col-md-8">
  						<FilePicker v-model="contract.file" />
  					</div>
  				</div>
  			</div>
  			<hr />
  			<div class="field">
  				<div class="row start-xs middle-xs">
  					<div class="col-xs-12 col-sm-6 col-md-4">
  						<div class="label">
  							Наименование заказчика
  						</div>
  					</div>
  					<div class="col-xs-12 col-sm-6 col-md-8">
		            	<DadataPicker label="Начните ввод" type="party" @setValue="setCustomer"/>
  					</div>
  				</div>
  			</div>
  			<div class="field field--data">
  				<div class="row start-xs middle-xs">
  					<div class="col-xs-12 col-sm-6 col-md-4">
  						<div class="label">
  							ИНН заказчика
  						</div>
  					</div>
  					<div class="col-xs-12 col-sm-6 col-md-8">
  						<div class="label"> {{ contract.inn == null ? '-' : contract.inn }} </div>
  					</div>
  				</div>
  			</div>
  		</div>
  	</div>


  	<div class="block">
  		<div class="block__header">
  			Организация
  		</div>
  		<div class="block__body">
  			<div class="field">
  				<div class="row start-xs middle-xs">
  					<div class="col-xs-12 col-sm-6 col-md-4">
  						<div class="label">
  							Наименование организации
  						</div>
  					</div>
  					<div class="col-xs-12 col-sm-6 col-md-8">
		            	<DadataPicker label="Начните ввод" type="party" @setValue="setOrg"/>
  					</div>
  				</div>
  			</div>
  			<div class="field field--data">
  				<div class="row start-xs middle-xs">
  					<div class="col-xs-12 col-sm-6 col-md-4">
  						<div class="label">
  							ОГРН
  						</div>
  					</div>
  					<div class="col-xs-12 col-sm-6 col-md-8">
  						<div class="label"> {{ organization.ogrn == null ? '-' : organization.ogrn }} </div>
  					</div>
  				</div>
  			</div>
  			<div class="field field--data">
  				<div class="row start-xs middle-xs">
  					<div class="col-xs-12 col-sm-6 col-md-4">
  						<div class="label">
  							ИНН
  						</div>
  					</div>
  					<div class="col-xs-12 col-sm-6 col-md-8">
  						<div class="label"> {{ organization.inn == null ? '-' : organization.inn }} </div>
  					</div>
  				</div>
  			</div>
  			<div class="field field--data">
  				<div class="row start-xs middle-xs">
  					<div class="col-xs-12 col-sm-6 col-md-4">
  						<div class="label">
  							КПП
  						</div>
  					</div>
  					<div class="col-xs-12 col-sm-6 col-md-8">
  						<div class="label"> {{ organization.kpp == null ? '-' : organization.kpp }} </div>
  					</div>
  				</div>
  			</div>
  		</div>
  	</div>

  	<div class="block">
  		<div class="block__header">
  			Руководитель
  		</div>
  		<div class="block__body">
  			<div class="field">
  				<div class="row start-xs middle-xs">
  					<div class="col-xs-12 col-sm-6 col-md-4">
  						<div class="label">
  							Фамилия
  						</div>
  					</div>
  					<div class="col-xs-12 col-sm-6 col-md-8">
  						<div class="form-group">
  							<input class="form-control" type="text" placeholder="Фамилия" v-model="user.surname">
  						</div>
  					</div>
  				</div>
  			</div>
  			<div class="field">
  				<div class="row start-xs middle-xs">
  					<div class="col-xs-12 col-sm-6 col-md-4">
  						<div class="label">
  							Имя
  						</div>
  					</div>
  					<div class="col-xs-12 col-sm-6 col-md-8">
  						<div class="form-group">
  							<input class="form-control" type="text" placeholder="Имя" v-model="user.name">
  						</div>
  					</div>
  				</div>
  			</div>
  			<div class="field">
  				<div class="row start-xs middle-xs">
  					<div class="col-xs-12 col-sm-6 col-md-4">
  						<div class="label">
  							Отчество
  						</div>
  					</div>
  					<div class="col-xs-12 col-sm-6 col-md-8">
  						<div class="form-group">
  							<input class="form-control" type="text" placeholder="Отчество" v-model="user.middle_name">
  						</div>
  					</div>
  				</div>
  			</div>
  			<div class="field">
  				<div class="row start-xs middle-xs">
  					<div class="col-xs-12 col-sm-6 col-md-4">
  						<div class="label">
  							Телефон
  						</div>
  					</div>
  					<div class="col-xs-12 col-sm-6 col-md-8">
  						<div class="form-group">
  							<input class="form-control" type="text" placeholder="Телефон" v-model="user.phone" v-mask="'+7 (###) ### ##-##'" >
  						</div>
  					</div>
  				</div>
  			</div>
  			<div class="field">
  				<div class="row start-xs middle-xs">
  					<div class="col-xs-12 col-sm-6 col-md-4">
  						<div class="label">
  							Электронная почта
  						</div>
  					</div>
  					<div class="col-xs-12 col-sm-6 col-md-8">
  						<div class="form-group">
  							<input class="form-control" type="email" placeholder="Электронная почта" v-model="user.email">
  						</div>
  					</div>
  				</div>
  			</div>
  			<div class="field">
  				<div class="row start-xs middle-xs">
  					<div class="col-xs-12 col-sm-6 col-md-4">
  						<div class="label">
  							Пароль
  						</div>
  					</div>
  					<div class="col-xs-12 col-sm-6 col-md-8">
  						<div class="form-group">
  							<input class="form-control form-control--password" type="password" placeholder="Пароль" v-model="user.password">
  							<span class="password-watch" ref="passwordWatchNew"></span>
  							<a class="link-default-dashed password-generate fs-12" @click="generatePassword($event)"> 
		  						<div class="btn--loader"> </div>
		  						<span class="btn--text"> Сгенерировать <div class="visible-md visible-lg" style="margin-left: 4px;"> пароль </div> </span> 
		  					</a>
  						</div>
  					</div>
  				</div>
  			</div>
  			<div class="field">
  				<div class="row start-xs middle-xs">
  					<div class="col-xs-12 col-sm-6 col-md-4">
  						<div class="label">
  							Подтвердите пароль
  						</div>
  					</div>
  					<div class="col-xs-12 col-sm-6 col-md-8">
  						<div class="form-group">
  							<input class="form-control form-control--password" type="password" placeholder="Подтвердите пароль" v-model="user.password_repeat">
  							<span class="password-watch" ref="passwordWatchConfirm"></span>
  						</div>
  					</div>
  				</div>
  			</div>
  		</div>
  	</div>

  	<div class="block">
    	<div class="block__footer">
    		<a class="btn btn--default btn--md me-1" :class="{'disabled': isDisabledSave}" @click="save($event)">
    			<div class="btn--text"> Сохранить </div>
    			<div class="btn--loader"></div>
    		</a>
    		<router-link :to="{name: 'PageHome'}" class="btn btn--outline btn--md">
        		<div class="btn--text"> Отменить </div>
    		</router-link>
    	</div>
    </div>

</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue';
  import FilePicker from '@/components/ux/FilePicker.vue';
  import DatePicker from '@/components/ux/DatePicker.vue';
  import DadataPicker from '@/components/ux/DadataPicker.vue'

  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
</script>

<script>
 export default {
    name: 'PageOrganizationCreate',

    data: () => ({
        contract: {
        	number: null,
        	number_licenses: null,
        	file: null,
        	date: null,
        	customer: null,
			inn: null,
        },
        organization: {
		  	name: null,
		  	inn: null,
		  	ogrn: null,
		  	kpp: null,
		},
		user: {
		  	email: null,
		  	name: null,
		  	surname: null,
		  	middle_name: null,
		  	id_role: 1,
		  	phone: null,
		  	password: null,
		  	password_repeat: null,
		}
    }),
    created () {
    	this.contract.date = this.$moment().format('YYYY-MM-DD');
    },
    computed: {
    	isDisabledSave () {
    		for (let key in this.contract) {
    			if( (key !== 'file' && this.contract[key] + ''.trim() == '') || (key == "file" && this.contract[key] == null) ) {
    				return true;
    			}
    		}
    		for (let key in this.organization) {
    			if(key == 'kpp') continue;
    			
    			if(this.organization[key] == null || this.organization[key] + ''.trim() == '') {
    				return true;
    			}
    		}
    		for (let key in this.user) {
    			if(this.user[key] == null || this.user[key] + ''.trim() == '' ) {
    				return true;
    			}
    		}
    		console.log(3);
    		return false;
    	},
    },
    methods: {
      	beforeRender() {
      	},
      	setOrg (item) {
			if(item == null) {
				this.organization.name = null;
				this.organization.inn = null;
				this.organization.ogrn = null;
				this.organization.kpp = null;
			} else {
				this.organization.name = item.value;
				this.organization.inn = item.data.inn;
				this.organization.ogrn = item.data.ogrn;
				this.organization.kpp = item.data.kpp;
			}
		},

		setCustomer (item) {
			if(item == null) {
				this.contract.customer = null;
				this.contract.inn = null;
			} else {
				this.contract.customer = item.value;
				this.contract.inn = item.data.inn;
			}
		},

      	async save (e) {
	      	const btn = e.currentTarget;

	      	const contract = { ...this.contract };
			const organization = { ...this.organization };
			const user = { ...this.user };

			contract.file = await this.$helpers.toBase64(contract.file);

			if(user.password !== user.password_repeat) {
				this.$refs.messageDialogue.show({
					message: 'Пароли руководителя организации не совпадают',
					okButton: 'Подтвердить',
				});
				return;
			}

			delete user.password_repeat;
	  		user.avatar = null;
	      	
	      	btn.classList.add('btn--load');

    		try {
    			await this.$store.dispatch('createOrganization', {user: user, contract: contract, organization: organization});

        		await this.$refs.confirmDialogue.show({
					message: 'Организация успешно создана',
					okButton: 'Подтвердить',
					cancelButton: false,
				});
    			this.$router.push({name: 'PageHome'});
    		} catch (e) {
              	this.$refs.messageDialogue.show({message: this.$helpers.getErrorMessage(e), okButton: 'Подтвердить'});
    		}
    		btn.classList.remove('btn--load');
      	},
      	async generatePassword (e) {
    		const btn = e.currentTarget;
    		btn.classList.add('btn--load');
    		await new Promise(r => setTimeout(r, 300));
    		try {
    			const { password } = await this.$store.dispatch('getPassword');
				this.user.password = password;
				this.user.password_repeat = password;
				if(!this.$refs.passwordWatchNew.classList.contains('active')) {
					this.$refs.passwordWatchNew.click();
				}
				if(!this.$refs.passwordWatchConfirm.classList.contains('active')) {
					this.$refs.passwordWatchConfirm.click();
				}
    		} catch (e) {
              	this.$refs.messageDialogue.show({message: this.$helpers.getErrorMessage(e), okButton: 'Подтвердить'});
    		}
    		btn.classList.remove('btn--load');
    	},
    },
  }
</script>

<style scoped>
	.field:not(:last-child) {
		margin-bottom: 10px;
	}
	.field--data {
		margin-top: 20px;
		margin-bottom: 0px;
	}
	.block:not(:last-child) {
		margin-bottom: 10px;
	}
	.block__body {
		padding: 20px;
	}
	.block__footer {
    	border: none;
    	justify-content: flex-start;
	}


	@media (max-width: 767px) {
		.label {
			margin-bottom: 8px;
		}
		.block__footer a {
			width: 50%;
		}
	}
</style>
