<template>

  <TopMenu :headerText="null" />
  <Breadcrumbs :breadcrumbs="breadcrumbs" />

  <MessageDialogue ref="messageDialogue" />
  <ConfirmDialogue ref="confirmDialogue" />

  <!-- Обследования -->
  <div class="block mt-1">
    <div class="block__header block__header--btn">
      Обследования
      <router-link class="btn btn--grey btn--sm" :to="{name: 'PageCheckupCreate', params: {mkd_id: mkd_id}}">
        <img class="btn--ico btn--ico--left" src="@/assets/img/plus-blue-small.svg">
        <div class="btn--text"> Добавить  <span class="hidden-xs"> обследование </span> </div>
      </router-link>
    </div>
    <div class="block__body">
      <grid-table :options="options">
        <template v-slot:default="{ item, reload }">
          <tr>
            <td v-if="id_role == 0" width="1px">{{item.id}}</td>
            <td v-if="id_role == 0">
              <router-link class="link-default" :to="{name: 'PageOrganization', params: {organization_id: item.id_organization}}">
                <span> {{item.organization_name}} </span>
              </router-link>
            </td>
            <td>
              <div class="clock">
                <div class="clock__item">
                  <router-link :to="{name: 'PageCheckupCustomer', params: { mkd_id: item.id_mkd, checkup_id: item.id }}" class="link-default">
                    <img class="btn--ico--left" src="@/assets/img/min-calendar-grey.svg">
                    <span> {{ $moment(item.create_at).format('DD.MM.YYYY') }} </span>
                  </router-link>
                </div>
                <div class="clock__item">
                  <img class="btn--ico--left" src="@/assets/img/min-clock-grey.svg">
                  {{ $moment(item.create_at).format('HH:mm:ss') }}
                </div>
              </div>
            </td>
            <td class="nowrap">
              {{ item.number }}
            </td>
            <td style="min-width: 350px;">
              {{ item.customer }} (ИНН: {{ item.customer_inn }})
            </td>
            <td>
              <span v-if="item.id_checkup_status == 1"> В работе </span>
              <span v-if="item.id_checkup_status == 2"> Завершен </span>
            </td>
            <td style="width: 1px;" v-if="id_role == 0">
              <a class="link-default ms-3 fs-12" @click="deleteCheckup($event, item.id, reload)">
                <img class="btn--ico btn--ico--left" src="@/assets/img/cross-blue.svg">
                <span class="btn--text"> Удалить </span>
                <div class="btn--loader"></div>
              </a>
            </td>
          </tr>
        </template>
      </grid-table>
    </div>
  </div>
  <div class="block mt-1">
    <div class="block__footer" style="border: none">
      <a class="btn btn--outline btn--md" @click="$router.go(-1)">
        <img class="btn--ico btn--ico--left" src="@/assets/img/6.svg">
        <div class="btn--text">Вернуться</div>
      </a>
    </div>
  </div>
</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue'
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
  import GridTable from '@/components/ux/GridTable.vue';
</script>

<script>
  export default {
    name: 'PageObjectsCheckupList',
    data: () => ({
      
      mkd: null,

      breadcrumbs: [],

      organizations: [],

      options: {
        method: 'getCheckups',
        display: 10,
        fields: [
          {key:'create_at', name:'Дата / время'},
          {key:'number', name:'№ договора'},
          {key:'customer', name: 'Заказчик'},
          {key:'id_checkup_status', name: 'Статус'},
        ],
        filters: [
          {key: 'number', type: 'text'},
          {key: 'id_checkup_status', type: 'select', list: [{id: 1, name: 'В работе'}, {id: 2, name: 'Завершен'}]},
        ],
        defaultFilter: [],
      },

    }),
    created () {

      // Чекапы конкретного дома
      this.options.defaultFilter.push({key: 'id_mkd', value: this.mkd_id});

      // Для рута
      if(this.id_role == 0) {

        // Выводим ID
        this.options.fields.splice(0, 0, {key: 'id', name: 'ID'})
        // Выводим Организацию
        this.options.fields.splice(1, 0, {key: 'id_organization', name:'Организация'})
        // Выводим пустой для управления
        this.options.fields.splice(6, 0, {key: false, name:''})

        // Фильтр по организациям
        this.options.filters.splice(1, 0, { key: 'id_organization', type: 'select', list: [] });

        Promise.all([this.loadOrganizations()])
          
          .then(() => {
            // Данные для фильтра по организациям
            this.options.filters[1].list = this.organizations;
          })

          .catch(()=> {})
      
      }

      Promise.all([this.loadMkd()])

        .then(() => {
          // Данные для фильтра по организациям
          this.beforeRender();
        })

        .catch(() => {})

    },
    
    computed: {
      mkd_id () {
        return this.$route.params.mkd_id;
      },
      id_role () {
        return this.$store.state.auth.user.profile.id_role;
      },
    },

    methods: {
      beforeRender() {
        this.breadcrumbs = [
          {name: this.$helpers.mkd_address(this.mkd), link: {name: 'PageObjectsView', params: {mkd_id: this.mkd_id}}},
          {name: 'Список обследований', link: ''},
        ];

        if(this.id_role == 2) {
          // Для обследователя - на главную
          this.breadcrumbs.unshift({name: 'Главная', link: {name: 'PageHome'}});
        } else {
          // Для всех остальных на объекты
          this.breadcrumbs.unshift({name: 'Объекты', link: {name: 'PageObjects'}});
        }
          
      },

      async loadMkd () {
        this.mkd = await this.$store.dispatch('getMkd', {id_mkd: this.mkd_id});
      },
      async loadOrganizations () {
        const { items } = await this.$store.dispatch('getOrganizations', {display: 1000, filter: {}, order: {}, page: 1});
        this.organizations = items;
      },

      async deleteCheckup (e, id, reload) {
        
        const btn = e.currentTarget;
        
        btn.classList.add('btn--load');

        const confirm = await this.$refs.confirmDialogue.show({
          message: 'Вы уверены, что хотите удалить это обследование?',
          okButton: 'Удалить',
        });

        if(confirm) {
          try {
            await this.$store.dispatch('deleteCheckup', {id_checkup: id});
            reload();
          } catch (e) {
            this.$refs.messageDialogue.show({message: this.$helpers.getErrorMessage(e), okButton: 'Подтвердить'});
          }
        }

        btn.classList.remove('btn--load');

      },

    }
  }
</script>