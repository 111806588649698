<template>

	<TopMenu :headerText="headerText" />
  <Breadcrumbs :breadcrumbs="breadcrumbs" />
  <MessageDialogue ref="messageDialogue" />
  <ConfirmDialogue ref="confirmDialogue" />


  <div class="block">
  	<div class="block__header">
  		Договор
  	</div>
  	<div class="block__body">
	 		
      <div class="field">
				<div class="row start-xs middle-xs">
					<div class="col-xs-12 col-sm-5 col-md-5">
						<div class="label">
							Номер договора
						</div>
					</div>
					<div class="col-xs-12 col-sm-7 col-md-7">
						<div class="form-group">
							<input class="form-control" type="text" placeholder="Номер договора" v-model="form.number">
						</div>
					</div>
				</div>
			</div>
			<div class="field">
				<div class="row start-xs middle-xs">
					<div class="col-xs-12 col-sm-5 col-md-5">
						<div class="label">
							Общее количество лицензий
						</div>
					</div>
					<div class="col-xs-12 col-sm-7 col-md-7">
						<div class="form-group">
							<input class="form-control" type="number" placeholder="Общее количество лицензий" v-model="form.number_licenses">
						</div>
					</div>
				</div>
			</div>
			<div class="field">
				<div class="row start-xs middle-xs">
					<div class="col-xs-12 col-sm-5 col-md-5">
						<div class="label">
							Дата подписания
						</div>
					</div>
					<div class="col-xs-12 col-sm-7 col-md-7">
						<div class="form-group">
							<DatePicker v-model="form.date" :label="'Дата подписания'"/>
						</div>
					</div>
				</div>
			</div>
			<div class="field" v-if="contract !== null">
				<div class="row start-xs top-xs">
					<div class="col-xs-12 col-sm-5 col-md-5">
						<div class="label">
							Подписанный документ
						</div>
					</div>
					<div class="col-xs-12 col-sm-7 col-md-7">

						<div class="file mb-1" v-if="form.file == null">
							<img class="file__ico" src="@/assets/img/file-blue.svg">
							<div class="file__content">
								<div class="file__info">
									<div class="file__name">
										{{ contract_name }}
									</div>
								</div>
								<div class="file__control">
									<a class="file__link" target="blank" :href="contract.file"><span>Скачать</span></a>
								</div>
							</div>
						</div>

						<FilePicker v-model="form.file" label="Изменить файл" />

					</div>
				</div>
			</div>
	
  	</div>

  	<div class="block__footer">
  		<a class="btn btn--default btn--md me-1" @click="saveContract($event)" :class="{'disabled': isDisabledBtn}">
      	<div class="btn--text"> Сохранить </div>
      	<div class="btn--loader"></div>
      </a>
      <a class="btn btn--outline btn--md" @click="$router.go(-1)">
      	<div class="btn--text"> Отменить </div>
      	<div class="btn--loader"></div>
      </a>
  	</div>
  </div>

</template>

<script setup>
  	import TopMenu from '@/components/TopMenu.vue';
  	import Breadcrumbs from '@/components/Breadcrumbs.vue';
  	import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  	import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';

    import FilePicker from '@/components/ux/FilePicker.vue';
  	import DatePicker from '@/components/ux/DatePicker.vue';
</script>

<script>
  export default {

    name: 'PageContractsEdit',

    data: () => ({
      headerText: null,

      breadcrumbs: [],

      contract: null,

      form: {
      	number: null,
				number_licenses: null,
				date: null,
				file: null,
      },

      newFile: null,
      organization: null,

    }),
    created () {

      const loads = [this.loadContract()];

      if(this.organization_id) {
        loads.push( this.loadOrganization());
      }

      Promise.all(loads)
	      .then(() => {
	        this.beforeRender();
	      })
	      .catch((e)=> {
	        console.log(e);
	      })
    },
    computed: {
    	contract_id () {
      	return this.$route.params.contract_id;
    	},
    	organization_id () {
        return this.$route.params.organization_id;
      },
    	contract_name () {
    		return 'Договор № ' + this.contract.number + ' от ' + this.$moment(this.contract.date).format('DD.MM.YYYY');
    	},
    	isDisabledBtn () {
        for(let key in this.form) {
        	if(key == 'file') {
        		continue;
        	}
          if(this.form[key] == null || this.form[key] + ''.trim() == '') {
            return true;
          }
        }
        return false;
      },
    },
    methods: {
    	
    	beforeRender () {
    		
        if (this.organization_id) {

        	this.breadcrumbs = [
            {name: this.organization.name, link: {name: 'PageOrganization', params: {organization_id: this.organization.id}}},
            {name: this.contract_name, link: ''},
          ];
          
        } else {

        	this.breadcrumbs = [
        		{name: 'Договоры', link: {name: 'PageContracts'}},
        		{name: this.contract_name, link: ''},
      		];

        }

    	},

    	setCustomer (item) {
        if(item == null) {
          this.form.customer = null;
          this.form.inn = null;
        } else {
          this.form.customer = item.value;
          this.form.inn = item.data.inn;
        }
      },

    	
    	async loadOrganization () {
        const organization = await this.$store.dispatch('getOrganization', {id: this.organization_id});
        this.organization = organization;
      },

    	async loadContract () {
    		this.contract = await this.$store.dispatch('getContractById', {id: this.contract_id});
    		this.form = {...this.contract};
    		this.form.file = null;
    		this.form.date = this.$moment(this.form.date).format('YYYY-MM-DD')
    	},

    	async saveContract (e) {
    		const btn = e.currentTarget;
    		btn.classList.add('btn--load');

    		if(this.form.file !== null) {
    			this.form.file = await this.$helpers.toBase64(this.form.file);
    		} else {
    			this.form.file = this.contract.file;
    		}
    		
    		// delete this.form.id;

  			try {
  				
  				await this.$store.dispatch('updateContract', {id: this.contract.id, data: this.form});
          
          this.$router.push({name: 'PageContracts'});

  			} catch (e) {

          this.$refs.messageDialogue.show({message: this.$helpers.getErrorMessage(e), okButton: 'Подтвердить'});
  			
  			}

    		btn.classList.remove('btn--load');
    		
      },

   
    },
  }
</script>


<style scoped>
	.block__body {
		padding: 20px 0px;
	}
	.block__footer {
		justify-content: flex-start;
	}
	.field {
		padding: 0px 20px;
	}
  .field:not(:last-child) {
    margin-bottom: 10px;
  }
  .field--data {
    margin-top: 20px;
  }
  @media (max-width: 767px) {
    .label {
      margin-top: 0px;
      margin-bottom: 8px;
    }
    .field:not(:last-child) {
      margin-bottom: 15px;
    }
  }
</style>