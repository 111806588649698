<template>
  <TopMenu :headerText="headerText" />
  <div class="content" v-if="!isLoad && !isError">
    <div class="hint" v-if="items == null || items.length == 0">
      Данные отсутствуют
    </div>
    <template v-else>
      <MkdCard :mkd="item" v-for="(item) in items" :key="item.id"/>
    </template>
  </div>
</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue'
  import MkdCard from '@/components/archive/MkdCard.vue'
  // import ErrorContent from '@/components/ErrorContent.vue'
</script>

<script>
  export default {
    name: 'PageArchive',
    data: () => ({
      items: [],
      isLoad: true,
      isError: false,
      headerText: null,
    }),
    created () {
      Promise.all([this.loadAllMkd()])
      .then(() => {
        this.beforeRender();
        this.isLoad = false;
      })
      .catch((e)=> {
        console.log(e);
        this.isError = true;
        this.isLoad = false;
      })
    },
    methods: {
      beforeRender() {},
      async loadAllMkd () {
        this.items = await this.$store.dispatch('getMkdByArchive');
      }
    },
  }
</script>