<template>
	<div class="block">
		<div class="block__body">
			<div class="load-container" :class="{'load' : isLoad}">
			    <div v-if="!isLoad && !isError">
					<div class="row">
						<div class="col-xs-12 col-sm-4 col-md-4" v-if="metrics.hasOwnProperty('organization_count')">
							<div class="number">
								<div class="number__num">
									{{ metrics.organization_count }}
								</div>
								<div class="number__text">
									Общее количество <br class="hidden-xs" /> подключенных организаций
								</div>
							</div>
						</div>
						<div class="col-xs-12 col-sm-4 col-md-4" v-if="metrics.hasOwnProperty('contract_count')">
							<div class="number">
								<div class="number__num">
									{{ metrics.contract_count }}
								</div>
								<div class="number__text">
									Общее количество <br class="hidden-xs" /> проданных лицензий
								</div>
							</div>
						</div>
						<div class="col-xs-12 col-sm-4 col-md-4" v-if="metrics.hasOwnProperty('count_checkup')">
							<div class="number">
								<div class="number__num">
									{{ metrics.count_checkup }}
								</div>
								<div class="number__text">
									Общее количество  <br class="hidden-xs" /> обследований
								</div>
							</div>
						</div>
						<div class="col-xs-12 col-sm-4 col-md-4" v-if="metrics.hasOwnProperty('count_checkup_end')">
							<div class="number">
								<div class="number__num">
									{{ metrics.count_checkup_end }}
								</div>
								<div class="number__text">
									Обследований <br class="hidden-xs" /> проведено
								</div>
							</div>
						</div>
						<div class="col-xs-12 col-sm-4 col-md-4" v-if="metrics.hasOwnProperty('count_checkup_work')">
							<div class="number">
								<div class="number__num">
									{{ metrics.count_checkup_work }}
								</div>
								<div class="number__text">
									Обследований <br class="hidden-xs" /> в работе
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'MetricsComponent',
	    data: () => ({
  			isLoad: false,
  			isError: false,
  			metrics: [],
	    }),
	    created () {
	      	Promise.all([this.loadItems()])
		      	.then(() => {
		        	this.isLoad = false;
		      	})
		      	.catch(()=> {
		        	this.isError = true;
		        	this.isLoad = false;
		      	})
	    },
	    methods: {
	  		async loadItems () {
	  			this.metrics = await this.$store.dispatch('getMetrics');
	    	},
	    }
	}
</script>

<style scoped>
	.block__body {
		padding: 20px;
	}
	.number {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}
	.number__num {
		color: #008DD2;
		font-size: 34px;
		font-family: Inter;
		font-weight: 600;
		word-wrap: break-word;
		/*height: 60px;*/
		/*min-height: 60px;*/
		/*width: 60px;*/
		/*min-width: 60px;*/
		/*border: 1px solid rgba(var(--cozh-blue-code), .35);*/
		/*border-radius: 50%;*/
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.number__text {
		margin-left: 15px;
		font-size: 12px;
		font-weight: 500;
	}

	.load-container.load {
     	min-height: 60px; 
	}

  	@media (max-width: 767px) {
  		.number {
  			flex-direction: column;
  			margin-bottom: 20px;
  		}
		.number__text {
			margin-left: 0px;
			text-align: center;
		}

  		.block__body {
  			padding-bottom: 10px;
  		}
  		.load-container.load {
     		min-height: 210px; 
		}
  	}
</style>