<template>

  <!-- Мобильное меню сверху -->
  <div class="mobile-header" :class="{'fixed' : isOpenMenu}">
    <a class="mobile-header__humburger" :class="{'open' : isOpenMenu}" @click="isOpenMenu = !isOpenMenu">
      <div v-if="isOpenMenu">
        <IconCross />
        <span> Закрыть </span>
      </div>
      <div v-if="!isOpenMenu">
        <IconHamburger />
        <span> Меню </span>
      </div>     
    </a>
    <div class="mobile-header__logo">
      <img src="@/assets/img/logo-max.svg">
    </div>
  </div>

  <!-- Меню боковое -->
  <div class="menu" :class="{'open' : isOpenMenu}">

    <!-- Логотип -->
    <router-link to="/" class="logo">
      <img src="@/assets/img/logo-min.svg" class="logo--1">
      <img src="@/assets/img/logo-max.svg" class="logo--2">
    </router-link>
    
    <!-- Навигация -->
    <div class="nav">
      <router-link :to="{name: 'PageHome'}" class="nav__item">
        <IconPageMain />
        <div class="nav__name">Главная</div>
      </router-link>
      
      <template v-if="id_role == 0 || id_role == 1">
          
        <router-link :to="{name: 'PageObjects'}" class="nav__item" >
          <IconPageObjects />
          <div class="nav__name">Объекты</div>
        </router-link>

        <router-link :to="{name: 'PageUsers'}" class="nav__item">
          <IconPageUsers />
          <div class="nav__name">Пользователи</div>
        </router-link>
        
        <router-link :to="{name: 'PageContracts'}" class="nav__item">
          <IconPageContracts />
          <div class="nav__name">Договоры</div>
        </router-link>

        <router-link :to="{name: 'PageMap'}" class="nav__item">
          <IconPageMap />
          <div class="nav__name">Карта</div>
        </router-link>

      </template>

      <router-link :to="{name: 'PageArchive'}" class="nav__item">
        <IconPageArchive />
        <div class="nav__name">Архив</div>
      </router-link>

      <router-link :to="{name: 'PageFAQ'}" class="nav__item">
        <IconPageFAQ />
        <div class="nav__name">Вопросы ответы</div>
      </router-link>


      <template v-if="id_role == 0">
        
        <!-- Список чатов у рута -->
        <router-link :to="{name: 'PageRootDialogs'}" class="nav__item">
          <IconPageSupport />
          <div class="nav__name">Техническая поддержка</div>
          <div class="nav__badge" v-if="countUnreadMessages > 0"> {{ countUnreadMessages }} </div>
        </router-link>

      </template>

      <template v-if="id_role == 1 || id_role == 2">

        <!-- Чат с рутом у пользователя -->
        <router-link :to="{name: 'PageUserChat'}" class="nav__item">
          <IconPageSupport />
          <div class="nav__name">Техническая поддержка</div>
          <div class="nav__badge" v-if="countUnreadMessages > 0"> {{ countUnreadMessages }} </div>
        </router-link>

      </template>

    </div>

    <!-- Пользователь -->
    <UserState class="visible-xs" />

  </div>

</template>

<script setup>
  import UserState from './UserState.vue';
  import IconCross from './icons/IconCross-b2.w10.h10.vue';
  import IconHamburger from './icons/IconHamburger-b2.w18.h12.vue';

  import IconPageMain from './icons/pages/IconPageMain.vue';
  import IconPageUsers from './icons/pages/IconPageUsers.vue';
  import IconPageMap from './icons/pages/IconPageMap.vue';
  import IconPageArchive from './icons/pages/IconPageArchive.vue';
  import IconPageFAQ from './icons/pages/IconPageFAQ.vue';
  import IconPageSupport from './icons/pages/IconPageSupport.vue';
  import IconPageObjects from './icons/pages/IconPageObjects.vue';
  import IconPageContracts from './icons/pages/IconPageContracts.vue';
</script>

<script>
  export default {
    name: 'LeftMenu',
    data: () => ({
      isOpenMenu: false,
      timer: null,
    }),
    watch:{
      isOpenMenu (val) {
        if (val) {
          document.querySelectorAll('body')[0].classList.add('overflow');
        } else {
          document.querySelectorAll('body')[0].classList.remove('overflow');
        }
      },
      $route () {
        this.isOpenMenu = false;
      },
    },
    created () {
      this.$store.dispatch('getUnreadMessages');
      
      this.timer = setInterval(() => {
      
        this.$store.dispatch('getUnreadMessages');
      
      }, 10000);
    },
    beforeUnmount () {
      clearInterval(this.timer);
    },
    computed: {
      id_role () {
        return this.$store.state.auth.user.profile.id_role;
      },
      countUnreadMessages () {
        return this.$store.state.unreadMessages;
      },
    },
  }
</script>


<style scoped>

  .menu {
    position: fixed;
    background-color: var(--cozh-white);
    width: 90px;
    min-width: 90px;
    display: flex;
    flex-direction: column;
    height: 100dvh;
    user-select: none;
    z-index: 5;
    transition: all .3s ease-out;
    overflow-x: hidden;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 30px 30px;
  }
  .logo img {
    height: 28px;
  }
  .logo a {
    display: flex;
  }


  .nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    width: 100%;
    border-top: 1px solid var(--cozh-light-grey);

  }
  .nav__item {
    min-width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: left;
    border-radius: 4px;
    padding: 16px;
    position: relative;
  }
  .nav .nav__item:not(:last-child) {
    margin-bottom: 5px;
  }
  .nav__item:hover {
    background-color: rgba(var(--cozh-blue-code), .08);
  }
  .nav__item.router-link-active {
    background-color: rgba(var(--cozh-blue-code), .08);
  }
  .nav__item:deep(svg path) {
    transition: all .2s ease-out;
  }
  .nav__item.router-link-active:deep(svg path) {
    fill: var(--cozh-blue);
  }
  .nav__name {
    display: none;
    margin-left: 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 1;
  }

  .nav__badge {
    position: absolute;
    top: -8px;
    right: -8px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: var(--cozh-blue);
    color: #fff;
    font-size: 9px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }


  .logo--2 {
    display: none;
  }


  .menu:hover {
    width: 300px;
    box-shadow: 20px 0px 60px 0px rgba(var(--cozh-black-code), 0.15);
  }
  .menu:hover .logo .logo--1 {
    display: none;
  }
  .menu:hover .logo .logo--2 {
    display: block;
  }
  .menu:hover .nav {
    width: 300px;
  }
  .menu:hover .nav__name {
    display: block;
  }
  .menu:hover .nav__item {
    width: 100%;
  }


  .mobile-header {
    height: 60px;
    background-color: var(--cozh-white);
    width: 100%;
    display: none;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: none;

  }

  .mobile-header__logo img {
    display: flex;
  }

  .mobile-header__humburger {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 20px;
    margin-left: -20px;
    height: 100%;
    cursor: pointer;
  }
  .mobile-header__humburger div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .mobile-header__humburger div svg {
    margin-right: 10px;
  }
  .mobile-header__humburger div span {
    font-size: 11px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
  }



  @media (max-width: 767px) {

    .visible-xs {
      display: flex!important;
    }
    .nav {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px 0px;
    }
    .nav__item {
      border-radius: 0px;
      padding: 0px 20px;
      /*height: 42px;*/
    }
    .nav .nav__item:not(:last-child) {
      margin-bottom: 0px;
    }

    .menu, .menu:hover {
      width: 100%;
      box-shadow:  none;
      left: calc(-100%);
      top: 60px;
      height: calc(100dvh - 60px);
      position: absolute;
      justify-content: space-between;
      overflow-y: scroll;
      position: fixed;
    }
    .menu:hover .nav {
      width: 100%;
    }


    .logo {
      display: none;
    }

    .menu .nav__name {
      display: block;
    }

    .menu.open {
      left: 0px;
    }

    .mobile-header {
      display: flex;
      z-index: 99;
    }

    .mobile-header.fixed {
      position: fixed;
      top: 0px;
    }

    .nav__name {
      font-size: 14px;
    }

    .nav__badge {
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
    }


/*    .mobile-header.fixed {
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 9999;
    }
    .menu.open {
      position: fixed;
      top: 60px;
      left: 0px;
      z-index: 9999;
    }*/

  }


</style>
