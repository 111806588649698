<template>
  <MessageDialogue ref="messageDialogue" />
  <ConfirmDialogue ref="confirmDialogue" />

	<div class="block mt-1">
    <div class="block__header block__header--btn"> 
    	Организация
    	<transition name="slide">
	    	<a class="btn btn--grey btn--sm" v-if="isEdit && !editMode" @click="editMode = true">
			    <img class="btn--ico btn--ico--left" src="@/assets/img/edit-blue.svg">
			    <div class="btn--text"> Изменить</div>
			  </a>
    	</transition>
    </div>
    <div class="block__body">
			<div class="field">
				<div class="row start-xs middle-xs">
					<div class="col-xs-12 col-sm-6 col-md-4">
						<div class="label"> Наименование организации </div>
					</div>
					<div class="col-xs-12 col-sm-6 col-md-8">
						<template v-if="!editMode">
							<div class="label"> {{ organization.name }} </div>
						</template>
						<template v-if="editMode">
			      	<DadataPicker label="Начните ввод" type="party" @setValue="setOrg" :value="organization.name"/>
						</template>
					</div>
				</div>
			</div>
			<div class="field field--data">
				<div class="row start-xs middle-xs">
					<div class="col-xs-12 col-sm-6 col-md-4">
						<div class="label"> ОГРН </div>
					</div>
					<div class="col-xs-12 col-sm-6 col-md-8">
						<div class="label"> {{ organization.ogrn == null ? '-' : organization.ogrn }} </div>
					</div>
				</div>
			</div>
			<div class="field field--data">
				<div class="row start-xs middle-xs">
					<div class="col-xs-12 col-sm-6 col-md-4">
						<div class="label"> ИНН </div>
					</div>
					<div class="col-xs-12 col-sm-6 col-md-8">
						<div class="label"> {{ organization.inn == null ? '-' : organization.inn }} </div>
					</div>
				</div>
			</div>
			<div class="field field--data">
				<div class="row start-xs middle-xs">
					<div class="col-xs-12 col-sm-6 col-md-4">
						<div class="label"> КПП </div>
					</div>
					<div class="col-xs-12 col-sm-6 col-md-8">
						<div class="label"> {{ organization.kpp == null ? '-' : organization.kpp }} </div>
					</div>
				</div>
			</div>
    </div>
    <div class="block__footer" v-if="editMode">

    	<a class="btn btn--default btn--md me-1" @click="saveInfo($event)">
  			<div class="btn--text">Сохранить</div>
  			<div class="btn--loader"></div>
  		</a>
  		
  		<a class="btn btn--outline btn--md" @click="cancelInfo()">
  			<div class="btn--text">Отменить</div>
  			<div class="btn--loader"></div>
  		</a>

    </div>
  </div>
</template>

<script setup>
  import DadataPicker from '@/components/ux/DadataPicker.vue'
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
</script>

<script>
  export default {
    name: 'CardInfo',
    props: ['data', 'isEdit'],

    data: () => ({
    	
    	editMode: false,

    	organization: {
		  	id: null,
		  	name: null,
		  	inn: null,
		  	ogrn: null,
		  	kpp: null,
			},

    }),

    created () {

    	this.organization = {...this.data};

    },

    methods: {

    	cancelInfo () {
    		this.organization = {...this.data};
    		this.editMode = false;
    	},

    	setOrg (item) {
				
				if(item == null) {
					
					this.organization.name = null;
					this.organization.inn = null;
					this.organization.ogrn = null;
					this.organization.kpp = null;

				} else {

					this.organization.name = item.value;
					this.organization.inn = item.data.inn;
					this.organization.ogrn = item.data.ogrn;
					this.organization.kpp = item.data.kpp;

				}

			},

			async saveInfo (e) {
				
				const btn = e.currentTarget;
				
				btn.classList.add('btn--load');

				await new Promise(r => setTimeout(r, 300));
				try {

          await this.$store.dispatch('changeOrganization', this.organization);
        	
    			this.editMode = false;

        } catch (e) {
        
          this.$refs.messageDialogue.show({message: e.message, okButton: 'Подтвердить'});
        
        }

				btn.classList.remove('btn--load');

			},

    }
  }
</script>

<style scoped>

	.block__body {
		padding: 20px;
	}
	.block__footer {
		justify-content: flex-start;
	}

	.field:not(:last-child) {
		margin-bottom: 10px;
	}
	.field--data {
		margin-top: 20px;
		margin-bottom: 0px;
	}

	@media (max-width: 767px) {
		.label {
			margin-bottom: 8px;
		}
		.block__footer a {
			width: 50%;
		}
	}


</style>