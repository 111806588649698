<template>
	<div 
		class="dadata" 
		ref="picker"
		:class="{'dadata--top': isTop}"
	>
		<input class="form-control" type="text" :placeholder="label" v-model="query" @focus="() => {isOpen = true; isCheckLeave = true;}" ref="query">
		<transition name="slide-fade">
			<div class="dadata__content" v-if="list.length > 0 && isOpen" ref="content">
				<div class="dadata__hint">
					Выберите вариант или продолжите ввод
				</div>
				<ul>
					<li v-for="(item, index) in list" :key="index">
						<a @click.prevent="setValue(item)"> 
							{{ item.value }}&nbsp;<span v-if="type == 'party'">({{ item.data.inn }}) </span> 
						</a>
					</li>
				</ul>
			</div>
		</transition>
	</div>
</template>

<script>

	import _ from 'lodash'; 

	export default {
	    name: 'DadataPicker',
	    props: ['label', 'type', 'value'],
	    data: () => ({

	    	isOpen: false,
	    	isTop: false,
	    	isCheckLeave: true,

	    	query: '',
	    	debounce: 300,
	    	
	    	list: [],

	    	unwatch: null,

	    	api_key: 'eba4c5a355fe97a53df17aa9f49081af606870a5',
	    	endpoint: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/',

	    }),

	    created () {
	    	
	    	if(this.value) {
	    		this.query = this.value;
	    	}

	    	this.unwatch = this.$watch('query', _.debounce(() => {

	      if (this.query.trim() == '') {
  				this.$emit('setValue', null);
				} else {
	      	this.loadData();
				}

		    }, this.debounce));
	    },

			mounted () {
		    this.$nextTick(() => {
				  window.addEventListener('click', this.clickEvent);
		    })
	    },

	    unmounted () {
	    	window.removeEventListener('click', this.clickEvent);
		    this.unwatch();
			},

			watch: {
				isOpen: function (val) {
					// При открытии рассчитываем
					if(val) {
						this.calculateVertical();
					} else {
						this.leaveControl();
					}
				},
			},

	    methods: {

	    	clickEvent (e) {
	    		// Только если открыт
			  	if(this.isOpen) {
			  		// Элемент на который нажали находится за пределами этого селекта
				  	if(!this.$refs.picker.contains(e.target)) {
				  		// При этом нет фокуса на поиске
				  		if(document.activeElement !== this.$refs.query) {
				  			// Закрываем
				  			this.isOpen = false;
				  		}
				  	}
			  	}
	    	},

	    	calculateVertical () {
	    		this.$nextTick(() => {
		    		const content = this.$refs.content;
		    		const picker = this.$refs.picker;
		    		if(!content) return;
		    		const boundingContent = content.getBoundingClientRect();
		    		const boundingSelect = picker.getBoundingClientRect();
		    		if ((boundingSelect.bottom + boundingContent.height) > window.innerHeight) {
		    			this.isTop = true;
		    		} else {
		    			this.isTop = false;
		    		}
			    });
	    	},

	    	async loadData () {
	    		const r = await this.axios.post(this.endpoint + this.type, {query: this.query, count: 7}, {headers: {Authorization: "Token " + this.api_key}});
	        this.list = r.data.suggestions;
					this.calculateVertical();
	    	},
	    	
	    	setValue (item) {
	    		
	    		this.isCheckLeave = false;

	    		if(item !== null) {
	    			this.query = item.value;
	    		}
	    		this.$emit('setValue', item);
	    		this.isOpen = false;
	    	},

	    	leaveControl () {
	    		
	    		if(!this.isCheckLeave) return;

	    		console.log('leave');

    			// Если на последнем вводе были подсказки 
    			if(this.list.length == 1) {
    				// Флаг совпадений
    				let coincidence = false;
    				// Проходимся по списку подсказок
    				this.list.forEach((suggest) => {
    					// Подсказка на 100% совпадает с введенным значением 
    					if(suggest.value.toLowerCase() == this.query.toLowerCase()) {

    						// Устанавливаем как значение автоматически
    						this.setValue(suggest);
    						// Есть совпадение
    						coincidence = true;
    					}
    				})
    				// Если нет ни одного совпадения
    				if(!coincidence) {
    					// Сбрасываем установку
    					this.setValue(null);
    				}
    			} else {
    				this.setValue(null);
    			}
	    	} 

	    }

	}
</script>

<style scoped>
	.dadata {
		position: relative;
	}
	.dadata__content {
	    position: absolute;
	    /*opacity: 0;*/
	    /*visibility: hidden;*/
	    overflow: hidden;

	    width: max-content;
	    min-width: calc(100%);
	    max-width: 100%;

	    background-color: #fff;
	    box-shadow: none;
	    border-radius: 8px;
	    transition: all .1s ease-out;
	    transform: translateY(30px);
	    z-index: 6;

	    box-shadow: 0px 5px 20px 0px rgba(11, 40, 54, 0.18);

	    top: 15px;
	    left: 0px;
	}

	.dadata__content ul {
	    list-style: none;
	    padding: 0px;
	    margin: 0px;

	    height: auto;
	    max-height: auto;
	    overflow-y: auto;

	    padding-top: 0px;
	    padding-bottom: 10px;

	}
	.dadata__content ul li {
	    margin-bottom: 0px;
	}
	.dadata__content ul li a {
	    display: block;
	    padding: 8px 16px;
	    padding-right: 47px;
	    font-size: 13px;
	    line-height: 1.3;
	    font-weight: 500;
	    position: relative;
	}

	.dadata__content ul li:not(:last-child) a {
	}
	.dadata__content ul li a:hover {
	    background-color: rgba(var(--cozh-blue-code), .08);
	    border-color: transparent;
	}

	.dadata__hint {
	    padding: 10px 16px;
		color: rgba(197, 198, 199, 1);
		font-size: 12px;
	}




  .slide-enter-active {
    transition: all 0.2s ease-out;
  }
  .slide-leave-active {
    transition: all 0.2s ease-out;
  }
  .slide-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }
  .slide-enter-from {
    transform: translateX(-20px);
    opacity: 0;
  }

	.dadata--top .dadata__content {
    top: auto;
  	bottom: 75px;
	}
</style>
