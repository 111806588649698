<template>
	<div class="user">
      <router-link class="user__info" :to="{name: 'PageProfile'}">
        <div class="user__avatar" :style="{ backgroundImage: 'url(' + profile.avatar + ')' }" :class="{'empty': profile.avatar == null}">
          <span v-if="profile.avatar == null || profile.avatar == ''"> {{ profile.name.charAt(0) }} </span>
        </div>
        <div class="user__name">
          {{ profile.name }} <br /> {{ profile.surname }}
        </div>
      </router-link>
      <div class="user__controll">
        <router-link class="user__btn" :to="{name: 'PageProfile'}"> 
          <IconProfile />
          <span>Аккаунт</span>
        </router-link>
        <a class="user__btn" @click="logout">
          <IconExit />
          <span>Выйти</span>
        </a>
      </div>
    </div>
</template>


<script setup>
    import IconProfile from './icons/IconProfile-b2.w20.h20.vue';
    import IconExit from './icons/IconExit-b2.w20.h20.vue';
</script>

<script>
  export default {
    name: 'UserState',
    data: () => ({
    }),
    computed: {
      profile () {
        return this.$store.getters.authUser.profile;
      },
    },
    methods: {
      async logout () {
        this.$router.push({name: 'PageLogin'}).then(() => {
          document.querySelectorAll('body')[0].classList.remove('overflow');
          this.$store.dispatch('logout');
        });
      },
    },
  }
</script>

<style scoped>
	.user {
    padding-left: 15px;
    background-color: var(--cozh-white);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 60px;
    width: auto;
    margin-left: 10px;
    min-width: 310px;
	}
.user__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.user__avatar {
  min-height: 40px;
  min-width: 40px;
  font-size: 18px;
  font-weight: 800;
  color: var(--cozh-white);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.user__avatar.empty {
  background-color: var(--cozh-blue);
}

.user__name {
    font-weight: 600;
    font-size: 11px;
    line-height: 1.3;
}

.user__controll {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin-left: 15px;
    border-left: 1px solid var(--cozh-light-grey);
}

.user__btn {
    padding: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
}

.user__btn span {
  display: none;
}
.user__btn:last-child {
  padding-right: 12px;
}
.user__btn:first-child {
  padding-left: 12px;
}



.user__btn:deep(svg path), 
.user__btn:deep(svg circle) {
    transition: all .2s ease-out;
}
.user__btn:hover:deep(svg path), 
.user__btn:hover:deep(svg circle) {
    stroke: var(--cozh-blue);
}

.user__controll .router-link-active:deep(svg path),
.user__controll .router-link-active:deep(svg circle) {
  stroke: var(--cozh-blue);
}
	
@media (max-width: 767px) {
  .user {
  	border-top: 1px solid var(--cozh-light-grey);
    margin-left: 0px;
    border-radius: 0px;
    height: auto;
    min-width: 100%;
    padding-left: 0px;
    flex-direction: column;
  }
  .user__controll {
    margin-left: 0px;
    border: none;
    flex-direction: column;
    width: 100%;
    padding-bottom: 20px;
  }
  .user__info {
    padding: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid var(--cozh-light-grey);
  }
  .user__btn {
    padding: 13px 20px;
    width: 100%;
    justify-content: flex-start;
    height: 48px;
  }
  .user__btn:first-child {
    padding-left: 20px; 
  }
  .user__btn:last-child {
    padding-right: 20px; 
  }
  .user__btn span {
    display: block;
    margin-left: 15px;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
  }
  .user__controll .router-link-active {
    background-color: rgba(var(--cozh-blue-code), .08);
  }

}
</style>