<template>
    
    <TopMenu :headerText="headerText" />
    <!-- <ArchiveNavigator /> -->
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <div class="load-container" :class="{'load' : isLoad}">
      	<div class="content" v-if="!isLoad && !isError">
	        <!-- Общее состояние МКД -->
	        <MkdCard :mkd="item" :is_light="true" />
	        <!-- Блок "Данные по модулям" -->
	        <div class="block">
	          	<div class="block__header">
	              Данные по модулям
	            </div>
	            <div class="block__body">
	              	<div class="table-responsive">
		                <table class="table-custom" border="0" cellspacing="0" cellpadding="0">
		                  	<thead>
			                    <tr>
			                      	<th class="nowrap">Наименование <br /> модуля</th>
			                      	<th class="nowrap">Категория потребности <br /> капитального ремонта</th>
			                      	<th class="nowrap">Доля единичных фрагментов <br /> с повреждениями, %</th>
			                      	<th class="nowrap">Повреждения <br /> категории «А»</th>
			                    </tr>
		                  	</thead>
		                  <tbody>
		                    <tr v-for="(module) in item.modules" :key="module.id" @click="goToNext(module.id)">
		                      	<td> {{ module.name }} </td>
		                      	<td> {{ module.category }} </td>
		                      	<td> {{ module.percentage == null ? '-' : module.percentage }} </td>
		                      	<td> {{ module.damage_a ? 'Имеются' : 'Отсутствуют' }} </td>
		                    </tr>
		                  </tbody>
		                </table>
	              	</div>
	            </div>
	        </div>
	        <!-- Блок "Общие сведения" -->
	        <div class="block">
	            <div class="block__header">
	              	Общие сведения
	            </div>
	            <div class="block__body">
	                <table class="table-info">
	                  	<tr> <td> Этажность </td> <td> {{ item.mkd_info.count_floors ? item.mkd_info.count_floors : '-' }} </td> </tr>
	                  	<tr> <td> Материал наружных стен </td> <td> {{ item.mkd_info.exterior_wall_material ? item.mkd_info.exterior_wall_material : '-' }} </td> </tr>
	                  	<tr> <td> Общая площадь, м2 </td> <td> {{ item.mkd_info.total_area ? item.mkd_info.total_area : '-' }} </td> </tr>
	                  	<tr> <td> Год ввода в эксплуатацию </td> <td> {{ item.mkd_info.year_commissioning ? item.mkd_info.year_commissioning : '-' }} </td> </tr>
	                  	<tr> <td> Год последнего капитального ремонта </td> <td> {{ item.mkd_info.year_last_major_repair ? item.mkd_info.year_last_major_repair : '-' }} </td> </tr>
	                  	<tr> <td> Дата проведения обследования </td> <td> {{ item.mkd_info.date ? item.mkd_info.date : '-' }} </td> </tr>
	                  	<tr> <td> Остаточный срок эксплуатации, лет </td> <td> {{ item.mkd_info.residual_service_life ? item.mkd_info.residual_service_life : '-' }} </td> </tr>
	                </table>
	            </div>
	        </div>
	        <!-- Блок "Исполнитель обследования" -->
	        <div class="block">
	            <div class="block__header">
	              	Исполнитель обследования
	            </div>
	            <div class="block__body">
	                <table class="table-info">
	                  	<tr> <td> Наименование </td> <td> {{ item.executor.organization_name }} </td> </tr>
	                  	<tr> <td> ИНН </td> <td> {{ item.executor.organization_inn }} </td> </tr>
	                  	<tr> 
	                  		<td> ФИО эксперта </td> 
	                  		<td> {{ item.executor.executor_middle_surname + ' ' + item.executor.executor_name.charAt(0) + '. ' +  item.executor.executor_middle_name.charAt(0) + '.'}} </td> 
	                  	</tr>
	                </table>
	            </div>
	        </div>
	        <!-- Блок "Заказчик" -->
	        <div class="block">
	            <div class="block__header">
	              	Заказчик
	            </div>
	            <div class="block__body">
	                <table class="table-info">
	                  	<tr> <td> Наименование </td> <td> {{ item.customer.customer_name }} </td> </tr>
	                  	<tr> <td> ИНН </td> <td> {{ item.customer.customer_inn }} </td> </tr>
	                </table>
	            </div>
	        </div>
	        <!-- Блок "Информация о модулях" -->
	        <div class="block">
	            <div class="block__header">
	              	Информация о модулях
	            </div>
	            <div class="block__body">
	                <table class="table-info">
	                  	<tr> <td> Площадь подвала, м2 </td> <td> {{ item.mkd_info.basement_area ? item.mkd_info.basement_area : '-' }} </td> </tr>
	                  	<tr> <td> Площадь фундамента, м2 </td> <td> {{ item.mkd_info.foundation_area ? item.mkd_info.foundation_area : '-' }} </td> </tr>
	                  	<tr> <td> Площадь фасада, м2 </td> <td> {{ item.mkd_info.facade_area ? item.mkd_info.facade_area : '-' }} </td> </tr>
	                  	<tr> <td> Площадь крыши, м2 </td> <td> {{ item.mkd_info.roof_area ? item.mkd_info.roof_area : '-' }} </td> </tr>
	                  	<tr> <td> Протяженность системы ГВС, м </td> <td> {{ item.mkd_info.length_dhw_system ? item.mkd_info.length_dhw_system : '-' }} </td> </tr>
	                  	<tr> <td> Протяженность системы ХВС, м </td> <td> {{ item.mkd_info.length_hvac_system ? item.mkd_info.length_hvac_system : '-' }} </td> </tr>
	                  	<tr> <td> Протяженность системы теплоснабжения, м </td> <td> {{ item.mkd_info.length_heat_supply_system ? item.mkd_info.length_heat_supply_system : '-' }} </td> </tr>
	                  	<tr> <td> Протяженность системы водоотведения, м </td> <td> {{ item.mkd_info.length_drainage_system ? item.mkd_info.length_drainage_system : '-'}} </td> </tr>
	                  	<tr> <td> Протяженность системы электроснабжения, м </td> <td> {{ item.mkd_info.length_power_supply_system ? item.mkd_info.length_power_supply_system : '-' }} </td> </tr>
	                </table>
	            </div>
	        </div>
	        <div class="block">
	        	<div class="block__footer">
	        		<router-link :to="{name: 'PageArchive'}" class="btn btn--outline btn--md">
		        		<img class="btn--ico btn--ico--left" src="@/assets/img/6.svg">
		        		<div class="btn--text">Вернуться</div>
	        		</router-link>
	        	</div>
	        </div>
      	</div>
      	<ErrorContent  v-else/>
    </div>
</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue'
  import MkdCard from '@/components/archive/MkdCard.vue'

  import Breadcrumbs from '@/components/Breadcrumbs.vue'
  import ErrorContent from '@/components/ErrorContent.vue'
</script>

<script>
  export default {
    name: 'PageArchiveCheckup',
    data: () => ({
      item: null,
      isLoad: true,
      isError: false,
      headerText: null,
      breadcrumbs: [],
    }),
    created () {
      Promise.all([this.loadItem()])
      .then(() => {
        this.beforeRender();
        this.isLoad = false;
      })
      .catch((e)=> {
        console.log(e);
        this.isError = true;
        this.isLoad = false;
      })
    },
    computed: {
      mkd_id () {
        return this.$route.params.mkd_id;
      },
      checkup_id () {
        return this.$route.params.checkup_id;
      },
    },
    methods: {
      beforeRender() {
        this.breadcrumbs = [
          {name: 'Объекты', link: '/archive'},
          {name: this.$helpers.mkd_address(this.item), link: ''}
        ];
      },
      async loadItem () {
        this.item = await this.$store.dispatch('getCheckupByArchive', {id_checkup: this.checkup_id});
      },
      goToNext (id) {
        this.$router.push({name: 'PageArchiveModule', params: {mkd_id: this.mkd_id, checkup_id: this.checkup_id, module_id: id}});
      },
    },
  }
</script>

<style scoped>
  .block {
	  margin-top: 10px;
	}
	.table-custom tbody tr {
	  cursor: pointer;
	}
	.table-info {
	  padding: 20px 0px;
	}
	.table-info tr td {
	  width: 50%;
	}
  .block__footer {
    border: none;
    justify-content: space-between;
	}
	@media (max-width: 767px) {
  	.table-info tr td {
  		width: 100%;
  	}
	}
</style>