<template>
	<div class="error-content">
      Произошла ошибка при загрузке данных
    </div>
</template>
<script>
  	export default {
    	name: 'ErrorContent',
    	data: () => ({
      		isLoad: true,
    	})
   	}
</script>
<style scoped>
	.error-content {
    	padding: 15px 20px;
	    background-color: var(--cozh-white);
	    border-radius: 8px;
	    font-weight: 500;
	    color: rgba(var(--cozh-black-code), .5);
	    font-size: 14px;
	    line-height: 1.3;
	}
</style>