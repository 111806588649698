<template>
	<div class="component">

		<div class="component__left">
			<div class="block">
				<div class="c-search">
					<input type="text" v-model="search" placeholder="Поиск по объектам" id="search_input">
					<label class="c-search__label" for="search_input">
						<img src="@/assets/img/search-blue.svg">
					</label>
				</div>
				<div class="block__body">
					<div
						v-for="item in objectsBySearch" 
						class="c-filter"
						:class="{'selected': selected.includes(item.mkd_id)}"
						:key="item.mkd_id" 
						@click="selectMkd(item.mkd_id)">
						<div class="c-filter__name">
							<span v-html="backlight(item.address)"></span>
						</div>
						<div class="c-filter__label">
							<svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1.5 5.5L3.5 7.5L9.5 1.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="component__right">
			<yandex-map 
				ref="mapsapps"
				style="width: 100%; height: 100%"
				v-if="true"
				:settings="settings" 
				:coordinates="[58.013612, 56.20103]"
				@geo-objects-updated="mapUpdate"
				:zoom="12">

				<template v-for="item in data" :key="item.mkd_id">
					<template v-if="selected.length == 0 || selected.includes(item.mkd_id)">

						<yandex-marker 
							:coordinates="[item.mkd_lat, item.mkd_long]" 
							:options="{preset: item.dot}"
							:marker-id="item.mkd_id" 
							:properties="{hintContent: item.address}"
							type="Point">


							<template #component>
								<div class="c-baloon">
									<div class="c-baloon__header">
										{{ item.city_name }}, ул. {{ item.mkd_street }}, {{ item.mkd_building}}
									</div>
									<div class="c-baloon__body">

										<div class="c-baloon__item" v-if="item.modified_date">
											<div class="row">
												<div class="col-xs-8 col-sm-8 col-md-8">
													<div class="c-baloon__label">
														Дата последнего обследования
													</div>
												</div>
												<div class="col-xs-4 col-sm-4 col-md-4">
													<div class="c-baloon__value">
														{{ $moment(item.modified_date).format('DD.MM.YYYY') }}
													</div>
												</div>
											</div>
										</div>

										<div class="c-baloon__item">
											<div class="row">
												<div class="col-xs-8 col-sm-8 col-md-8">
													<div class="c-baloon__label">
														Обследователь
													</div>
												</div>
												<div class="col-xs-4 col-sm-4 col-md-4">
													<div class="c-baloon__value">
														{{ item.surname + ' ' + Array.from(item.name)[0] + '. ' + Array.from(item.middle_name)[0] + '.' }}
													</div>
												</div>
											</div>
										</div>

										<div class="c-baloon__item">
											<div class="row">
												<div class="col-xs-8 col-sm-8 col-md-8">
													<div class="c-baloon__label">
														Общее количество модулей
													</div>
												</div>
												<div class="col-xs-4 col-sm-4 col-md-4">
													<div class="c-baloon__value">
														{{ item.count_modules }}
													</div>
												</div>
											</div>
										</div>

										<div class="c-baloon__item">
											<div class="row">
												<div class="col-xs-8 col-sm-8 col-md-8">
													<div class="c-baloon__label">
														Количество модулей 2 кат.
													</div>
												</div>
												<div class="col-xs-4 col-sm-4 col-md-4">
													<div class="c-baloon__value">
														{{ item.count_cat_2 }}
													</div>
												</div>
											</div>
										</div>

										<div class="c-baloon__item">
											<div class="row">
												<div class="col-xs-8 col-sm-8 col-md-8">
													<div class="c-baloon__label">
														Количество модулей 3 кат.
													</div>
												</div>
												<div class="col-xs-4 col-sm-4 col-md-4">
													<div class="c-baloon__value">
														{{ item.count_cat_3 }}
													</div>
												</div>
											</div>
										</div>

									
									</div>
								</div>
							</template>


						</yandex-marker>

					</template>
				</template>


			</yandex-map>
		</div>
		
	</div>
</template>


<script setup>
  import { YandexMap, YandexMarker } from 'vue-yandex-maps/dist/vue-yandex-maps.esm';
</script>

<script>
  export default {
    
    name: 'RootMap',

    data: () => ({
		data: [],
		selected: [],
		search: '',
		settings: {
		  	apiKey: '59cbac06-aec3-4721-bc5a-6cf5e1d7be57',
		  	lang: 'ru_RU',
		  	coordorder: 'latlong',
		  	debug: false,
		  	version: '2.1',
		},
    }),
    
    created () {
    	this.loadMap();
    },

    computed: {
    	backlight () {
        return (text) => {
          if(this.search !== null && this.search !== '') {
            const idx = text.toLowerCase().indexOf(this.search.toLowerCase());
            const substring = text.substring(idx, idx + this.search.length);
            return text.replace(substring, '<span class="text-yellow">' + substring + '</span>');
          }
          return text;
        }
      },
    	objectsBySearch () {
    		const list = [];
    		this.data.forEach((item) => {
				if (item.address.toLowerCase().indexOf(this.search.toLowerCase()) >= 0) {
    				list.push(item);
				}
    		});
    		return list;
    	},
    },
 
    methods: {

    	selectMkd (id) {
    		const idx = this.selected.findIndex((item) => { return item == id });
    		if(idx !== -1) {
    			this.selected.splice(idx, 1);
    		} else {
    			this.selected.push(id);
    		}
    	},

    	mapUpdate (e) {
    		const map = e._map;
    		const points = map.geoObjects.getBounds();
    		if (points !== null) {
	    		map.container.fitToViewport();
	    		map.setBounds(points);
	    		let zoom = map.getZoom();
	    		if(zoom > 16) {
	    			zoom = 16;
	    		}
	       		map.setZoom(zoom, {checkZoomRange: true});
    		}
    	},

    	async loadMap () {
    		const data = await this.$store.dispatch('getMap');
    		data.forEach((mkd) => {
    			mkd.address = mkd.city_name + ', ул. ' + mkd.mkd_street + ', ' + mkd.mkd_building;
    			if(mkd.count_cat_3 > 0) {
    				mkd.dot = 'islands#redCircleDotIcon';
    			}
    			if(mkd.count_cat_2 > 0 && mkd.count_cat_3 == 0) {
    				mkd.dot = 'islands#yellowCircleDotIcon';
    			}
    			if(mkd.count_cat_2 == 0 && mkd.count_cat_3 == 0) {
    				mkd.dot = 'islands#darkGreenCircleDotIcon';
    			}
    		});
    		this.data = data;
    	},
    }
  }
</script>

<style scoped>

	.component {
    	height: calc(100dvh - 110px);
    	width: 100%;
    	display: flex;
    	flex-direction: row;
    	align-items: flex-start;
    	justify-content: space-between;
	}
	.component__left {
		height: 100%;
    	width: 100%;
    	margin-right: 10px;
    	min-width: 360px;
    	max-width: 360px;
	}
	.component__right {
		height: 100%;
		width: 100%;
    	overflow: hidden;
    	border-radius: 12px;
	}

	.block {
    	height: 100%;
    	overflow: hidden;
	}
	.block__body {
		padding: 20px;
	}
	.block__header {
		padding: 0px;
	}




	.c-filter {
		border-radius: 6px;
		padding: 11px 10px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
	}
	.c-filter:not(:last-child) {
		margin-bottom: 5px;
	}
	
	.c-filter__name {
		font-size: 13px;
		font-weight: 500;
	}
	.c-filter__label {
		height: 18px;
		width: 18px;
		background-color: var(--cozh-light-grey);
		border-radius: 4px;
		display: flex;
    	flex-direction: row;
    	align-items: center;
    	justify-content: center;
		transition: all .1s ease-out;
	}
	.c-filter__label svg {
		opacity: 0;
		visibility: hidden;
	}
	.c-filter.selected {
		background-color: rgba(var(--cozh-blue-code), .08);
	}
	.c-filter.selected .c-filter__label {
		background-color: var(--cozh-blue);
	}
	.c-filter.selected .c-filter__label svg {
		opacity: 1;
		visibility: visible;
	}


	.c-baloon {
	  	width: 100%;
	}
	.c-baloon__header {
		font-size: 16px;
    	font-weight: 600;
    	font-family: 'Inter';
    	width: 100%;
    	border-bottom: 1px solid var(--cozh-light-grey);
    	padding: 13px 20px;
	}
	.c-baloon__body {
		padding: 20px;
	}
	.c-baloon__item:not(:last-child) {
		margin-bottom: 10px;
	}
	.c-baloon__label {
		font-weight: 500;
    	font-size: 12px;
    	font-family: 'Inter';
    	color: rgba(var(--cozh-black-code), .5);
    	line-height: 1.3;
	} 
	.c-baloon__value {
		font-weight: 500;
    	font-size: 12px;
    	font-family: 'Inter';
    	color: rgba(var(--cozh-black-code), 1);
    	line-height: 1.3;
	}


	.c-search {
		position: relative;
	}

	.c-search__label {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 53px;
		height: 100%;
		border-right: 1px solid var(--cozh-light-grey);
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.c-search img {
		width: 20px;
	}

	.c-search input {
		height: 53px;
		width: 100%;
		border: none;
		border-bottom: 1px solid var(--cozh-light-grey);
		padding-left: 68px;
		font-size: 15px;
		font-weight: 500;
	}

	.c-search input::placeholder {
		font-size: 15px;
		color: rgba(var(--cozh-black-code), .5);
		transition: all .2s ease-out;
	}
	.c-search input:focus::placeholder {
		opacity: 0;
		visibility: hidden;
	}

	@media (max-width: 767px) {
		.c-filter {
    		padding: 6px 10px;
    	}
		.component {
			flex-direction: column-reverse;
	    	height: calc(100dvh - 150px);
	    }
		.component__left {
			min-width: 100%;
			max-width: 100%;
			margin-right: 0px;
			margin-top: 10px;
			height: 50%;
		}
		.block__body {
		    padding: 10px;
		    height: calc(100% - 40px);
		    overflow-y: auto;
		}
		.c-baloon__item:not(:last-child) {
		    margin-bottom: 5px;
		}

		.c-baloon__header {
		    font-size: 14px;
		    padding: 8px 10px;
		}
		.c-baloon__body {
		    padding: 10px 10px;
		}
	}

</style>

<style type="text/css">
	@media (max-width: 767px) {
		ymaps[class*="balloon_layout_panel"] {
			margin-bottom: 70px;
    		width: calc(100% - 30px);
    		margin-left: 15px;
		}
	}
</style>