<template>
    <popup-modal ref="popup" :size="'lg'" @close="close">
     
     <div class="popup popup--lg">
        <div class="popup__header">
          <div class="popup__title"> {{ title }} </div>
        </div>
        <div class="popup__body popup__body--nopadding">
          <div class="field" v-for="(item) in items" :key="item.id">
            <div class="checkbox">
              <input type="checkbox" :id="item.id" :value="item.id" v-model="selected">
              <label :for="item.id"> {{ item.name }} </label>
            </div>
          </div>
        </div>
        <div class="popup__footer">
          <div class="btn-group">
            <a class="btn btn--default btn--lg me-1" @click="linked($event)">
              <img class="btn--ico btn--ico--left" src="../../assets/img/8.svg">
              <div class="btn--text">Выбрать</div>
              <div class="btn--loader"></div>
            </a>
            <a class="btn btn--outline btn--lg" @click="close">
              Отменить
            </a>
          </div>
        </div>
      </div>
      
    </popup-modal>
</template>

<script>
  
  import PopupModal from './PopupModal.vue'

  export default {

    name: 'LinkedItems',

    components: { PopupModal },

    data: () => ({
      selected: [],
      title: null,
      items: null,
    }),

    methods: {
      close() {
        this.clearSelected();
        this.$refs.popup.close();
      },
      show(opts = {}) {
          this.title = opts.title;
          this.items = opts.items;
          this.$refs.popup.open();
      },

      clearSelected () {
        this.selected = [];
      },

      async linked(e) {
        const btn = e.currentTarget;
        if(this.selected.length > 0) {
          await this.$emit('linked', this.selected, btn);
        }
      },

    },

  };
</script>

<style scoped>
  .field {
      padding: 10px 20px;
  } 
  .field:not(:last-child) {
      border-bottom: 1px solid var(--cozh-light-grey);
  }
  @media (max-width: 767px) {
    .field {
        padding: 10px 20px;
    }
  }
</style>