<template>
  
  <TopMenu :headerText="null" />

  <!-- Карта для Root -->
	<RootMap v-if="id_role == 0" />

  <!-- Карта для Admin -->
	<AdminMap v-if="id_role == 1" />

</template>


<script setup>
  import TopMenu from '@/components/TopMenu.vue';
  import RootMap from '@/components/map/RootMap.vue';
  import AdminMap from '@/components/map/AdminMap.vue';
</script>

<script>
  export default {
    name: 'PageMap',
    data: () => ({
    }),
    created () {
    },
    computed: {
    	id_role () {
        return this.$store.state.auth.user.profile.id_role;
      },
    },
    methods: {
    }
  }
</script>

<style>
	.yandex-balloon {
	  height: auto!important;
	  /*min-height: 200px!important;*/
	  width: 377px;
	}
	ymaps[class*="balloon_layout_normal"], ymaps[class*="balloon_layout_panel"] {
	  box-shadow: none;
	  border-radius: 12px;
	  overflow: hidden;
	  filter: drop-shadow(0px 8px 24px rgba(var(--cozh-black-code), .15));

	}
	ymaps[class*="balloon__content"] {
	  margin-right: 0px!important;
	  padding: 0px;
	}
	ymaps[class*="balloon__content"] > ymaps {
	  height: auto!important;

	}
	ymaps[class*="balloon__close-button"] {
		margin-right: 5px;
	}

	@media (max-width: 767px) {
		.yandex-balloon {
		  height: auto!important;
		  width: 100%;
		}
	}

</style>