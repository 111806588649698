<template>
	<div class="steps" ref="content">

      <div class="steps__item" :class="{'active' : routeName == 'PageCheckupCreate'}" v-if="!checkup_id">
        Заказчик
      </div>

      <router-link 
        :to="{name: 'PageCheckupCustomer', params:{mkd_id: mkd_id, checkup_id: checkup_id}}"
        class="steps__item" 
        :class="{'active' : routeName == 'PageCheckupCustomer'}" 
        v-if="checkup_id">
        Заказчик
      </router-link>

      <div class="steps__item" :class="{'active' : routeName == 'PageCheckupModules'}" v-if="!checkup_id">
        Модули
      </div>

      <router-link 
        :to="{name: 'PageCheckupModules', params:{mkd_id: mkd_id, checkup_id: checkup_id}}"
        class="steps__item" 
        :class="{'active' : routeName == 'PageCheckupModules'}" 
        v-if="checkup_id">
        Модули
      </router-link>

      <div class="steps__item" :class="{'active' : routeName == 'PageCheckupElements'}" v-if="!module_id">
        Элементы
      </div>

      <router-link 
        :to="{name: 'PageCheckupElements', params:{mkd_id: mkd_id, checkup_id: checkup_id, module_id: module_id}}"
        class="steps__item" 
        :class="{'active' : routeName == 'PageCheckupElements'}" 
        v-if="module_id">
        Элементы
      </router-link>
      

      <div class="steps__item" :class="{'active' : routeName == 'PageCheckupElement'}">
        Элемент
      </div>

    </div>
</template>
<script>
  export default {
    
    name: 'CheckupNavigator',
    
    // props: ['mkd_id', 'checkup_id'],

    computed: {
      routeName () {
        return this.$route.name;
      },
      mkd_id () {
        return this.$route.params.mkd_id;
      },
      checkup_id () {
        return this.$route.params.checkup_id;
      },
      module_id () {
        return this.$route.params.module_id;
      },
      element_id () {
        return this.$route.params.element_id;
      },
    },

    mounted () {
      const childs = this.$refs.content.getElementsByClassName("active");
      if(childs.length > 0) {
        const child = childs[0];
        this.$refs.content.scrollTo({left: 0});
        const parentPos = this.$refs.content.getBoundingClientRect();
        const childPos = child.getBoundingClientRect();
        const centerPosition = childPos.left - parentPos.left - (parentPos.width / 2) + (childPos.width / 2);
        this.$refs.content.scrollTo({left: centerPosition, behavior: "smooth" });
      }
    }
  }
</script>
<style scoped>
</style>