<template>
  <div class="header">
    <HeaderBlock :headerText="headerText" />
    <UserState class="hidden-xs" />
  </div>
</template>

<script setup>
  import HeaderBlock from './HeaderBlock.vue';
  import UserState from './UserState.vue';
</script>

<script>
  export default {
    name: 'TopMenu',
    props: ['headerText'],
    data: () => ({

    }),
    methods: {

    },
  }
</script>

<style scoped>
  .header {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
</style>