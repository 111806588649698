<template>

	<TopMenu :headerText="null" />
  <MessageDialogue ref="messageDialogue" />
  <ConfirmDialogue ref="confirmDialogue" />

	<!-- Пользователи -->
	<div class="block mt-1">
    <div class="block__header block__header--btn">
      Пользователи
      <router-link :to="{name: 'PageUserCreate'}" class="btn btn--grey btn--sm">
        <img class="btn--ico btn--ico--left" src="@/assets/img/plus-blue-small.svg">
        <div class="btn--text"> Добавить  <span class="hidden-xs"> пользователя </span> </div>
      </router-link>
    </div>
    <div class="block__body">
      <grid-table :options="options">
        <template v-slot="{ item, reload }">
          <tr>
          	<td width="1px" v-if="id_role == 0">{{item.id}}</td>
            <td style="padding: 8px 20px;">
              <div class="c-filed-row">
                <div class="avatar" :style="{ backgroundImage: item.avatar ? 'url(' + item.avatar + ')' : ''}">
                  <img class="avatar__badge" src="@/assets/img/star.svg" v-if="item.role_name == 'Администратор'">
                </div>
                <span class="nowrap ms-1"> {{item.fullname}} </span>
              </div>
            </td>
            <td>
              {{item.role_name}}
            </td>
            <td v-if="id_role == 0">
              <router-link class="link-default" :to="{name: 'PageOrganization', params: {organization_id: item.id_organization}}">
                <span> {{item.organization_name}} </span>
              </router-link>
            </td>
            <td style="width: 1px;">
              <div class="c-filed-row">
                <router-link class="link-default fs-12" :to="{name: 'PageUserProfile', params: {user_id: item.id}}">
                  <img class="btn--ico btn--ico--left" src="@/assets/img/edit-blue.svg" style="margin-bottom: 2px;">
                  <span class="btn--text"> Изменить </span>
                  <div class="btn--loader"></div>
                </router-link>
                <a class="link-default ms-3 fs-12" @click="deleteUser($event, item.id, reload)">
                  <img class="btn--ico btn--ico--left" src="@/assets/img/cross-blue.svg">
                  <span class="btn--text"> Удалить </span>
                  <div class="btn--loader"></div>
                </a>
              </div>
            </td>
          </tr>
        </template>
      </grid-table>
    </div>
  </div>
</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue'
  import GridTable from '@/components/ux/GridTable.vue';
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
</script>

<script>
  	export default {
	    
	    name: 'PageUsersMain',

	    data: () => ({
	    	options: {
	        method: 'getUsers',
	        display: 10,
	        fields: [
	          	{key:'fullname', name:'ФИО'},
	          	{key:'id_role', name:'Роль'},
	          	{key: false, name:''},
	        ],
	        filters: [
	        	{key: 'fullname', type: 'text'},
	        	{key: 'id_role', type: 'select', list: [{id: 1, name: "Администратор"}, {id: 2, name: "Обследователь"}]},
	        ],
      	},
      	organizations: [],
	    }),

	    created () {

	    	// Для рута
	      if(this.id_role == 0) {

	      	// Выводим ID
        	this.options.fields.splice(0, 0, {key: 'id', name: 'ID'})

        	// Выводим Организацию
          this.options.fields.splice(3, 0, {key: 'id_organization', name:'Организация'})

        	// Фильтр по организациям
        	this.options.filters.splice(2, 0, { key: 'id_organization', type: 'select', list: [] });

		    	Promise.all([this.loadOrganizations()])
          
	          .then(() => {
	            // Данные для фильтра по организациям
	            this.options.filters[2].list = this.organizations;
	          })

	          .catch(()=> {})

			  }

	    },

	    computed: {
	      id_role () {
	        return this.$store.state.auth.user.profile.id_role;
	      },
	    },

	    methods: {
	    	async loadOrganizations () {
    			const { items } = await this.$store.dispatch('getOrganizations', {display: 1000, filter: {}, order: {}, page: 1});
    			this.organizations = items;
    		},

	    	async deleteUser (e, id, reload) {
	        const btn = e.currentTarget;
	        btn.classList.add('btn--load');
	        const confirm = await this.$refs.confirmDialogue.show({
	          message: 'Вы уверены, что хотите удалить этого пользователя?',
	          okButton: 'Удалить',
	        });
	        if(confirm) {
		        try {
		            await this.$store.dispatch('deleteUser', {id_user: id});
    				reload();
		        } catch (e) {
		          this.$refs.messageDialogue.show({message: this.$helpers.getErrorMessage(e), okButton: 'Подтвердить'});
		        }
	        }
	        btn.classList.remove('btn--load');
	      },
	    },
  	}
</script>

<style scoped>
	.c-filed-row {
	    display: flex;
	    flex-direction: row;
	    align-items: center;
	    justify-content: flex-start;
	}
</style>