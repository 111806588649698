<template>

	<form class="grid-filter" v-if="options.filters.length > 0" @submit.prevent="saveFilters($event)">
		<div class="grid-filter__container">
			<div class="grid-filter__control" v-for="(f, index) in options.filters" :key="index" :class="{'grid-filter__control--checkbox': f.type == 'checkbox'}">
				
				<!-- Фильтр текст -->
				<template v-if="f.type == 'text'">
					<div class="form-group">
						<input type="text" class="form-control form-control--outline" :placeholder="filedByKey(f.key).name" v-model="localFilter[f.key]">
			  		<transition name="slide">
							<div class="form-clear" v-if="localFilter[f.key] && localFilter[f.key] !== '' && localFilter[f.key] !== null" @click="localFilter[f.key] = null">
								<svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M9.5 1L1.5 9M1.5 1L9.5 9" stroke="#aec1cc" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
							</div>
						</transition>
					</div>
				</template>

				<!-- Фильтр селект -->
				<template v-if="f.type == 'select'">
  				<SelectPicker 
  					:list="f.list" 
  					:isClear="true" 
  					v-model="localFilter[f.key]" 
  					:label="f.label ? f.label : filedByKey(f.key).name" 
  					size="sm"
  				/>
				</template>

				<!-- Фильтр чекбокс -->
				<template v-if="f.type == 'checkbox'">
					<div class="checkbox">
						<input :id="f.key" type="checkbox" v-model="localFilter[f.key]">
						<label :for="f.key"> {{ f.label }} </label>
					</div>
				</template>

				 <!-- @change="(e) => { params.filter[f.key] = e.currentTarget.checked ? f.value : null }" -->
			</div>
		</div>
		<div class="grid-filter__buttons">
			<button type="submit" class="btn btn--default btn--small grid-filter__btn">
        <div class="btn--text"> Применить <span class="hidden-xs"> фильтры </span> </div>
       	<div class="btn--loader"></div>
      </button>
      <a class="btn btn--outline btn--small grid-filter__btn" @click="clearFilters($event)">
        <div class="btn--text"> Сбросить <span class="hidden-xs"> фильтры </span> </div>
        <div class="btn--loader"></div>
      </a>
		</div>
	</form>

	<div class="table-responsive">
  	<table class="table-custom">
      <thead>
      	<tr>
      		<template v-for="(field, index) in options.fields" :key="index">
	        	<th class="nowrap" v-if="!field.isHidden">
	        		<div class="grid-order" :class="orderColamnClass(field)" @click="setOrder(field)" v-if="field.key">
	        			<div class="grid-order__column" v-html="field.name">
	        			</div>
	        			<div class="grid-order__container">
	        				<svg 
	        					class="grid-order__control" 
					        	width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1 4L3.5 1.5L6 4" stroke="#AAB4B9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
									<svg 
										class="grid-order__control" 
										width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1 1L3.5 3.5L6 1" stroke="#AAB4B9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
	        			</div>
	        		</div>
	        		<div class="grid-order" v-html="field.name" v-else></div>
	        	</th>
      		</template>

      	</tr>
      </thead>
			<tbody class="load-container" :class="{'load' : isLoad}">
				<template v-if="items.length > 0">
					<slot :item="item" :params="params" :index="index + 1" :reload ="loadItems" v-for="(item, index) in items" :key="index">
						
						<!-- slot content in parent -->
					
					</slot>
				</template>
				<div v-else class="grid-empty">
					Список пуст...
				</div>
      </tbody>
  	</table>
  </div>
  	<div class="grid-pager">
  		<div class="grid-pagination">
  			<template v-if="state.total_page > 1">
	  			<!-- Стрелка в начало -->
	  			<a class="grid-pagination__item" :class="{'grid-pagination__item--disabled': params.page <= 1}" @click="setPage(1)">
	  				<svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M8.5 1L3.5 6L8.5 11M1.5 1V11" stroke="#AAB4B9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
	  			</a>
	  			<!-- Стрелка предыдущий -->
	  			<a class="grid-pagination__item" :class="{'grid-pagination__item--disabled': params.page <= 1}" @click="setPage(params.page - 1)">
	  				<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M6 1L1 6L6 11" stroke="#AAB4B9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
	  			</a>
	  			<!-- Отбивка от стрелок -->
	  			<div class="grid-pagination__item" style="width: 20px;"></div>
	  			<template v-if="params.page >= 3">
	      			<!-- Цифра в начало -->
	      			<a class="grid-pagination__item" @click="setPage(1)">
	      				1
	      			</a>
	      			<!-- Отбивка от цифр -->
	      			<div class="grid-pagination__item"> ... </div>
	  			</template>
	  			<!-- Цифра предыдущий -->
	  			<a class="grid-pagination__item" v-if="params.page - 1 >= 1" @click="setPage(params.page - 1)"> {{ params.page - 1 }} </a>
	  			<div class="grid-pagination__item grid-pagination__item--current">
	  				{{ params.page }}
	  			</div>
	  			<!-- Цифра следующий -->
	  			<a class="grid-pagination__item" v-if="params.page + 1 <= state.total_page" @click="setPage(params.page + 1)"> {{ params.page + 1 }} </a>
	  			<template v-if="params.page < state.total_page - 1">
	      			<!-- Отбивка от цифр -->
	      			<div class="grid-pagination__item"> ... </div>
	      			<!-- Цифра в конец -->
	      			<a class="grid-pagination__item" @click="setPage(state.total_page)">
	      				{{ state.total_page }}
	      			</a>
	  			</template>
	  			<!-- Отбивка от стрелок-->
	  			<div class="grid-pagination__item" style="width: 20px;"></div>
	  			<!-- Стрелка следующий -->
	  			<a class="grid-pagination__item" :class="{'grid-pagination__item--disabled': params.page >= state.total_page}" @click="setPage(params.page + 1)">
	  				<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1 1L6 6L1 11" stroke="#AAB4B9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
	  			</a>
	  			<!-- Стрелка в конец -->
	  			<a class="grid-pagination__item" :class="{'grid-pagination__item--disabled': params.page >= state.total_page}" @click="setPage(state.total_page)">
	  				<svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1.5 1L6.5 6L1.5 11M8.5 1V11" stroke="#AAB4B9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
	  			</a>
  			</template>
  		</div>
  		<div class="grid-display">
  			<div class="grid-display__text">
  				Выводить на странице по
  			</div>
  			<SelectPicker :list="display" v-model="params.display" size="xs"/>
  		</div>

  	</div>

</template>

<script setup>
  import SelectPicker from '@/components/ux/SelectPicker.vue';
</script>

<script>
	export default {
		
		name: 'GridTable',
		
		props: ['options'],

	    data: () => ({
  			
  			isLoad: false,
  			isError: false,
  			
  			items: [],
  			display: [{id: 2, name: '2'}, {id: 5, name: '5'}, {id: 10, name: '10'}, {id: 20, name: '20'}],

  			params: {
				  display: 10,
				  page: 1,
				  order: {},
				  filter: {},
				},

				state: {
					total_page: 0,
					total_items: 0,
				},

				localFilter: {},

	    }),
	    
	    created () {

	    	// Следим за каждым полем фильтра
	    	this.options.fields.forEach((field) => {

	  			if(this.options.defaultFilter) {
		    		// Кроме дэфолтных
		  			const idx = this.options.defaultFilter.findIndex((item) => {return item.key == field.key});
		    		if(idx !== -1) return;
		    	}

	    		this.$watch('localFilter.' + field.key, function (val) {
	    			// Значение пустое - сбрасываем
				    if(val == null || val + ''.trim() == '') {
	        		// this.localFilter[field.key] = null;
	  					this.loadItems();
	        	}
				  }, {deep: true})
	    	});


	    	if(this.params.display !== this.options.display) {
	    		// watcher видит изменения, запускает подгрузку
	    		this.params.display = this.options.display;
	    	} else {
	    		// 
	    		this.loadItems();
	    	}

	  	},

	  	computed: {
	  		orderColamnClass () {
	  			return (field) => {
	  				if(this.params.order[field.key]) {
	  					return 'grid-order--' + this.params.order[field.key];
	  				}
	  			}
	  		},
	  		filedByKey () {
	  			return (key) => {
	  				const idx = this.options.fields.findIndex((item) => { return item.key == key })
	  				return this.options.fields[idx];
	  			}
	  		}
	  	},

	  	watch: {
	  		'params.display': function () {
	  			this.params.page = 1;
					this.loadItems();
	  		}
	  	},
		  	
	  	methods: {

	  		setPage (page) {
	  			if(page < 1 || page > this.state.total_page) return;
	  			this.params.page = page;
					this.loadItems();
	  		},

	  		setOrder (field) {
	  			if (this.params.order[field.key] && this.params.order[field.key] == 'asc') {
		  				this.params.order[field.key] = 'desc';
					} else if (this.params.order[field.key] && this.params.order[field.key] == 'desc') {
	  					delete this.params.order[field.key];
					} else {
						this.params.order = {};
		  				this.params.order[field.key] = 'asc';
					}
					this.loadItems();
	  		},

	  		async saveFilters (e) {

	  			const btn = e.submitter;

	  			btn.classList.add('btn--load');

	        await new Promise(r => setTimeout(r, 200));


	  			this.params.page = 1;

	  			await this.loadItems();

	  			btn.classList.remove('btn--load');

	  		},

	  		async clearFilters (e) {

	  			const btn = e.currentTarget;
	  			
	  			btn.classList.add('btn--load');

	        await new Promise(r => setTimeout(r, 200));

	  			// Сбрасываем все фильтры кроме дэфолтных
	  			for (let key in this.localFilter) {
	  				if(this.options.defaultFilter) {
		  				const idx = this.options.defaultFilter.findIndex((item) => {return item.key == key});
		  				if(idx == -1) {
		  					delete this.localFilter[key];
		  				}
	  				} else {
		  				delete this.localFilter[key];
	  				}
	  			}
	  			await this.loadItems();

	  			btn.classList.remove('btn--load');

	  		},

	  		buildFilters () {
	  			
	  			const filter = {};

	        // Фильтр по умолчанию определены
		    	if(this.options.defaultFilter) {
		    		// Проходимся по каждому
		    		this.options.defaultFilter.forEach((item) => {
		    			// Пушим в фильтр
  						filter[item.key] = item.value;
		    		});
		    	}

		    	// Проходимся по всем основным фильтрам
		    	for (let key in this.localFilter) {

		    		// Если фильтр пустой - пропускаем
	        	if(this.localFilter[key] == null || this.localFilter[key] + ''.trim() == '') { continue; }

        		// Ищем фильтр в опциях
  					const idx = this.options.filters.findIndex((item) => { return item.key == key })

  					// Чекбоксы по отдельному сценарию
  					if(this.options.filters[idx].type == 'checkbox') {

  						// Чекбокс прожат
  						if(this.localFilter[key]) {
  							
  							// Пушим параметр в фильтр
  							filter[key] = this.options.filters[idx].value;

  						}

  					} else {
  						
  						// Пушим параметр в фильтр
  						filter[key] = this.localFilter[key];

  					}

	        }

	        this.params.filter = filter;


	  		},

	  		async loadItems () {

	        this.isLoad = true;

					this.buildFilters();

					// this.items = [];

					try {

	        	const {items, total_items} = await this.$store.dispatch(this.options.method, this.params);

	        	this.items = items;
        		this.state.total_page = Math.ceil(total_items / this.params.display);
        		this.state.total_items = total_items;

					} catch (e) {

						e;

	        	this.state.total_page = 0;
						this.state.total_items = 0;
	        	this.items = [];
					
					}

	        this.isLoad = false;
        	
	    	},
	  	},

	}
</script>

<style scoped>
	
	tbody.load-container {
	    min-height: 100px;
	    /*height: 100px;*/
	}

	tbody.load-container.load {
	    min-height: 100px;
	    /*max-height: 200px;*/
	    height: 100px;
	}

	.table-responsive {
    	border-bottom: 1px solid var(--cozh-light-grey);
	}

	.grid-order {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;
		padding: 15px 20px;
	}

	.grid-order__column {
		margin-right: 10px;
		user-select: none;
		transition: all .2s ease-out;
	}

	.grid-order__container {
		display: flex;
	    flex-direction: column;
	    align-items: center;
	    justify-content: space-between;
	    height: 13px;
	}

	.grid-order__control path {
		transition: all .2s ease-out;
	}

	.grid-order--asc .grid-order__container .grid-order__control:first-child path {
		stroke: var(--cozh-black);
	}

	.grid-order--desc .grid-order__container .grid-order__control:last-child path {
		stroke: var(--cozh-black);
	}

	.grid-order--asc .grid-order__column, .grid-order--desc .grid-order__column {
		color: var(--cozh-black);
	}

	.grid-order:hover .grid-order__column {
		color: var(--cozh-black);
	}

	.table-custom th {
		padding: 0px;
	}

	.grid-pager {
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 15px;
		padding-right: 25px;
	}

	.grid-pagination {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.grid-pagination__item {
		height: 30px;
		width: 30px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-weight: 600;
		color: var(--cozh-dark-grey);
		user-select: none;
		line-height: 1;
	}

	.grid-pagination__item--current {
		color: var(--cozh-black);
	} 

	.grid-pagination__item svg path {
		transition: all .2s ease-out;
	}

	.grid-pagination__item:hover svg path {
		stroke: var(--cozh-blue);
	}
	.grid-pagination__item:not(.grid-pagination__item--current):hover {
		color: var(--cozh-blue);
	}
	.grid-pagination__item--disabled {
		pointer-events: none;
		opacity: .5;
	}


	.grid-display {
		display: flex;
		align-items: center;
	}

	.grid-display__text {
		color: var(--cozh-grey);
		font-size: 12px;
		font-weight: 500;
		margin-right: 10px;
	}

	.grid-filter {
		padding: 10px 20px;
    	border-bottom: 1px solid var(--cozh-light-grey);
    	display: flex;
    	flex-direction: row;
    	align-items: center;
    	justify-content: space-between;
	}

	.grid-filter__container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
	}
	.grid-filter__control {
    width: 100%;
    min-width: 200px;
	}
	.grid-filter__control:not(:last-child) {
		margin-right: 10px;
	}

	.grid-filter__buttons {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-left: 10px;
	}
	.grid-filter__buttons button {
		margin-right: 10px;
	}
	.grid-empty {
		padding: 15px 20px;
		font-weight: 500;
		font-size: 13px;
		color: rgba(var(--cozh-black-code), .5);
		white-space: nowrap;
	}

	.grid-filter__control--checkbox {
		width: fit-content;
    min-width: fit-content;
		margin-left: 10px;
    margin-right: 10px;
	}

	.checkbox label {
		white-space: nowrap;
	}
	@media (max-width: 767px) {


		.grid-pager {
			flex-direction: column-reverse;
		    align-items: center;
		    padding: 15px 20px 10px 20px;
		    height: auto;
		}
		.grid-pagination {
			margin-top: 10px;
		}
		.grid-filter {
			flex-direction: column;
		}
		.grid-filter__buttons {
			width: 100%;
			margin-top: 10px;
			margin-left: 0px;
		}
		.grid-filter__btn {
			width: 50%;
		}

		.grid-filter__container {
			flex-direction: column;
		}
		.grid-filter__control {
			width: 100%;
		}
		.grid-filter__control:not(:last-child) {
			margin-right: 0px;
			margin-bottom: 10px;
		}

		.grid-filter__control--checkbox {
			margin-top: 5px;
			margin-bottom: 5px;
			margin-left: 0px;
    	margin-right: 0px;
		}

	}

</style>