<template>
	<div class="checkboxes">
		<div class="checkboxes__label" v-if="label !== null">
			{{ label }}
		</div>
		<div class="checkboxes__container">
			<div class="checkbox" v-for="(item) in list" :key="item.id">
				<input :id="uniq + item.id" type="checkbox" v-model="picked" :value="item.id">
				<label :for="uniq + item.id"> {{ item.name }} </label>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
	    name: 'CheckboxesPicker',
	    props: ['list', 'label', 'modelValue'],
	    data: () => ({
	    	picked: [],
	    	uniq: null,
	    }),
	    watch: {
	    	picked (val) {
	    		this.$emit('update:modelValue', val);
	    	},
	    	modelValue (val) {
	    		this.picked = val;
	    	}
	    },
	    created () {
	    	this.picked = this.modelValue;
	    	this.uniq = "id" + Math.random().toString(16).slice(2);
	    },
	    computed: {
	    	displayName () {
	    		if(this.modelValue !== null) {
		    		var index = this.list.findIndex((item) => {
				        return item.id == this.modelValue;
				    });
			    	return this.list[index].name;
	    		} else {
	    			return null;
	    		}
	    	},
	    },
	    methods: {
	    	// setValue (val) {
	    	// 	// Можно Передавать null для очищения
	    	// 	this.$emit('update:modelValue', val);
	    	// },
	    },
    }
</script>

<style scoped>
	.checkboxes {
		padding: 15px 0px 15px 0px;
    	/*border: 1px solid var(--cozh-light-grey);*/
    	/*border-radius: 8px;*/
	}
	.checkboxes__label {
	    font-size: 11px;
	    font-weight: 600;
	    line-height: 1;
	    letter-spacing: 0.05em;
	    text-transform: uppercase;
	    color: rgba(var(--cozh-black-code), .35);
	    -webkit-user-select: none;
	    -moz-user-select: none;
	    user-select: none;
	    pointer-events: none;
	    display: none;
	}
	.checkboxes__container {
		display: flex;
		flex-direction: column;
		/*margin-top: 10px;*/
	}
	.checkbox:not(:last-child) {
		margin-bottom: 10px;
	}
</style>