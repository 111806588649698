import { h } from 'vue'
import { RouterView } from 'vue-router'
import { createWebHistory, createRouter } from "vue-router";
import store from './store';

import PageLogin from './pages/PageLogin.vue';
import PageNotFound from './pages/PageNotFound.vue';


import PageHome from './pages/home/PageHome.vue';

import PageOrganization from "./pages/organizations/Main.vue";
import PageOrganizationCreate from "./pages/organizations/Create.vue";


import PageProfile from './pages/profile/PageProfile.vue';

import PageUsers from './pages/users/Main.vue';
import PageUserProfile from './pages/users/Profile.vue';
import PageUserCreate from './pages/users/Create.vue';

import PageContracts from "./pages/contracts/Main.vue";
import PageContractsCreate from "./pages/contracts/Create.vue";
import PageContractsEdit from "./pages/contracts/Edit.vue";


import PageObjects from "./pages/objects/Main.vue";
import PageObjectsCheckupList from "./pages/objects/CheckupList.vue";
import PageObjectsCreate from "./pages/objects/Create.vue";
import PageObjectsView from "./pages/objects/View.vue";


import PageCheckupCreate from "./pages/checkups/PageCheckupCreate.vue";
import PageCheckupCustomer from "./pages/checkups/PageCheckupCustomer.vue";
import PageCheckupModules from "./pages/checkups/PageCheckupModules.vue";
import PageCheckupElements from "./pages/checkups/PageCheckupElements.vue";
import PageCheckupElement from "./pages/checkups/PageCheckupElement.vue";


import PageArchive from "./pages/archive/PageArchive.vue";
import PageArchiveCheckup from "./pages/archive/PageArchiveCheckup.vue";
import PageArchiveModule from "./pages/archive/PageArchiveModule.vue";
import PageArchiveElement from "./pages/archive/PageArchiveElement.vue";

import PageFAQ from "./pages/faq/Main.vue";
import PageMap from "./pages/map/Main.vue";

import PageUserChat from "./pages/support/UserChat.vue";
import PageRootDialogs from "./pages/support/RootDialogs.vue";
import PageRootChat from "./pages/support/RootChat.vue";


const routes = [
  
  // Страница 404
  {
    path: '/:catchAll(.*)',
    component: PageNotFound,
    meta: {
      title: '404',
      name: 'Ошибка',
      layout: 'main-layout',
      requiresAuth: false,
    }
  },

  // Страница авторизации
  {
    path: '/login',
    component: { render: () => h(RouterView) },
    meta: {
      title: 'Логин',
      layout: 'empty-layout',
      requiresAuth: false,
    },
    children: [
      { 
        path: '',
        name: 'PageLogin',
        component: PageLogin,
      },
    ]
  },

  // Страница профиля
  {
    path: '/profile',
    component: { render: () => h(RouterView) },
    meta: {
      title: 'Профиль',
      name: 'Профиль',
      ico: 'IconPageProfile',
      layout: 'main-layout',
      requiresAuth: true,
    },
    children: [
      { 
        path: '',
        name: 'PageProfile',
        component: PageProfile,
      },
    ]
  },

  // Страница FAQ
  {
    path: '/faq',
    component: { render: () => h(RouterView) },
    meta: {
      title: 'Вопросы ответы',
      name: 'Вопросы ответы',
      ico: 'IconPageFAQ',
      layout: 'main-layout',
      requiresAuth: true,
    },
    children: [
      { 
        path: '',
        name: 'PageFAQ',
        component: PageFAQ,
      },
    ]
  },


  // Страница технической поддержки
  {
    path: '/support',
    component: { render: () => h(RouterView) },
    meta: {
      title: 'Техническая поддержка',
      name: 'Техническая поддержка',
      ico: 'IconPageSupport',
      layout: 'main-layout',
      requiresAuth: true,
    },
    children: [
      { 
        path: '',
        name: 'PageUserChat',
        component: PageUserChat,
      },
      { 
        path: 'chats',
        name: 'PageRootDialogs',
        component: PageRootDialogs,
      },
      { 
        path: 'chats/:user_id',
        name: 'PageRootChat',
        component: PageRootChat,
      },
    ]
  },


  // Страница c картой
  {
    path: '/map',
    component: { render: () => h(RouterView) },
    meta: {
      title: 'Карта',
      name: 'Карта',
      ico: 'IconPageMap',
      layout: 'main-layout',
      requiresAuth: true,
    },
    children: [
      { 
        path: '',
        name: 'PageMap',
        component: PageMap,
      },
    ]
  },



  // Главная страница
  {
    path: '/',
    component: { render: () => h(RouterView) },
    meta: {
      title: 'Главная',
      name: 'Главная',
      ico: 'IconPageMain',
      layout: 'main-layout',
      requiresAuth: true,
    },
    children: [
      { 
        path: '',
        name: 'PageHome',
        component: PageHome,
      },
    ],
  },

  // Организации
  {
    path: '/organizations',
    component: { render: () => h(RouterView) },
    meta: {
      title: 'Организации',
      name: 'Организации',
      ico: 'IconPageMain',
      layout: 'main-layout',
      requiresAuth: true,
    },
    children: [
      {
        path: 'create',
        name: 'PageOrganizationCreate',
        component: PageOrganizationCreate,
      },
      {
        path: ':organization_id',
        name: 'PageOrganization',
        component: PageOrganization,
      },


      // Дубль редактирования пользователя через организацию
      { 
        path: ':organization_id/users/:user_id',
        name: 'PageOrganizationUserProfile',
        component: PageUserProfile,
      },
      // Дубль создания пользователя через организацию
      { 
        path: ':organization_id/users/create',
        name: 'PageOrganizationUserCreate',
        component: PageUserCreate,
      },


      // Дубль редактирования договора через организацию
      { 
        path: ':organization_id/contracts/:contract_id/edit',
        name: 'PageOrganizationContractsEdit',
        component: PageContractsEdit,
      },
      // Дубль создания договора через организацию
      { 
        path: ':organization_id/contracts/create',
        name: 'PageOrganizationContractsCreate',
        component: PageContractsCreate,
      },



    ],
  },


  // Пользователи
  {
    path: '/users',
    component: { render: () => h(RouterView) },
    meta: {
      title: 'Пользователи',
      name: 'Пользователи',
      ico: 'IconPageUsers',
      layout: 'main-layout',
      requiresAuth: true,
    },
    children: [
      { 
        path: '',
        name: 'PageUsers',
        component: PageUsers,
      },
      { 
        path: ':user_id',
        name: 'PageUserProfile',
        component: PageUserProfile,
      },
      { 
        path: 'create',
        name: 'PageUserCreate',
        component: PageUserCreate,
      },
    ],
  },

  // Договоры
  {
    path: '/contracts',
    component: { render: () => h(RouterView) },
    meta: {
      title: 'Договоры',
      name: 'Договоры',
      ico: 'IconPageContracts',
      layout: 'main-layout',
      requiresAuth: true,
    },
    children: [
      { 
        path: '',
        name: 'PageContracts',
        component: PageContracts,
      },
      { 
        path: 'create',
        name: 'PageContractsCreate',
        component: PageContractsCreate,
      },
      { 
        path: ':contract_id/edit',
        name: 'PageContractsEdit',
        component: PageContractsEdit,
      },
    ],
  },


  // Объекты
  {
    path: '/objects',
    component: { render: () => h(RouterView) },
    meta: {
      title: 'Объекты',
      name: 'Объекты',
      ico: 'IconPageObjects',
      layout: 'main-layout',
      requiresAuth: true,
    },
    children: [
      { 
        path: '',
        name: 'PageObjects',
        component: PageObjects,
      },
      { 
        path: ':mkd_id/checkup-list',
        name: 'PageObjectsCheckupList',
        component: PageObjectsCheckupList,
      },
      { 
        path: 'create',
        name: 'PageObjectsCreate',
        component: PageObjectsCreate,
      },
      {
        path: ':mkd_id',
        name: "PageObjectsView",
        component: PageObjectsView,
      },
      {
        path: ':mkd_id/checkup/create',
        name: "PageCheckupCreate",
        component: PageCheckupCreate,
      },
      {
        path: ':mkd_id/checkup/:checkup_id/customer',
        name: "PageCheckupCustomer",
        component: PageCheckupCustomer,
      },
      {
        path: ':mkd_id/checkup/:checkup_id/modules',
        name: "PageCheckupModules",
        component: PageCheckupModules,
      },
      {
        path: ':mkd_id/checkup/:checkup_id/elements/:module_id',
        name: "PageCheckupElements",
        component: PageCheckupElements,
      },
      {
        path: ':mkd_id/checkup/:checkup_id/elements/:module_id/element/:element_id',
        name: "PageCheckupElement",
        component: PageCheckupElement,
      },
    ],
  },


  {
    path: "/archive",
    component: { render: () => h(RouterView) },
    meta: {
      title: 'Архив',
      name: 'Архив',
      ico: 'IconPageArchive',
      layout: 'main-layout',
      requiresAuth: true,
    },
    children: [
      { 
        path: '',
        name: "PageArchive",
        component: PageArchive,
      },
      { 
        path: ':mkd_id/checkup/:checkup_id',
        name: "PageArchiveCheckup",
        component: PageArchiveCheckup,
      },
      { 
        path: ':mkd_id/checkup/:checkup_id/module/:module_id',
        name: "PageArchiveModule",
        component: PageArchiveModule,
      },
      { 
        path: ':mkd_id/checkup/:checkup_id/module/:module_id/element/:element_id',
        name: "PageArchiveElement",
        component: PageArchiveElement,
      },
    ]
  },
];



const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
});

router.beforeEach((to, from, next) => {
  // Устанавливаем title страницы
  document.title = 'Верньер - ' + to.meta.title;
  // Если страница закрыта авторизацией
  if(to.matched.some(record => record.meta.requiresAuth)) {
    // Убеждаемся что авторизация есть
    if (store.getters.isLoggedIn) {
      // Пропускаем
      next();
      return;
    }
    // Редиректим на авторизацию
    next('/login');
  } else {
    // Страница без авторизации, пропускаем
    next();
  }
});

export default router;