<template>
	<TopMenu :headerText="null" />
	<Breadcrumbs :breadcrumbs="breadcrumbs" />
	<div class="load-container" :class="{'load' : isLoad}">
  	<div class="content" v-if="!isLoad && !isError">
  	
  		<div class="block block--padding mb-1">
	    	<div class="block__header">
	    		{{ path.name }}
	    	</div>
	    	<div class="block__body">
	    		<div class="field" v-if="schemes.length > 0">
		    		<div class="row">
		    			<div class="col-xs-12 col-sm-6 col-md-6">
		    				<div class="label">Схемы</div>
		    			</div>
		    			<div class="col-xs-12 col-sm-6 col-md-6">
		    				<GalleryPicker v-model="schemes" size="xs" viewMode="true" />
		    			</div>
		    		</div>
	    		</div>
	    	</div>
	    </div>

	    <div class="collapse" v-for="(region, index) in data.regions" :key="index">
	        <div class="collapse__header js-collapse">
	          	<div class="collapse__title">
	            	Единичный фрагмент {{ index + 1 }} 
	          	</div>
	        </div>
      		<div class="collapse__content">
      			<div class="field">
	    			<div class="row">
		    			<div class="col-xs-12 col-sm-6 col-md-6">
		    				<div class="label">Индекс единичного фрагмента</div>
		    			</div>
		    			<div class="col-xs-12 col-sm-6 col-md-6">
		    				<div class="c-value">
		    					{{ region.index_region }}
		    				</div>
		    			</div>
		    		</div>
		    	</div>
      		<div class="field" v-if="region.comment">
		    		<div class="row">
		    			<div class="col-xs-12 col-sm-6 col-md-6">
		    				<div class="label">Примечание</div>
		    			</div>
		    			<div class="col-xs-12 col-sm-6 col-md-6">
		    				<div class="c-value">
		    					{{ region.comment }}
		    				</div>
		    			</div>
		    		</div>
		    	</div>
		    	<div class="field" v-if="region.images.length > 0">
		    		<div class="row">
		    			<div class="col-xs-12 col-sm-6 col-md-6">
		    				<div class="label">Фото</div>
		    			</div>
		    			<div class="col-xs-12 col-sm-6 col-md-6">
		    				<div class="c-value">
		    					<GalleryPicker v-model="region.images" size="xs" viewMode="true" />
		    				</div>
		    			</div>
		    		</div>
		    	</div>
      		<div class="field">
		    		<div class="row">
		    			<div class="col-xs-12 col-sm-12 col-md-12">
		    				<div class="label">Выявленные повреждения:</div>
		    			</div>
		    			<div class="col-xs-12 col-sm-12 col-md-12">
		    				<ul class="list-grey">
		    					<template v-for="(defect) in region.defects" :key="defect.id">
		    						<li v-if="defect.checked">
		    							{{ defect.name }}
		    						</li>
		    					</template>
		    				</ul>
		    			</div>
		    		</div>
	    		</div>
      		</div>
	    </div>
	    <div class="block">
	      	<div class="block__footer">
	      		<router-link 
	      			:to="{name: 'PageArchiveModule', params: {mkd_id: mkd_id, checkup_id: checkup_id, module_id: path.module.id}}" 
	      			class="btn btn--outline btn--md"
	      		>
	          		<img class="btn--ico btn--ico--left" src="@/assets/img/6.svg">
	          		<div class="btn--text">Вернуться</div>
	      		</router-link>
	      	</div>
	    </div>
  	</div>
  	<ErrorContent  v-else/>
  </div>
</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue'
  import Breadcrumbs from '@/components/Breadcrumbs.vue'
  import GalleryPicker from '@/components/ux/GalleryPicker.vue';
  import ErrorContent from '@/components/ErrorContent.vue'
</script>


<script>
  export default {
    name: 'PageArchiveElement',
    data: () => ({
    	isLoad: true,
    	isError: false,
    
    	breadcrumbs: [],

      mkd: null,
    	path: null,
    	data: null,
    }),
    created () {
      Promise.all([this.loadMkd(), this.loadPath(), this.loadData()])
      .then(() => {
        this.beforeRender();
        this.isLoad = false;
      })
      .catch((e)=> {
        console.log(e);
        this.isError = true;
        this.isLoad = false;
      })
    },
    computed: {
    	mkd_id () {
    	  return this.$route.params.mkd_id;
    	},
    	checkup_id () {
    	  return this.$route.params.checkup_id;
    	},
    	module_id () {
    	  return this.$route.params.module_id;
    	},
    	element_id () {
    	  return this.$route.params.element_id;
    	},
    	schemes () {
    		const schemes = [];
				this.data.images.forEach((img) => {
      		schemes.push(img.path); 
      	});
      	return schemes;
  		},
    },
    methods: {
    	beforeRender() {
        this.breadcrumbs = [
          {name: 'Объекты', link: '/archive'},
          {name: this.$helpers.mkd_address(this.mkd), link: {name: 'PageArchiveCheckup', params: {mkd_id: this.mkd_id, checkup_id: this.checkup_id}}},
          {name: this.path.module.name, link: {name: 'PageArchiveModule', params: {mkd_id: this.mkd_id, checkup_id: this.checkup_id, module_id: this.path.module.id}}},
          {name: this.path.name, link: ''},
        ];
    	},
    	async loadMkd () {
        this.mkd = await this.$store.dispatch('getMkd', {id_mkd: this.mkd_id});
      },
      async loadPath () {
        this.path = await this.$store.dispatch('getPath', {id_element: this.element_id});
    	},
    	async loadData () {
        this.data = await this.$store.dispatch('getRegionsByElement', {id_element: this.element_id, id_checkup: this.checkup_id, id_module: this.module_id });
    	},
    },
  }
</script>

<style scoped>
  	.block--padding .block__body {
    	padding: 20px;
  	}
	.field:not(:last-child) {
    	margin-bottom: 15px;
  	}

	.collapse {
		background-color: #fff;
		border: none;
		margin-bottom: 10px;
	}
	.collapse__content {
    	border-top: 1px solid var(--cozh-light-grey);
		padding: 20px;
	}
  	.c-value {
    	font-size: 13px;
    	font-weight: 600;
  	}
  	.list-grey {
  		margin-top: 15px;
  	}

  	.block__footer {
    	border: none;
    	justify-content: space-between;
	}

  	@media (max-width: 767px) {
		.label {
    		margin-bottom: 8px;
		}
		.list-grey {
  			margin-top: 0px;
  		}
	}
</style>