<template>

  <TopMenu :headerText="headerText" />

  <MessageDialogue ref="messageDialogue" />
  <ConfirmDialogue ref="confirmDialogue" />

  <OrganizationCard :data="organization" v-if="organization != null" :isEdit="true" />

  <div class="block mt-1">
    <div class="block__header block__header--btn">
      Обследования
    </div>
    <div class="block__body">
      <grid-table :options="optionsCheckups">
        <template v-slot:default="{ item, reload }">
          <tr>
            <td width="1px">{{item.id}}</td>
            <td>
              <div class="clock">
                <div class="clock__item">
                  <router-link :to="{name: 'PageCheckupCustomer', params: { mkd_id: item.id_mkd, checkup_id: item.id }}" class="link-default">
                    <img class="btn--ico--left" src="@/assets/img/min-calendar-grey.svg">
                    <span> {{ $moment(item.create_at).format('DD.MM.YYYY') }} </span>
                  </router-link>
                </div>
                <div class="clock__item">
                  <img class="btn--ico--left" src="@/assets/img/min-clock-grey.svg">
                  {{ $moment(item.create_at).format('HH:mm:ss') }}
                </div>
              </div>
            </td>
            <td class="">
              <router-link class="link-default" :to="{name: 'PageObjectsView', params: {mkd_id: item.id_mkd}}"> 
                <span> {{ item.address }} </span>
              </router-link>
            </td>
            <td class="nowrap">
              {{ item.number }}
            </td>
            <td style="min-width: 350px;">
              {{ item.customer }} (ИНН: {{ item.customer_inn }})
            </td>
            <td>
              <span v-if="item.id_checkup_status == 1"> В работе </span>
              <span v-if="item.id_checkup_status == 2"> Завершен </span>
            </td>
            <td style="width: 1px;">
              <a class="link-default ms-3 fs-12" @click="deleteCheckup($event, item.id, reload)">
                <img class="btn--ico btn--ico--left" src="@/assets/img/cross-blue.svg">
                <span class="btn--text"> Удалить </span>
                <div class="btn--loader"></div>
              </a>
            </td>
          </tr>
        </template>
      </grid-table>
    </div>
  </div>

  <!-- Договоры -->
  <div class="block mt-1">
    <div class="block__header block__header--btn">
      Договоры
      <router-link :to="{name: 'PageOrganizationContractsCreate', params: {organization_id: organization_id}}" class="btn btn--grey btn--sm">
        <img class="btn--ico btn--ico--left" src="@/assets/img/plus-blue-small.svg">
        <div class="btn--text"> Добавить  <span class="hidden-xs"> договор </span> </div>
      </router-link>
    </div>
    <div class="block__body">
      <grid-table :options="optionsContracts">
        <template v-slot:default="{ item, reload }">
          <tr>
            <td width="1px">{{item.id}}</td>
            <td>
              <span class="nowrap"> {{$moment(item.date).format("DD MMMM YYYY")}} </span>
            </td>
            <td>
              <span class="nowrap"> {{item.number}} </span>
            </td>
            <td>
              {{item.number_licenses}}
            </td>
            <td>
              {{item.available_count}}
            </td>
            <td>
              <a class="link-default" :href="item.file" target="blank"> 
                <span> {{ 'Договор №' + item.number + ' от ' + $moment(item.date).format("DD.MM.YYYY") }} </span>
              </a>
            </td>
            <td style="width: 1px;">
              <div class="c-filed-row">
                <router-link class="link-default fs-12" :to="{name: 'PageOrganizationContractsEdit', params: {contract_id: item.id, organization_id: organization_id}}">
                  <img class="btn--ico btn--ico--left" src="@/assets/img/edit-blue.svg" style="margin-bottom: 2px;">
                  <span class="btn--text"> Изменить </span>
                  <div class="btn--loader"></div>
                </router-link>
                <a class="link-default ms-3 fs-12" @click="deleteContract($event, item.id, reload)">
                  <img class="btn--ico btn--ico--left" src="@/assets/img/cross-blue.svg">
                  <span class="btn--text"> Удалить </span>
                  <div class="btn--loader"></div>
                </a>
              </div>
            </td>
          </tr>
        </template>
      </grid-table>
    </div>
  </div>

  <!-- Пользователи -->
  <div class="block mt-1">
    <div class="block__header block__header--btn">
      Пользователи
      <router-link :to="{name: 'PageOrganizationUserCreate', params: {organization_id: organization_id}}" class="btn btn--grey btn--sm">
        <img class="btn--ico btn--ico--left" src="@/assets/img/plus-blue-small.svg">
        <div class="btn--text"> Добавить  <span class="hidden-xs"> пользователя </span> </div>
      </router-link>
    </div>
    <div class="block__body">
      <grid-table :options="optionsUsers">
        <template v-slot="{ item, reload }">
          <tr>
            <td width="1px">{{item.id}}</td>
            <td style="padding: 8px 20px;">
              <div class="c-filed-row">
                <div class="avatar" :style="{ backgroundImage: item.avatar ? 'url(' + item.avatar + ')' : ''}">
                  <img class="avatar__badge" src="@/assets/img/star.svg" v-if="item.role_name == 'Администратор'">
                </div>
                <span class="nowrap ms-1"> {{item.fullname}} </span>
              </div>
            </td>
            <td>
              {{item.role_name}}
            </td>
            <td style="width: 1px;">
              <div class="c-filed-row">
                <router-link class="link-default fs-12" :to="{name: 'PageOrganizationUserProfile', params: {user_id: item.id, organization_id: organization_id}}">
                  <img class="btn--ico btn--ico--left" src="@/assets/img/edit-blue.svg" style="margin-bottom: 2px;">
                  <span class="btn--text"> Изменить </span>
                  <div class="btn--loader"></div>
                </router-link>
                <a class="link-default ms-3 fs-12" @click="deleteUser($event, item.id, reload)">
                  <img class="btn--ico btn--ico--left" src="@/assets/img/cross-blue.svg">
                  <span class="btn--text"> Удалить </span>
                  <div class="btn--loader"></div>
                </a>
              </div>
            </td>
          </tr>
        </template>
      </grid-table>
    </div>
  </div>


</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue';
  import GridTable from '@/components/ux/GridTable.vue';
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
  import OrganizationCard from '@/components/organizations/Card.vue';
</script>

<script>
  export default {
    name: 'PageOrganization',
    data: () => ({
      
      organization: null,

      headerText: null,

      optionsUsers: {
        method: 'getUsers',
        display: 5,
        fields: [
          {key:'id', name:'ID'},
          {key:'fullname', name:'ФИО'},
          {key:'id_role', name:'Роль'},
          {key: false, name:''},
        ],
        filters: [
          {key: 'fullname', type: 'text'},
        ],
        defaultFilter: [],
      },

      optionsContracts: {
        method: 'getContracts',
        display: 5,
        fields: [
          {key:'id', name:'ID'},
          {key:'date', name:'Дата'},
          {key:'number', name:'№ договора'},
          {key:'number_licenses', name:'Всего <br /> лицензий'},
          {key:'available_count', name:'Доступно <br /> обследований'},
          {key:'file', name:'Подписанный документ'},
          {key: false, name:''},
        ],
        filters: [
          {key: 'number', type: 'text'},
        ],
        defaultFilter: [],
      },

      optionsCheckups: {
        method: 'getCheckups',
        display: 5,
        fields: [
          {key:'id', name:'ID'},
          {key:'create_at', name:'Дата / время'},
          {key:'address', name:'Объект обследования'},
          {key:'number', name:'№ договора'},
          {key:'customer', name: 'Заказчик'},
          {key:'id_checkup_status', name: 'Статус'},
          {key: false, name:''},
        ],
        filters: [
          {key: 'number', type: 'text'},
          {key: 'id_checkup_status', type: 'select', list: [{id: 1, name: 'В работе'}, {id: 2, name: 'Завершен'}]},
        ],
        defaultFilter: [],
      },

      roles: [
        {id: 1, name: "Администратор"},
        {id: 2, name: "Обследователь"},
      ],
    }),
    computed: {
      id_role () {
        return this.$store.state.auth.user.profile.id_role;
      },
      organization_id () {
        return this.$route.params.organization_id;
      },
    },
    created () {
      
      this.optionsUsers.defaultFilter.push({key: 'id_organization', value: this.organization_id});
      this.optionsContracts.defaultFilter.push({key: 'id_organization', value: this.organization_id});
      this.optionsCheckups.defaultFilter.push({key: 'id_organization', value: this.organization_id});

      this.optionsUsers.filters.push({ key: 'id_role', type: 'select', list: this.roles });

      this.loadOrganization();
    },
    methods: {
      async loadOrganization () {
        const organization = await this.$store.dispatch('getOrganization', {id: this.organization_id});
        this.organization = organization;
      },
      async deleteUser (e, id, reload) {
        const btn = e.currentTarget;
        btn.classList.add('btn--load');
        const confirm = await this.$refs.confirmDialogue.show({
          message: 'Вы уверены, что хотите удалить этого пользователя?',
          okButton: 'Удалить',
        });
        if(confirm) {
          try {
              await this.$store.dispatch('deleteUser', {id_user: id});
          reload();
          } catch (e) {
            this.$refs.messageDialogue.show({message: this.$helpers.getErrorMessage(e), okButton: 'Подтвердить'});
          }
        }
        btn.classList.remove('btn--load');
      },

      async deleteContract (e, id, reload) {
        const btn = e.currentTarget;
        btn.classList.add('btn--load');
        const confirm = await this.$refs.confirmDialogue.show({
          message: 'Вы уверены, что хотите удалить этот договор?',
          okButton: 'Удалить',
        });
        if(confirm) {
          try {
            await this.$store.dispatch('deleteContract', {id: id});
            reload();
          } catch (e) {
            this.$refs.messageDialogue.show({message: this.$helpers.getErrorMessage(e), okButton: 'Подтвердить'});
          }
        }
        btn.classList.remove('btn--load');
      },

      async deleteCheckup (e, id, reload) {
        const btn = e.currentTarget;
        btn.classList.add('btn--load');
        const confirm = await this.$refs.confirmDialogue.show({
          message: 'Вы уверены, что хотите удалить это обследование?',
          okButton: 'Удалить',
        });
        if(confirm) {
          try {
            await this.$store.dispatch('deleteCheckup', {id_checkup: id});
            reload();
          } catch (e) {
            this.$refs.messageDialogue.show({message: this.$helpers.getErrorMessage(e), okButton: 'Подтвердить'});
          }
        }
        btn.classList.remove('btn--load');
      },


    },
  }
</script>

<style scoped>
  .block__header--btn {
    padding: 10px 20px;
  }
  .c-filed-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
</style>
