<template>

  	<TopMenu :headerText="null" />
  	<Breadcrumbs :breadcrumbs="breadcrumbs" />
  	<CheckupNavigator />
  	<ConfirmDialogue ref="confirmDialogue" />
  	<MessageDialogue ref="messageDialogue" />

  	<div class="block">
  		<div class="block__header">
  			Информация о заказчике
  		</div>
  		<div class="block__body">
  			<div class="load-container" :class="{'load' : isLoad}">
			    <div class="content" v-if="!isLoad && !isError">

			    	<div class="field">
		    			<div class="row">
			    			<div class="col-xs-12 col-sm-6 col-md-6">
			    				<div class="label">Договор</div>
			    			</div>
			    			<div class="col-xs-12 col-sm-6 col-md-6">
			    				<div class="form-group">
				            <!-- Для админа и обследователя -->
                    <div class="file" v-if="id_role == 1 || id_role == 2">
				              <img class="file__ico" src="@/assets/img/file-blue.svg">
				              <div class="file__info">
				                <div class="file__name">
				                  {{ 'Договор №' + info.contract_number + ' от ' + $moment(info.contract_date).format('DD MMMM YYYY') }}
				                </div>
				                <div class="file__bottom">
				                  <a class="file__link" target="blank" :href="info.contract_link"> <span> Скачать </span> </a>
				                </div>
				              </div>
				            </div>
				            <!-- Для рута -->
                    <SelectPicker :label="'Выберите договор'" :list="contracts" v-model="newinfo.id_contract" v-if="id_role == 0" />
                  </div>
			    			</div>
			    		</div>
		    		</div>

		    		<div class="field">
		    			<div class="row">
			    			<div class="col-xs-12 col-sm-6 col-md-6">
			    				<div class="label">Наименование</div>
			    			</div>
			    			<div class="col-xs-12 col-sm-6 col-md-6">
				          <!-- Для админа и обследователя -->
			    				<div class="label" v-if="id_role == 1 || id_role == 2">{{ info.customer }}</div>
				          <!-- Для рута -->
		            	<DadataPicker label="Начните ввод" type="party" @setValue="setCustomer" :value="newinfo.customer" v-if="id_role == 0"/>
			    			</div>
			    		</div>
		    		</div>
			    	
		    		<div class="field mt-2">
		    			<div class="row">
			    			<div class="col-xs-12 col-sm-6 col-md-6">
			    				<div class="label">ИНН</div>
			    			</div>
			    			<div class="col-xs-12 col-sm-6 col-md-6">
				          <!-- Для админа и обследователя -->
			    				<div class="label" v-if="id_role == 1 || id_role == 2">{{ info.customer_inn }}</div>
				          <!-- Для рута -->
  								<div class="label" v-if="id_role == 0"> {{ newinfo.customer_inn == null ? '-' : newinfo.customer_inn }} </div>
			    			</div>
			    		</div>
		    		</div>

			    </div>
			    <ErrorContent v-else/>
		  	</div>
  		</div>
    	<div class="block__footer">
        <a class="btn btn--outline btn--md me-1" @click="$router.go(-1)">
          <img class="btn--ico btn--ico--left" src="@/assets/img/6.svg">
          <div class="btn--text">Вернуться</div>
        </a>

        <div class="btn-group">
        	<a class="btn btn--default btn--md me-1" @click="saveInfo($event)" v-if="id_role == 0" :class="{'disabled': isDisabledBtn}">
	          <div class="btn--text">Сохранить</div>
	          <div class="btn--loader"></div>
	        </a>
	        <router-link 
	        	class="btn btn--md" :class="{'btn--default': id_role !== 0, 'btn--outline': id_role == 0}" 
	        	:to="{name: 'PageCheckupModules', params: {mkd_id: mkd_id, checkup_id: checkup_id}}">
	          <div class="btn--text">Далее</div>
          	<img class="btn--ico btn--ico--right" src="@/assets/img/6.svg" style="transform: rotate(180deg);" v-if="id_role == 0">
          	<img class="btn--ico btn--ico--right" src="@/assets/img/next-white.svg" v-if="id_role !== 0">
	        </router-link>
        </div>
      </div>
  	</div>
</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue';
  import CheckupNavigator from '@/components/checkups/CheckupNavigator.vue';
  import ErrorContent from '@/components/ErrorContent.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import Breadcrumbs from '@/components/Breadcrumbs.vue'
  import SelectPicker from '@/components/ux/SelectPicker.vue'
  import DadataPicker from '@/components/ux/DadataPicker.vue'
</script>

<script>
	export default {
	    name: 'PageCheckupCustomer',

	    data: () => ({
	      isLoad: true,
	      isError: false,
	      breadcrumbs: [],

	      info: null,

	      newinfo: {
	      	id: null,
			  	id_contract: null,
			  	customer: null,
			  	customer_inn: null,
	      },

	      contracts: [],

	    }),
	    computed: {
	    	
	    	mkd_id () {
        	return this.$route.params.mkd_id;
      	},

      	checkup_id () {
        	return this.$route.params.checkup_id;
      	},

      	id_role () {
      		return this.$store.state.auth.user.profile.id_role;
      	},

      	isDisabledBtn () {
      		if(this.newinfo.id == null || this.newinfo.id_contract == null || this.newinfo.customer == null || this.newinfo.customer_inn == null) {
      			return true;
      		}
      		return false;
      	},


	    },
	    created () {

	      Promise.all([this.loadInfo()])
	      	.then(() => {
	        	this.beforeRender();
	        	this.isLoad = false;
	      	})
	      	.catch((e)=> {
	        	console.log(e);
	        	this.isError = true;
	        	this.isLoad = false;
	      	})
	    },
	    methods: {
      	
      	beforeRender() {

          this.breadcrumbs = [
	          {name: this.info.address, link: {name: 'PageObjectsView', params: {mkd_id: this.mkd_id}}},
	          {name: 'Обследование №' + this.checkup_id + ' от ' + this.$moment(this.info.contract_date).format('DD.MM.YYYY'), link: ''},
	        ];

	        if(this.id_role == 2) {
	          // Для обследователя - на главную
	          this.breadcrumbs.unshift({name: 'Главная', link: {name: 'PageHome'}});
	        } else {
	          // Для всех остальных на объекты
	          this.breadcrumbs.unshift({name: 'Объекты', link: {name: 'PageObjects'}});
	        }
	        

	        this.newinfo.id = this.checkup_id;
					this.newinfo.id_contract = this.info.contract_id;
					this.newinfo.customer = this.info.customer;
					this.newinfo.customer_inn = this.info.customer_inn;

	        // Для рута дополнительно тянем всех сотрудников этой организации
	        if(this.id_role == 0) {
	    			this.getContractsActive();
	        }
      	
      	},

      	setCustomer (item) {
					if(item == null) {
						this.newinfo.customer = null;
						this.newinfo.customer_inn = null;
					} else {
						this.newinfo.customer = item.value;
						this.newinfo.customer_inn = item.data.inn;
					}
				},

      	async saveInfo (e) {
      		
      		const btn = e.currentTarget;
      		
      		btn.classList.add('btn--load');

      		await new Promise(r => setTimeout(r, 300));

      		try {
      			// Сохраняем данные
      			await this.$store.dispatch('updateInfoByCheckup', this.newinfo);
          	this.$refs.messageDialogue.show({ message: 'Данные сохранены', okButton: 'Подтвердить' });
      		} catch (e) {
          	this.$refs.messageDialogue.show({message: e.message, okButton: 'Подтвердить'});
      		}

      		btn.classList.remove('btn--load');

      	},

      	async loadInfo () {
      		this.info = await this.$store.dispatch('getInfoByCheckup', {id_checkup: this.checkup_id});
      	},
      	async getContractsActive () {
      		this.contracts = [];
      		const param = this.id_role == 0 ? '?id_organization=' + this.info.organization_id : '';
      		const items = await this.$store.dispatch('getContractsActive', param);
      		items.forEach((item) => {
      			this.contracts.push({id: item.id, name: 'Договор №' + item.number + ' от ' + this.$moment(item.date).format('DD MMMM YYYY')});
      		})
      	},
	    }
	}
</script>

<style scoped>
	.block {
    border-radius: 0px 0px 8px 8px;
	}
	.block__body {
		padding: 20px 0px;
	}

	/*.label {*/
	  /*margin-top: 11px;*/
	/*}*/

	.field {
		padding: 0px 20px;
	}
	.field:not(:last-child) {
		margin-bottom: 10px;
	}

  @media (max-width: 767px) {
		.label {
    	margin-top: 0px;
    	margin-bottom: 8px;
		}
		.block__footer {
			flex-direction: column-reverse;
		}
		.block__footer a.me-1 {
			margin: 0px;
		}
		.block__footer a {
			width: 100%;
		}
		.btn-group {
			margin-bottom: 10px;
			width: 100%;
		}
		.btn-group a:first-child:not(:last-child) {
			margin-right: 10px;
		}
	}

</style>
