<template>

  <TopMenu :headerText="null" />
	<MessageDialogue ref="messageDialogue" />
	<ConfirmDialogue ref="confirmDialogue" />

	<div class="collapse" v-for="(item) in questions" :key="item.id" :ref="'answer_' + item.id">
		<div class="collapse__header js-collapse">
			<div class="collapse__title">
				{{item.question}}
			</div>
		</div>
		<div class="collapse__content">
			{{item.answer}}
		</div>
	</div>
</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue';
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
</script>

<script>
  export default {
    name: 'PageFAQ',
    data: () => ({
			questions: [],
    }),
    created () {
    	this.lodaQuestions();
    },
    methods: {
    	async checkHash () {
    		const hash = this.$route.hash.substring(1).match(/answer_(\d+)/);
    		// Есть какой-то хэш
	    	if (hash !== null) {
	    		// Есть на странице такой вопрос
		    	if(this.$refs[hash[0]]) {
		    		// HTML элемент
		    		const answer = this.$refs[hash[0]][0];
		    		// Раскрываем
		    		answer.classList.add('open');
		    		// Скролим к нему 
			    	answer.scrollIntoView({ behavior: 'smooth' });
		    	}
	    	}
    	},
    	async lodaQuestions () {
    		this.questions = await this.$store.dispatch('getQuestions');
    		this.$nextTick(() => {
    			this.checkHash();
      	});
    	},
    },
  }
</script>

<style scoped>
	.collapse {
	    background-color: #fff;
	    border: none;
	}
	.collapse:not(:last-child) {
	    margin-bottom: 10px;
	}
	.collapse__content {
		padding: 20px 0px;
		margin: 0px 20px;
		border-top: 1px solid var(--cozh-light-grey);
	}
	.collapse__header {
		font-size: 16px;
    	font-weight: 500;
    	padding: 20px 20px;
    	padding-right: 45px;
	}
  	@media (max-width: 767px) {
  	}
</style>