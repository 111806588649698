<template>

  <TopMenu :headerText="headerText" />
  <Breadcrumbs :breadcrumbs="breadcrumbs" />

  <div class="load-container" :class="{'load' : isLoad}">
    <div class="content" v-if="!isLoad && !isError">

      <div class="block block--padding">
        <div class="block__header">
          {{ data.module_name }}
        </div>
        <div class="block__body">
          <div class="field">
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-6">
                <div class="label">
                  Доля единичных фрагментов с повреждениями, %
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6">
                <div class="c-value">
                  {{ data.proportion_fragments_damage_module }}
                </div>
              </div>
            </div>
          </div>
          <div class="field" v-if="data.schemas.length > 0">
            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-6">
                <div class="label">Схемы</div>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6">
                <GalleryPicker v-model="data.schemas" size="xs" viewMode="true" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="block">
      	<div class="block__body">
  		    <div class="table-responsive">
  		        <table class="table-custom table-custom--central">
  		          	<thead>
  		          		<tr>
  		          			<td></td>
  		          			<th colspan="3"> Количество единичных фрагментов </th>
  		          			<td></td>
  		          			<td></td>
  		          		</tr>
  		                <tr>
  		                  <th class="nowrap" style="text-align: left;"> Наименование <br /> элемента </th>
  		                  <th class="nowrap"> Общее </th>
  		                  <th class="nowrap"> С дефектами категории «А» </th>
  		                  <th class="nowrap"> С дефектами категории «Б» </th>
  		                  <th class="nowrap"> Доля единичных фрагментов <br /> с повреждениями от общего, % </th>
  		                  <th class="nowrap"> Удельный вес <br /> элемента, % </th>
  		                </tr>
  		          	</thead>
  		          	<tbody>
  		                <tr v-for="(element, index) in data.elements" :key="index" @click="goToNext(element.element_id)">
  		                  	<td style="text-align: left;"> {{ element.element_name }} </td>
  		                  	<td> {{ element.count }} </td>
  		                  	<td> {{ element.count_A }} </td>
  		                  	<td> {{ element.count_B }} </td>
  		                  	<td> {{ element.proportion_fragments_damage }} </td>
  		                  	<td> {{ element.specific_weight }} </td>
  		                </tr>
  		          	</tbody>
  		        </table>
  		    </div>
      	</div>
      </div>
    </div>
    <ErrorContent  v-else/>
    <div class="block">
      <div class="block__footer">
        <router-link :to="{name: 'PageArchiveCheckup', params: {mkd_id: mkd_id, checkup_id: checkup_id}}" class="btn btn--outline btn--md">
            <img class="btn--ico btn--ico--left" src="@/assets/img/6.svg">
            <div class="btn--text">Вернуться</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue'
  import Breadcrumbs from '@/components/Breadcrumbs.vue'
  import GalleryPicker from '@/components/ux/GalleryPicker.vue';
  import ErrorContent from '@/components/ErrorContent.vue'
</script>

<script>
  export default {
    name: 'PageArchiveModule',
    data: () => ({
      isLoad: true,
      isError: false,
      headerText: null,
      breadcrumbs: [],
      mkd: null,
      data: null,
    }),
    created () {
      Promise.all([this.loadMkd(), this.loadElementsByArchive()])
      .then(() => {
        this.beforeRender();
        this.isLoad = false;
      })
      .catch((e)=> {
        console.log(e);
        this.isError = true;
        this.isLoad = false;
      })
    },
    mounted () {

    },
    computed: {
      mkd_id () {
        return this.$route.params.mkd_id;
      },
      checkup_id () {
        return this.$route.params.checkup_id;
      },
      module_id () {
        return this.$route.params.module_id;
      },
    },
    methods: {
      beforeRender() {
        this.breadcrumbs = [
          {name: 'Объекты', link: '/archive'},
          {name: this.$helpers.mkd_address(this.mkd), link: {name: 'PageArchiveCheckup', params: {mkd_id: this.mkd_id, checkup_id: this.checkup_id}}},
          {name: this.data.module_name}
        ];
        this.headerText = this.mkd_address;
      },
      async loadMkd () {
        this.mkd = await this.$store.dispatch('getMkd', {id_mkd: this.mkd_id});
      },
      async loadElementsByArchive () {
        this.data = await this.$store.dispatch('getElementsByArchive', {id_checkup: this.checkup_id, id_module: this.module_id});
      },
      goToNext (id) {
        this.$router.push({name: 'PageArchiveElement', params: {mkd_id: this.mkd_id, checkup_id: this.checkup_id, module_id: this.module_id, element_id: id}});
      },
    },
  }
</script>

<style scoped>
	.block {
	  	margin-top: 10px;
	}
  .block__footer {
    border: none;
    justify-content: space-between;
	}

  .block--padding .block__body {
    padding: 20px;
  }

  .field:not(:last-child) {
    margin-bottom: 15px;
  }

  .c-value {
    font-size: 13px;
    font-weight: 600;
  }

  .table-custom tbody tr {
    cursor: pointer;
  }

  @media (max-width: 767px) {
    .label {
      margin-top: 0px;
      margin-bottom: 8px;
    }
    .btn-group {
      width: 100%;
      margin-top: 10px;
    }
    .block__footer a {
      width: 100%;
    }
  }

</style>