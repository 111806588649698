<template>

  <TopMenu :headerText="null" />
  <MessageDialogue ref="messageDialogue" />
  <ConfirmDialogue ref="confirmDialogue" />

  <!-- Метрики -->
  <Metrics />

  <!-- Организации -->
  <div class="block mt-1" v-if="id_role == 0">
    <div class="block__header block__header--btn">
      Организации
      <router-link :to="{name: 'PageOrganizationCreate'}" class="btn btn--grey btn--sm">
        <img class="btn--ico btn--ico--left" src="@/assets/img/plus-blue-small.svg">
        <div class="btn--text"> Добавить  <span class="hidden-xs"> организацию </span> </div>
      </router-link>
    </div>
    <div class="block__body">
      <grid-table :options="optionsOrganizations">
        <template v-slot:default="{ item }">
          <tr>
            <td width="1px">{{item.id}}</td>
            <td>
              <router-link class="link-default" :to="{name: 'PageOrganization', params: {organization_id: item.id}}">
                <span> {{item.name}} </span>
              </router-link>
            </td>
            <td>
              <router-link 
                class="link-default" 
                :to="{name: 'PageUserProfile', params: {user_id: item.id_director}}"
                v-if="item.id_director !== null">
                <span class="btn--text"> {{item.director}} </span>
                <div class="btn--loader"></div>
              </router-link>
              <span v-else>-</span>
            </td>
            <td>{{item.count_employee}}</td>
            <td>{{item.count_license}}</td>
            <td>{{item.count_checkups}}</td>
          </tr>
        </template>
      </grid-table>
    </div>
  </div>


  <!-- Объекты -->
  <div class="block mt-1" v-if="id_role == 1 || id_role == 2">
    <div class="block__header block__header--btn">
      Объекты
      <router-link class="btn btn--grey btn--sm" :to="{name: 'PageObjectsCreate'}">
        <img class="btn--ico btn--ico--left" src="@/assets/img/plus-blue-small.svg">
        <div class="btn--text"> Добавить  <span class="hidden-xs"> объект </span> </div>
      </router-link>
    </div>
    <div class="block__body">
      <grid-table :options="optionsObjects">
        <template v-slot:default="{ item }">
          <tr>
            <td style="min-width: 350px;">
              {{item.address}}
            </td>
            <td>
              <!-- Редактировать -->
              <router-link class="link-default ms-3 fs-12" :to="{name: 'PageObjectsView', params: {mkd_id: item.id}}">
                <img class="btn--ico btn--ico--left" src="@/assets/img/edit-blue.svg" style="margin-bottom: 2px;">
                <span> Исходные данные </span>
              </router-link>
            </td>
            <td style="width: 1px;">
              <div class="c-filed-row">
                <!-- Новое обследование -->
                <router-link class="link-default ms-3 fs-12" :to="{name: 'PageCheckupCreate', params: {mkd_id: item.id}}">
                  <img class="btn--ico btn--ico--left" src="@/assets/img/plus-blue-small.svg">
                  <span class="btn--text"> Новое обследование </span>
                  <div class="btn--loader"></div>
                </router-link>
                <!-- Список обследований -->
                <router-link 
                  class="link-default ms-3 fs-12" 
                  :to="{name: 'PageObjectsCheckupList', params: {mkd_id: item.id}}"
                  v-if="(id_role == 1 || id_role == 2) && item.is_exist_checkups || id_role == 0"
                  >
                  <img class="btn--ico btn--ico--left" src="@/assets/img/copy-white.svg">
                  <span> Обследования </span>
                </router-link>
                <span v-else class="ms-3 empty-text">
                  Нет обследований
                </span>
              </div>
            </td>
          </tr>
        </template>
      </grid-table>
    </div>
  </div>
  

  <!-- Договоры -->
  <div class="block mt-1" v-if="id_role == 1">
    <div class="block__header block__header--btn">
      Договоры
    </div>
    <div class="block__body">
      <grid-table :options="optionsContracts">
        <template v-slot:default="{ item }">
          <tr>
            <td>
              <span class="nowrap"> {{$moment(item.date).format("DD MMMM YYYY")}} </span>
            </td>
            <td>
              <span class="nowrap"> {{item.number}} </span>
            </td>
            <td>
              {{item.number_licenses}}
            </td>
            <td>
              {{item.available_count}}
            </td>
            <td>
              <a class="link-default" :href="item.file" target="blank"> 
                <span> {{ 'Договор №' + item.number + ' от ' + $moment(item.date).format("DD.MM.YYYY") }} </span>
              </a>
            </td>
          </tr>
        </template>
      </grid-table>
    </div>
  </div>


  <!-- Пользователи -->
  <div class="block mt-1" v-if="id_role == 1">
    <div class="block__header block__header--btn">
      Пользователи
      <router-link :to="{name: 'PageUserCreate'}" class="btn btn--grey btn--sm">
        <img class="btn--ico btn--ico--left" src="@/assets/img/plus-blue-small.svg">
        <div class="btn--text"> Добавить  <span class="hidden-xs"> пользователя </span> </div>
      </router-link>
    </div>
    <div class="block__body">
      <grid-table :options="optionsUsers">
        <template v-slot:default="{ item, reload }">
          <tr>
            <td style="padding: 8px 20px;">
              <div class="c-filed-row">
                <div class="avatar" :style="{ backgroundImage: 'url(' + item.avatar + ')'}">
                  <img class="avatar__badge" src="@/assets/img/star.svg" v-if="item.role_name == 'Администратор'">
                </div>
                <span class="nowrap ms-1"> {{item.fullname}} </span>
              </div>
            </td>
            <td>
              {{item.role_name}}
            </td>
            <td style="width: 1px;">
              <div class="c-filed-row">
                <router-link class="link-default fs-12" :to="{name: 'PageUserProfile', params: {user_id: item.id}}">
                  <img class="btn--ico btn--ico--left" src="@/assets/img/edit-blue.svg" style="margin-bottom: 2px;">
                  <span class="btn--text"> Изменить </span>
                  <div class="btn--loader"></div>
                </router-link>
                <a class="link-default ms-3 fs-12" @click="deleteUser($event, item.id, reload)">
                  <img class="btn--ico btn--ico--left" src="@/assets/img/cross-blue.svg">
                  <span class="btn--text"> Удалить </span>
                  <div class="btn--loader"></div>
                </a>
              </div>
            </td>
          </tr>
        </template>
      </grid-table>
    </div>
  </div>

</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue';
  import GridTable from '@/components/ux/GridTable.vue';
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
  import Metrics from '@/components/home/Metrics.vue';
</script>

<script>
  export default {
    name: 'PageHome',
    data: () => ({
      optionsUsers: {
        method: 'getUsers',
        display: 5,
        fields: [
          {key:'fullname', name:'ФИО'},
          {key:'id_role', name:'Роль'},
          {key: false, name:''},
        ],
        filters: [
          {key: 'fullname', type: 'text'},
          {key: 'id_role', type: 'select', list: [{id: 1, name: "Администратор"}, {id: 2, name: "Обследователь"}] }
        ],
      },
      optionsContracts: {
        method: 'getContracts',
        display: 5,
        fields: [
          {key:'date', name:'Дата'},
          {key:'number', name:'№ договора'},
          {key:'number_licenses', name:'Общее количество лицензий'},
          {key:'available_count', name:'Доступно обследований'},
          {key:'file', name:'Подписанный документ'},
        ],
        filters: [
          {key: 'number', type: 'text'},
        ],
      },
      optionsObjects: {
        method: 'getObjects',
        display: 5,
        fields: [
          {key:'address', name:'Наименование'},
          {key: false, name:''},
          {key: false, name:''},
        ],
        filters: [
          {key: 'address', type: 'text'},
        ],
      },
      optionsOrganizations: {
        method: 'getOrganizations',
        display: 10,
        fields: [
          {key:'id', name:'ID'},
          {key:'name', name:'Наименование организации'},
          {key:'director', name:'Руководитель'},
          {key:'count_employee', name:'Сотрудников'},
          {key:'count_license', name:'Лицензий'},
          {key:'count_checkups', name:'Обследований'},
        ],
        filters: [
          {key: 'name', type: 'text'},
        ],
      },
    }),
    created () {

      // Для Обследователя
      if(this.id_role == 2) {
        this.optionsObjects.filters.push({key: 'id_organization', type: 'checkbox', label: 'Объекты организации', value: this.id_organization });
        this.optionsObjects.filters.push({key: 'id_user', type: 'checkbox', label: 'Мои объекты', value: this.id_user });
        this.optionsObjects.display = 10;
      }

      // Для Администратора
      if(this.id_role == 1) {
        this.optionsObjects.filters.push({key: 'id_organization', type: 'checkbox', label: 'Объекты организации', value: this.id_organization });
      }

    },
    computed: {
      id_role () {
        return this.$store.state.auth.user.profile.id_role;
      },
      id_organization () {
        return this.$store.state.auth.user.profile.id_organization;
      },
      id_user () {
        return this.$store.state.auth.user.profile.id;
      },
    },
    methods: {
      async deleteUser (e, id, reload) {
        const btn = e.currentTarget;
        btn.classList.add('btn--load');
        const confirm = await this.$refs.confirmDialogue.show({
          message: 'Вы уверены, что хотите удалить этого пользователя?',
          okButton: 'Удалить',
        });
        if(confirm) {
          try {
            await this.$store.dispatch('deleteUser', {id_user: id});
            reload();
          } catch (e) {
            this.$refs.messageDialogue.show({message: this.$helpers.getErrorMessage(e), okButton: 'Подтвердить'});
          }
        }
        btn.classList.remove('btn--load');
      },

    },
  }
</script>

<style scoped>
  .block__header--btn {
    padding: 10px 20px;
  }
  .c-filed-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
    .empty-text {
    font-size: 12px;
    white-space: nowrap;
    color: var(--cozh-grey);
  }
</style>