<template>

  	<TopMenu :headerText="null" />
  	<Breadcrumbs :breadcrumbs="breadcrumbs" />
  	<CheckupNavigator />
  	<ConfirmDialogue ref="confirmDialogue" />
  	<MessageDialogue ref="messageDialogue" />
  	<LinkedItems ref="linkedItemsModal" @linked="linked" />

  	<div class="load-container" :class="{'load' : isLoad}">
	    <div class="content" v-if="!isLoad && !isError">

		    <div class="block block--first">
		    	<div class="block__header">
		    		{{ path.name }}
		    	</div>
		      <div class="block__body">
		      	<div class="hint" v-if="linked_items.length == 0 && addList.length == 0">
			        Данные отсутствуют
			    	</div>
		        <div class="row" v-else>
	          	<div class="col-xs-12 col-sm-6 col-md-3"  v-for="(item) in linked_items" :key="item.id">
	          		<!--  -->
		            <router-link class="element" :to="{
		            	name: 'PageCheckupElement', 
		            	params: {
		            		mkd_id: mkd_id, 
		            		checkup_id: checkup_id, 
		            		module_id: module_id,
		            		element_id: item.id,
		            	}
		            }">
	              	<div class="element__name">
	                	{{ item.name }}
	              	</div>
	              	<a class="link-gray link-gray--ico-right element__delete" @click.prevent="unlinkItem($event, item.id)">
    								<span class="btn--text">Удалить</span>
    								<IconCross />
    							</a>
		            </router-link>
	          	</div>
	          	<div class="col-xs-12 col-sm-6 col-md-3" v-if="addList.length > 0">
		            <a class="element element--add" @click="showLinkedModal">
	              	<div class="element__ico"></div>
	              	<div class="element__addtext">
	                	Добавить элемент
	              	</div>
		            </a>
	          	</div>
		    		</div>
		      </div>
		    </div>
		    <div class="block mt-1">
		    	<div class="block__header">
		    		Схемы
		    	</div>
		      <div class="block__content">
	      		<table class="table-custom" v-if="schemes.length > 0">
	      			<thead>
	      				<tr>
	      					<th width="1px">Номер</th>
	      					<th width="1px" style="text-align: center;">Схема</th>
	      					<th> Объекты осмотра </th>
	      				</tr>
	      			</thead>
	      			<tbody>
	      				<tr v-for="(scheme, index) in schemes" :key="index">
	      					<td> {{ index + 1 }} </td>
	      					<td>
	      						<GalleryPicker v-model="scheme.path" max="1" size="xs" label="Загрузить схему"/>
	      					</td>
	      					<td>
	      						<div class="btn-group">
	      							<input v-model="scheme.objects_of_inspection" class="form-control" type="text" placeholder="Укажите объект осмотра">
	      							<a class="link-gray link-gray--ico-right ms-3" @click="deleteScheme(scheme, index)">
	      								<span class="btn--text">Удалить</span>
	      								<IconCross />
	      							</a>
	      						</div>
	      					</td>
	      				</tr>
	      			</tbody>
	      		</table>
		      	<a class="btn btn--md btn--outline-grey btn--inline" @click="addScheme()">
		      		<IconPlus class="btn--ico--left"/> 
		      		<span> Добавить схему </span> 
		      	</a>
		      </div>
		      <div class="block__footer">
		        <router-link class="btn btn--outline btn--md me-1" :to="{name: 'PageCheckupModules', params: {mkd_id: mkd_id, checkup_id: checkup_id}}">
		          <img class="btn--ico btn--ico--left" src="@/assets/img/6.svg">
		          <div class="btn--text">Вернуться</div>
		        </router-link>
		        <a class="btn btn--default btn--md" @click="saveSchemes($event)">
		        	<img class="btn--ico btn--ico--left" src="@/assets/img/check-white.svg">
	          	<div class="btn--text">Сохранить</div>
	          	<div class="btn--loader"></div>
		        </a>
		      </div>
		    </div>
	    </div>
	    <ErrorContent v-else/>
  	</div>

</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue';
  import CheckupNavigator from '@/components/checkups/CheckupNavigator.vue';
  import ErrorContent from '@/components/ErrorContent.vue';
  import LinkedItems from '@/components/modals/LinkedItems.vue';
  import GalleryPicker from '@/components/ux/GalleryPicker.vue';
  import Breadcrumbs from '@/components/Breadcrumbs.vue'

  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';

  import IconPlus from '@/components/icons/IconPlus-b1.5_w8_h8_grey.vue';
  import IconCross from '@/components/icons/IconCross-b1.5_w8_h8_grey.vue';
</script>

<script>
	export default {
		
		name: 'PageCheckupElements',

		data: () => ({
	      	isLoad: true,
	      	isError: false,
	      	
	      	info: null,
      		path: null,

	      	items_all: [],
	      	linked_items: [],
	      	breadcrumbs: [],

	      	schemes: [],
	    }),

	    computed: {
	    		mkd_id () {
	        	return this.$route.params.mkd_id;
	      	},
	      	checkup_id () {
	        	return this.$route.params.checkup_id;
	      	},
	      	module_id () {
	        	return this.$route.params.module_id;
	      	},
	      	id_role () {
      			return this.$store.state.auth.user.profile.id_role;
      		},
	      	addList () {
		        let arr = [];
		        this.items_all.forEach((item) => {
		          	let index = this.linked_items.findIndex((linked) => linked.id == item.id);
		          	if(index == -1) { arr.push(item); }
		        })
		        return arr;
	      	},
	   
	    },

	    created () {
	      Promise.all([
	      		this.loadInfo(), 
	      		this.loadPath(),
	      		this.loadElementsByModules(), 
	      		this.loadElementsByModulesByCheckup(), 
	      	])
	      	.then(() => {
	        	this.beforeRender();
	        	this.isLoad = false;
	      	})
	      	.catch((e)=> {
	        	console.log(e);
	        	this.isError = true;
	        	this.isLoad = false;
	      	})
	    },

	    methods: {

      	beforeRender() {
          this.breadcrumbs = [
	          {name: this.info.address, link: {name: 'PageObjectsView', params: {mkd_id: this.mkd_id}}},
	          {
	          	name: 'Обследование №' + this.info.checkup_id + ' от ' + this.$moment(this.info.contract_date).format('DD.MM.YYYY'), 
	          	link: {name:'PageCheckupCustomer', params: {mkd_id: this.mkd_id, checkup_id: this.checkup_id}}
	          },
	        	{name: 'Модули', link: { name: 'PageCheckupModules', params: {mkd_id: this.mkd_id, checkup_id: this.checkup_id}}},
	          {name: this.path.name, link: ''},
	        ];

	        if(this.id_role == 2) {
	          // Для обследователя - на главную
	          this.breadcrumbs.unshift({name: 'Главная', link: {name: 'PageHome'}});
	        } else {
	          // Для всех остальных на объекты
	          this.breadcrumbs.unshift({name: 'Объекты', link: {name: 'PageObjects'}});
	        }
	        
      	},

      	showLinkedModal () {
	        this.$refs.linkedItemsModal.show({
          	title: 'Добавить элементы',
          	items: this.addList,
	        });
      	},

      	addScheme () {
      		this.schemes.push({
      			id: null,
        		objects_of_inspection: null,
        		path: [],
      		});
      	},

      	async deleteScheme (scheme, index) {
      		if(scheme.id == null) {
      			this.schemes.splice(index, 1);
      			return;
      		}
    			const confirm = await this.$refs.confirmDialogue.show({
          	message: 'Вы уверены, что хотите удалить эту схему?',
          	okButton: 'Удалить',
        	});
	       	if(!confirm) { return; }
     	 		try {
      			await this.$store.dispatch('deleteSchemeByCheckup', {id_image: scheme.id});
  					this.schemes.splice(index, 1);
     	 		} catch (e) {
    				this.$refs.messageDialogue.show({ message: e.message, okButton: 'Подтвердить' });
     	 		}
      	},

      	async saveSchemes (e) {
      		const btn = e.currentTarget;
      		const schemes = [];
      		// Фильтруем пустые схемы
      		this.schemes = this.schemes.filter((scheme) => {
      			const is = scheme.id == null && scheme.objects_of_inspection == null && (scheme.path == null || scheme.path.length == 0);
      			return !is;
      		});
    			// Пушим на вставку не пустые
      		this.schemes.forEach((scheme) => {
      			schemes.push({
          		id: scheme.id,
          		objects_of_inspection: scheme.objects_of_inspection,
          		path: scheme.path.length == 0 ? null : scheme.path[0],
      			});
      		})
      		// Если есть схемы для отправки
      		if(schemes.length == 0) { return; }
      		btn.classList.add('btn--load');
	        try {
		        await this.$store.dispatch('patchSchemeByCheckup', {id_module: this.module_id, id_checkup: this.checkup_id, schemes: schemes});
		        this.$refs.messageDialogue.show({
	            message: 'Данные сохранены',
	            okButton: 'Подтвердить',
	          });
	          this.loadElementsByModulesByCheckup();
	        } catch (e) {
      			this.$refs.messageDialogue.show({ message: e.message, okButton: 'Подтвердить' });
	        }
      		btn.classList.remove('btn--load');
      	},

      	async loadInfo () {
      		this.info = await this.$store.dispatch('getInfoByCheckup', {id_checkup: this.checkup_id});
      	},
      	async loadPath () {
	        this.path = await this.$store.dispatch('getPath', {id_module: this.module_id});
	    	},
      	async loadElementsByModules () {
        	this.items_all = await this.$store.dispatch('getElementsByModules', {id_module: this.module_id});
      	},
      	async loadElementsByModulesByCheckup () {
        	const data = await this.$store.dispatch('getElementsByModulesByCheckup', {id_checkup: this.checkup_id, id_module: this.module_id});
          // Элементы
          this.linked_items = data.elements;
          this.schemes = [];
          // Преобразование схем
          data.images.forEach((item) => {
          	this.schemes.push({
          		id: item.id,
          		objects_of_inspection: item.objects_of_inspection,
          		path: item.path == null ? [] : [item.path],
          	});
          });
      	},
      	async linked (items, btn) {
	        btn.classList.add('btn--load');
	        try {
          	await this.$store.dispatch('createElementByModuleByCheckup', {id_checkup: this.checkup_id, id_module: this.module_id, element_ids: items});
      			items.forEach((item) => {
      				const idx = this.items_all.findIndex((element) => { return element.id == item })
      				if(idx !== -1) this.linked_items.push(this.items_all[idx]);
      			});
	        } catch (e) {
      			this.$refs.messageDialogue.show({ message: e.message, okButton: 'Подтвердить' });
	        }
          this.$refs.linkedItemsModal.close();
          btn.classList.remove('btn--load');
      	},
      	async unlinkItem (e, id) {
	        const element = e.currentTarget.closest('.element');
	        const confirm = await this.$refs.confirmDialogue.show({
	          	message: 'Вы уверены, что хотите удалить этот элемент?',
	          	okButton: 'Удалить',
	        });
	        if(!confirm) { return; }
	        element.classList.add('element--load');
        	try {
        		await this.$store.dispatch('deleteElementByModuleByCheckup', {id_checkup: this.checkup_id, id_module: this.module_id, element_ids: [id]});
            this.linked_items = this.linked_items.filter((linked) => { return linked.id == id ? false : true });
        	} catch (e) {
      			this.$refs.messageDialogue.show({ message: e.message, okButton: 'Подтвердить' });
        	}
	        element.classList.remove('element--load');
      	},
	    }
	}
</script>

<style scoped>

	.block--first {
    border-radius: 0px 0px 8px 8px;
	}
  .block__body {
	  padding: 20px 30px 10px 30px;
	}

  .block__footer {
  	justify-content: flex-start;
  }

  .block__content {
    /*border-top: 1px solid var(--cozh-light-grey);*/
    padding: 0px 25px 20px 25px;
	}

	.block__content .btn--inline {
		margin-top: 20px;
	}


  .table-custom tbody tr td {
  	padding-top: 10px;
  	padding-bottom: 10px;
  }
  .table-custom tr td:not(:last-child), .table-custom tr th:not(:last-child) {
  	padding: 10px 30px 10px 0px;
  }
  .table-custom tr td:last-child, .table-custom tr th:last-child {
  	padding: 10px 0px 10px 0px;
  }
  .table-custom tbody tr:hover {
    background-color: #fff;
	}
	.table-custom tbody tr td {
    border-bottom: 1px solid var(--cozh-light-grey);
	}
	.table-custom th {
	  height: 55px;
	  color: var(--cozh-dark-grey);
	}


  @media (max-width: 767px) {
    .block__footer a {
      width: 100%;
    }

    .block__content {
	    padding: 20px 20px 20px 20px;
		}

    .table-custom thead {
    	display: none;
    }
    .table-custom tr {
    	display: flex;
    	flex-direction: column;
    }
    .table-custom tr td {
    	height: auto;
    }
    .table-custom tr td:not(:last-child), .table-custom tr th:not(:last-child) {
	  	padding: 0px 0px 15px 0px;
	  }
	  .table-custom tr td:last-child, .table-custom tr th:last-child {
	  	padding: 0px 0px 0px 0px;
	  }
	  .table-custom tbody tr td {
    	border-bottom: none!important;
		}
		.table-custom tbody tr {
			border: 1px solid var(--cozh-light-grey);
    	padding: 15px;
    	border-radius: 8px;
    	position: relative;
		}
		.table-custom tbody tr td:first-child {
			position: absolute;
	    top: 15px;
	    left: 15px;
	    font-size: 16px;
	    font-weight: 700;
	    color: var(--cozh-blue);
		}
		.table-custom tbody tr td:nth-child(2) {
			padding-left: 30px;
		}
		.table-custom tbody tr td:nth-child(3) {
			padding-left: 30px;
		}
		.table-custom tbody tr td:last-child .link-gray {
			position: absolute;
			top: 15px;
			right: 15px;
			margin: 0px;
		}
		.table-custom tbody tr:not(:last-child) {
			margin-bottom: 10px;
		}


  }
</style>