import moment from 'moment';

export default {

   	num_word: (value, words) => {
      value = Math.abs(value) % 100; 
		  var num = value % 10;
		  if(value > 10 && value < 20) return words[2]; 
		  if(num > 1 && num < 5) return words[1];
		  if(num == 1) return words[0]; 
		  return words[2];
    },

    badgeDateParam: (date) => {
        if (moment().format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD')) {
          return { class: 'badge--red', text: 'Сегодня' };
        }
        if (moment().add(1,'days').format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD')) {
          return { class: 'badge--blue', text: 'Завтра' };
        }
        if (moment().add(1,'days') < moment(date)) {
          return { class: 'badge--gray', text: moment(date).format('DD.MM.YYYY') };
        }
    },

    getRandomColor: () => {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },

    getDarkColor: () => {
      var color = '#';
      for (var i = 0; i < 6; i++) {
          color += Math.floor(Math.random() * 10);
      }
      return color;
    },


    statusWorkParam: (status) => {
    	if(status == 0) {
          return { class: 'status--empty', text: '' };
    	}
    	if(status == 1) {
          return { class: 'status--success', text: 'Выполнено' };
    	}
    	if(status == 2) {
          return { class: 'status--error', text: 'Не выполнено' };
    	}
    },

    toBase64: (file) => {
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
      });
    }, 

    validateFields: (form) => {
      let isError = false;
      // Проверка на обязательное заполнение
      for (var key in form) {
        // Проверка обязательности ввода
        if(form[key].required.status) {
        // Прроверка заполнения
          if(form[key].value === null || form[key].value.length === 0) {
            form[key].error.status = true;
            form[key].error.message = form[key].required.message;
            isError = true;
          }
        }
      }
      if(isError) return false;
      // Проверка регулярками
      for (key in form) {
        // Цикл по регуляркам
        for (var i = 0; i <= form[key].validate.length - 1; i++) {
          // Валидация регуляркой значения
          if(!form[key].validate[i].regexp.test(form[key].value.trim())) {
            form[key].error.status = true;
            form[key].error.message = form[key].validate[i].msg;
            isError = true;
            break;
          }
        }
      }
      if(isError) return false;
      return true;
    },

    mkd_address: (mkd) => {
      return mkd.region_name + ', г.' + mkd.city_name + ', ул.' + mkd.mkd_street + ', ' + mkd.mkd_building;
    },

    getErrorMessage: (e) => {
      if(e.response.status >= 400 && e.response.status < 500) {
        if(e.response.data && e.response.data.detail && typeof e.response.data.detail === 'string') {
          return e.response.data.detail;
        }
      }
      return 'Произошла ошибка, попробуйте позже';
    },

}