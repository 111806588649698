<template>

  <TopMenu :headerText="headerText" />
  <Breadcrumbs :breadcrumbs="breadcrumbs" />

  <MessageDialogue ref="messageDialogue" />
  <ConfirmDialogue ref="confirmDialogue" />

  
  <div class="load-container" :class="{'load' : isLoad}">
    <div v-if="!isLoad && !isError">
  
      <!-- Форма с личной информацией -->
      <div class="block">
        <div class="block__header"> 
          Личная информация
        </div>
        <div class="block__body">
          <div class="content">
            <div class="content__left">
              <div class="field">
                <div class="field__left">
                  Фамилия
                </div>
                <div class="field__right">
                  <span v-if="!isEdit"> {{ form.user.surname }} </span>
                  <input v-else class="form-control" type="text" placeholder="Фамилия" v-model="form.user.surname">
                </div>
              </div>
              <div class="field">
                <div class="field__left">
                  Имя
                </div>
                <div class="field__right">
                  <span v-if="!isEdit"> {{ form.user.name }} </span>
                  <input v-else class="form-control" type="text" placeholder="Имя" v-model="form.user.name">
                </div>
              </div>
              <div class="field">
                <div class="field__left">
                  Отчество
                </div>
                <div class="field__right">
                  <span v-if="!isEdit"> {{ form.user.middle_name }} </span>
                  <input v-else class="form-control" type="text" placeholder="Отчество" v-model="form.user.middle_name">
                </div>
              </div>
              <div class="field">
                <div class="field__left">
                  Телефон
                </div>
                <div class="field__right">
                  <span v-if="!isEdit"> {{ form.user.phone }} </span>
                  <input v-else class="form-control" type="text" placeholder="Телефон" v-model="form.user.phone" v-mask="'+7 (###) ### ##-##'">
                </div>
              </div>
              <div class="field">
                <div class="field__left">
                  Электронная почта
                </div>
                <div class="field__right">
                  <span v-if="!isEdit"> {{ form.user.email }} </span>
                  <input v-else class="form-control" type="text" placeholder="Электронная почта" v-model="form.user.email">
                </div>
              </div>
            </div>
            <div class="content__right">
              <ImagePicker v-model="form.user.avatar" :viewMode="!isEdit"/>
            </div>
          </div>
        </div>
        <div class="block__footer">
          <a class="btn btn--outline btn--md hidden-xs" @click="$router.go(-1)">
            <img class="btn--ico btn--ico--left" src="@/assets/img/6.svg">
            <div class="btn--text">Вернуться</div>
          </a>
          <div class="btn-group" v-if="!isEdit">
            <a class="btn btn--default btn--md me-1" @click="isEdit = true">
              <div class="btn--text">Редактировать</div>
              <div class="btn--loader"></div>
            </a>
            <a class="btn btn--outline btn--md" @click="isChangePassword = true" v-if="!isChangePassword">
              <div class="btn--text">Изменить пароль</div>
              <div class="btn--loader"></div>
            </a>
          </div>
          <div class="btn-group btn-group--row" v-if="isEdit">
            <a class="btn btn--default btn--md me-1" :class="{'disabled' : isDisabledEdit}" @click="saveInfo($event)">
              <div class="btn--text">Сохранить</div>
              <div class="btn--loader"></div>
            </a>
            <a class="btn btn--outline btn--md" @click="cancelInfo()">
              <div class="btn--text">Отменить</div>
              <div class="btn--loader"></div>
            </a>
          </div>
        </div>
      </div>

      <!-- Форма с паролем -->
      <div class="block mt-1" v-if="isChangePassword">
        <div class="block__header"> 
          Изменить пароль
        </div>
        <div class="block__body">
          <div class="content">
            <div class="content__left">
              <div class="field">
                <div class="field__left">
                  Новый пароль
                </div>
                <div class="field__right">
                  <div class="form-group">
                    <input class="form-control form-control--password" type="password" placeholder="Новый пароль" v-model="form.secure.new_password">
                    <span class="password-watch" ref="passwordWatchNew"></span>
                    <a class="link-default-dashed password-generate" @click="generatePassword($event)"> 
                      <div class="btn--loader"> </div>
                      <span class="btn--text"> Сгенерировать <div class="visible-md visible-lg" style="margin-left: 4px;"> пароль </div> </span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="field">
                <div class="field__left">
                  Подтвердите новый пароль
                </div>
                <div class="field__right">
                  <div class="form-group">
                    <input class="form-control form-control--password" type="password" placeholder="Подтвердите новый пароль" v-model="form.secure.confirm_password">
                    <span class="password-watch" ref="passwordWatchConfirm"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block__footer">
          <div class="btn-group btn-group--row">
            <a class="btn btn--default btn--md me-1" :class="{'disabled': isDisabledSavePassword}" @click="savePassword($event)">
              <div class="btn--text">Сохранить</div>
              <div class="btn--loader"></div>
            </a>
            <a class="btn btn--outline btn--md" @click="cancelSecure()">
              <div class="btn--text">Отменить</div>
              <div class="btn--loader"></div>
            </a>
          </div>
        </div>
      </div>
      <div class="block mt-1 visible-xs">
        <div class="block__footer" style="border: none">
          <a class="btn btn--outline btn--md" @click="$router.go(-1)">
            <img class="btn--ico btn--ico--left" src="@/assets/img/6.svg">
            <div class="btn--text">Вернуться</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
  import TopMenu from '@/components/TopMenu.vue';
  import ImagePicker from '@/components/ux/ImagePicker.vue';
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
</script>

<script>
  export default {
    
    name: 'PageUserProfile',

    data: () => ({
      headerText: null,
      breadcrumbs: [],

      isLoad: true,
      isError: false,
      
      isEdit: false,
      isChangePassword: false,

      form: {
        user: null,
        secure: {
          new_password: null,
          confirm_password: null,
        },
      },

      required: ['surname', 'name', 'middle_name', 'email', 'phone'],

      user: null,
      organization: null,

    }),
    computed: {
      user_id () {
        return this.$route.params.user_id;
      },
      organization_id () {
        return this.$route.params.organization_id;
      },
      isDisabledSavePassword() {
        const data = [];
        for (let key in this.form.secure) {
          data.push(this.form.secure[key] == null ? '' : this.form.secure[key]);
        }
        return data.includes('') ? true : false;
      },
      isDisabledEdit () {
        const data = [];
        this.required.forEach((key) => {
          data.push(this.form.user[key] == null ? '' : this.form.user[key]);
        })
        return data.includes('') ? true : false;
      },
      id_role () {
        return this.$store.state.auth.user.profile.id_role;
      },
    },
    created () {

      const loads = [this.loadUser()];

      if(this.organization_id) {
        loads.push( this.loadOrganization() );
      }

      Promise.all(loads)
        .then(() => {
          this.beforeRender();
          this.isLoad = false;
        })
        .catch((e)=> {
          console.log(e);
          this.isError = true;
          this.isLoad = false;
        })
    },
    methods: {
      
      beforeRender() {

        const fio = this.user.surname + ' ' + this.user.name + ' ' + this.user.middle_name;

        this.form.user = { ...this.user };

        if (this.organization_id) {

          this.breadcrumbs = [
            {name: this.organization.name, link: {name: 'PageOrganization', params: {organization_id: this.organization.id}}},
            {name: fio, link: ''},
          ];

        } else {

          this.breadcrumbs = [
            {name: 'Пользователи', link: {name: 'PageUsers'}},
            {name: fio, link: ''},
          ];

        }

      },

      cancelInfo () {
        this.isEdit = false;
        // При отмене редактирования возвращаем в локальную переменную клон профиля
        this.form.user = { ...this.user };
      },

      cancelSecure () {
        this.isChangePassword = false;
        this.form.secure.new_password = null;
        this.form.secure.confirm_password = null;
      },
      
      async loadOrganization () {
        const organization = await this.$store.dispatch('getOrganization', {id: this.organization_id});
        this.organization = organization;
      },

      async saveInfo (e) {
        const btn = e.currentTarget;
        btn.classList.add('btn--load');
        try {
          await this.$store.dispatch('changeUserProfile', {id_user: this.form.user.id, data: this.form.user});
          this.$refs.messageDialogue.show({
            message: 'Данные сохранены',
            okButton: 'Подтвердить',
          });
          this.isEdit = false;
        } catch (e) {
          this.$refs.messageDialogue.show({message: e.message, okButton: 'Подтвердить'});
        }
        btn.classList.remove('btn--load');
      },

      async generatePassword (e) {
        const btn = e.currentTarget;
        btn.classList.add('btn--load');
        try {
          const { password } = await this.$store.dispatch('getPassword');
          await new Promise(r => setTimeout(r, 300));
          this.form.secure.new_password = password;
          this.form.secure.confirm_password = password;
          if(!this.$refs.passwordWatchNew.classList.contains('active')) {
            this.$refs.passwordWatchNew.click();
          }
          if(!this.$refs.passwordWatchConfirm.classList.contains('active')) {
            this.$refs.passwordWatchConfirm.click();
          }
        } catch (e) {
          this.$refs.messageDialogue.show({message: e.message, okButton: 'Подтвердить'});
        }
        btn.classList.remove('btn--load');
      },

      async savePassword (e) {
        const btn = e.currentTarget;
        if (this.form.secure.new_password !== this.form.secure.confirm_password) {
          this.$refs.messageDialogue.show({
            message: 'Пароли не совпадают',
            text: 'Попробуйте заново указать пароль или нажмите кнопку «Сгенерировать пароль»',
            okButton: 'Подтвердить',
          });
          return true;
        }
        btn.classList.add('btn--load');
        try {
          await this.$store.dispatch('changeUserPassword', {id_user: this.user_id, new_password: this.form.secure.new_password});
          // Уведомление для пользователя
          this.$refs.messageDialogue.show({
            message: 'Новый пароль установлен',
            text: 'Новый пароль: ' + this.form.secure.new_password,
            okButton: 'Подтвердить',
          });
          //  Очищаем данные
          this.form.secure.new_password = null;
          this.form.secure.confirm_password = null;
          // Скрываем форму смены пароля
          this.isChangePassword = false;
        } catch (e) {
          this.$refs.messageDialogue.show({message: e.response.data.detail,
            okButton: 'Подтвердить',
          });
        }
        btn.classList.remove('btn--load');
      },
      async loadUser () {
        this.user = await this.$store.dispatch('getUser', {id_user: this.user_id});
      },
    },
  }
</script>


<style scoped>

  .block {
    width: 100%;
  }
  .content {
    display: flex;
      flex-direction: row;
  }
  .content__right {
      padding: 20px;
      border-left: 1px solid var(--cozh-light-grey);
      min-width: 300px;
  }
  .content__left {
    width: 100%;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .field {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    min-height: 40px;
    padding: 0px 20px;
  }    
  .field__left {
    min-width: 400px;
    font-size: 13px;
    font-weight: 500;
  }
  .field__right {
    width: 100%;
    font-size: 13px;
    font-weight: 500;
  }
  .field:not(:last-child) {
    margin-bottom: 10px;
  }

  @media (max-width: 1200px) {
    .field__left {
      min-width: 200px;
    }
  }

  @media (max-width: 1000px) {
    .field {
      flex-direction: column;
      gap: 5px;
    }
    .field__left {
      width: 100%;
      color: rgba(var(--cozh-black-code), .5);
    }
    .field__right {
      font-weight: 600;
    }
    .field:not(:last-child) {
      margin-bottom: 15px;
    }
    .content__right {
      min-width: 350px;
    }
  }

  @media (max-width: 767px) {
    .block__footer {
      flex-direction: column-reverse;
    }
    .content {
      flex-direction: column-reverse;
    }
    .content__right {
      border-left: none;
      border-bottom: 1px solid var(--cozh-light-grey);
      min-width: auto;
    }
    .btn-group {
      flex-direction: column;
      width: 100%;
    }
    .block__footer a {
      width: 100%;
    }
    .block__footer > a:not(:last-child):first-child {
      margin-top: 10px;
    }
    .btn-group a:first-child {
      margin-right: 0px;
    }
    .btn-group a:last-child:not(:first-child) {
      margin-top: 10px;
    }

    .btn-group--row {
      flex-direction: row;
    }
    .btn-group--row a:last-child:not(:first-child) {
      margin-top: 0px;
      margin-left: 10px;
    }
  }
</style>