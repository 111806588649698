<template>

  	<TopMenu :headerText="null" />
  	<Breadcrumbs :breadcrumbs="breadcrumbs" />
  	<CheckupNavigator />
  	<ConfirmDialogue ref="confirmDialogue" />
  	<MessageDialogue ref="messageDialogue" />
  	<LinkedItems ref="linkedItemsModal" @linked="linked" />

    <div class="block">
      <div class="block__body">

      	<div class="load-container" :class="{'load' : isLoad}">
			    <div class="content" v-if="!isLoad && !isError">
			    
		      	<div class="hint" v-if="linked_items.length == 0 && addList.length == 0">
			        Данные отсутствуют
			    	</div>
		        <div class="row" v-else>
	          	<div class="col-xs-12 col-sm-6 col-md-3"  v-for="(item) in linked_items" :key="item.id">
		            <router-link class="element" :to="{
		            	name: 'PageCheckupElements', 
		            	params: {
		            		mkd_id: mkd_id, 
		            		checkup_id: checkup_id, 
		            		module_id: item.id,
		            	}
		            }">
		              	<div class="element__name">
		                	{{ item.name }}
		              	</div>
		              	<a class="link-gray link-gray--ico-right element__delete" @click.prevent="unlinkItem($event, item.id)">
	    								<span class="btn--text">Удалить</span>
	    								<IconCross />
	    							</a>
		            </router-link>
	          	</div>
	          	<div class="col-xs-12 col-sm-6 col-md-3" v-if="addList.length > 0">
		            <a class="element element--add" @click="showLinkedModal">
	              	<div class="element__ico"></div>
	              	<div class="element__addtext">
	                	Добавить модуль
	              	</div>
		            </a>
	          	</div>
		    		</div>

	    		</div>
			    <ErrorContent v-else/>
		  	</div>

      </div>
      <div class="block__footer">
        <router-link class="btn btn--outline btn--md" :to="{name: 'PageCheckupCustomer', params: {mkd_id: mkd_id}}">
          <img class="btn--ico btn--ico--left" src="@/assets/img/6.svg">
          <div class="btn--text">Вернуться</div>
        </router-link>
        <a class="btn btn--default btn--md" @click="generateReport($event)" v-if="linked_items.length > 0">
          <div class="btn--text">Сформировать отчет</div>
          <div class="btn--loader"></div>
        </a>
      </div>
    </div>
	 
</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue';
  import CheckupNavigator from '@/components/checkups/CheckupNavigator.vue';
  import ErrorContent from '@/components/ErrorContent.vue';
  import LinkedItems from '@/components/modals/LinkedItems.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import IconCross from '@/components/icons/IconCross-b1.5_w8_h8_grey.vue';
  import Breadcrumbs from '@/components/Breadcrumbs.vue'
</script>

<script>
	export default {
	    name: 'PageCheckupModules',

	    data: () => ({

	      isLoad: true,
	      isError: false,

	      info: null,

	      items_all: [],
	      linked_items: [],
	      breadcrumbs: [],
	    }),
	    computed: {
	      	checkup_id () {
	        	return this.$route.params.checkup_id;
	      	},
	      	mkd_id () {
	        	return this.$route.params.mkd_id;
	      	},
	      	id_role () {
      			return this.$store.state.auth.user.profile.id_role;
      		},
	      	addList () {
		        let arr = [];
		        this.items_all.forEach((item) => {
		          	let index = this.linked_items.findIndex((linked) => linked.id == item.id);
		          	if(index == -1) { arr.push(item); }
		        })
		        return arr;
	      	},
	    },
	    created () {
	      	Promise.all([
	      		this.loadInfo(), 
	      		this.$store.dispatch('getModules'), 
	      		this.loadModulesByCheckup() 
	      	])
	      	.then(() => {
	        	this.beforeRender();
	        	this.isLoad = false;
	      	})
	      	.catch((e)=> {
	        	console.log(e);
	        	this.isError = true;
	        	this.isLoad = false;
	      	})
	    },
	    methods: {
      	beforeRender() {
        	this.items_all = [ ...this.$store.state.modules ];
          this.breadcrumbs = [
	          {name: this.info.address, link: {name: 'PageObjectsView', params: {mkd_id: this.mkd_id}}},
	          {
	          	name: 'Обследование №' + this.info.checkup_id + ' от ' + this.$moment(this.info.contract_date).format('DD.MM.YYYY'), 
	          	link: {name:'PageCheckupCustomer', params: {mkd_id: this.mkd_id, checkup_id: this.checkup_id}}
	          },
		        {name: 'Модули', link: ''},
	        ];

	        if(this.id_role == 2) {
	          // Для обследователя - на главную
	          this.breadcrumbs.unshift({name: 'Главная', link: {name: 'PageHome'}});
	        } else {
	          // Для всех остальных на объекты
	          this.breadcrumbs.unshift({name: 'Объекты', link: {name: 'PageObjects'}});
	        }

      	},
      	showLinkedModal () {
	        this.$refs.linkedItemsModal.show({
	          	title: 'Добавить модули',
	          	items: this.addList,
	        });
      	},
      	async loadInfo () {
      		this.info = await this.$store.dispatch('getInfoByCheckup', {id_checkup: this.checkup_id});
      	},
      	async loadModulesByCheckup () {
      		this.linked_items = await this.$store.dispatch('getModulesByCheckup', {id_checkup: this.checkup_id});
      	},
      	async linked (items, btn) {
	        btn.classList.add('btn--load');
	        try {
          	await this.$store.dispatch('createModuleByCheckup', {id_checkup: this.checkup_id, module_ids: items});
        		items.forEach((item) => {
      				const idx = this.items_all.findIndex((element) => { return element.id == item })
      				if(idx !== -1) this.linked_items.push(this.items_all[idx]);
      			});
	        } catch (e) {
        		this.$refs.messageDialogue.show({ message: e.message, okButton: 'Подтвердить' });
	        }
          this.$refs.linkedItemsModal.close();
          btn.classList.remove('btn--load');
      	},
      	async unlinkItem (e, id) {
	        const element = e.currentTarget.closest('.element');
	        const confirm = await this.$refs.confirmDialogue.show({
	          message: 'Вы уверены, что хотите удалить этот модуль?',
	          okButton: 'Удалить',
	        });
      		if(!confirm) { return; }
	        element.classList.add('element--load');
        	try {
          	await this.$store.dispatch('deleteModuleByCheckup', {id_checkup: this.checkup_id, module_ids: [id]});
            this.linked_items = this.linked_items.filter((linked) => { return linked.id == id ? false : true });
        	} catch (e) {
        		this.$refs.messageDialogue.show({ message: e.message, okButton: 'Подтвердить' });
        	}
	        element.classList.remove('element--load');
      	},
      	async generateReport (e) {
	        const btn = e.currentTarget;
	        btn.classList.add('btn--load');
	        try {
        		await this.$store.dispatch('getReport', {id_checkup: this.checkup_id});
	        	this.$router.push({name: 'PageArchiveCheckup', params: {mkd_id: this.mkd_id, checkup_id: this.checkup_id }})
	        } catch (e) {
        		this.$refs.messageDialogue.show({ message: e.message, okButton: 'Подтвердить' });
	        }
          btn.classList.remove('btn--load');
      	},
	    }
	}
</script>

<style scoped>
	.block {
    border-radius: 0px 0px 8px 8px;
	}
  .content {
	  padding: 20px 30px 10px 30px;
	}

  @media (max-width: 767px) {
    .block__footer {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
    .block__footer a:first-child {
      margin-top: 10px;
    }
    .block__footer a {
      width: 100%;
    }
  }
</style>