<template>
  <div class="bread">
    <div class="bread__item" v-for="(item, index) in breadcrumbs" :key="index">
      <router-link class="bread__link" :to="item.link" v-if="index != breadcrumbs.length - 1">
        <span> {{ item.name }} </span>
      </router-link>
      <span v-else>
        {{ item.name }}
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BreadrumbsSteps',
    props: [ 'breadcrumbs' ],
    data: () => ({
    }),
    computed: {
    },
    methods: {
    },
  }
</script>

<style scoped>
  .bread {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 50px;
    padding: 0px 20px;
    background-color: var(--cozh-white);
    margin-bottom: 10px;
    border-radius: 8px;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .bread::-webkit-scrollbar {
    display: none;
  }
  .bread__item span {
    font-size: 12px;
    font-weight: 600;
    user-select: none;
    white-space: nowrap;
    display: block;
    line-height: 1;
  }
  .bread__link {
    margin-right: 25px;
    position: relative;
    color: var(--cozh-blue);
    border-bottom: 1px solid rgba(var(--cozh-blue-code), 0.35);
    line-height: 1;
    height: fit-content;
    display: block;
    white-space: nowrap;
  }
  .bread__link span {
    font-size: 12px;
    font-weight: 500;
  }

  .bread__link::before {
    content: url('@/assets/img/5.svg');
    position: absolute;
    right: -17px;
    top: 0px;
  }
  .bread__link:hover {
    border-color: transparent;
    opacity: .75;
  }
  .bread__link::after {
    content: url('@/assets/img/back.svg');
    display: none;
    position: absolute;
    left: -13px;
    top: 50%;
    transform: translateY(-50%);
  }
  @media (max-width: 767px) {
    .bread {
      justify-content: space-between;
    }
    .bread__link {
      border: none;
    }
    .bread__link::before {
      display: none;
    }
    .bread__link::after {
      display: block;
    }
    .bread .bread__item:not(:nth-last-child(2)):not(:last-child) {
      display: none;
    }
    .bread .bread__item:nth-last-child(2) {
      padding-left: 13px;
    }
    .bread__item span, .bread__item .bread__link span {
      /*max-width: 160px;*/
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
</style>