<template>
	<div class="gallery gellery--delete" :class="size ? 'gellery--' + size : ''">
		<div class="gallery__item" v-for="(image, index) in modelValue" :key="index">
			<a class="gallery__image" :style="{backgroundImage:'url(' + image + ')'}" :data-fancybox="'gallery-' + uniq" :data-src="image"></a>
			<a class="gallery__delete" @click="deleteImage(index)" v-if="!viewMode">
        <IconCross />
	    </a>
		</div>
		<div class="gallery__item" v-if="!viewMode && modelValue.length < maxLen">
			<input type="file" :id="uniq" :multiple="maxLen < 2 ? false : true" @change="onFileChanged($event)" accept="image/png, image/gif, image/jpeg">
			<label class="gallery__button" :for="uniq">
				<div class="gallery__ico">
          <!-- Стандартная иконка -->
					<svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!size">
						<path d="M5 1.5V9.5M1 5.5H9" stroke="var(--cozh-grey)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
          <!-- Маленькая иконка -->
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="size == 'xs'">
            <path d="M5 9V1M9 5H1" stroke="var(--cozh-grey)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
				</div>
				<div class="gallery__text"> {{ addText }} </div>
			</label>
		</div>
	</div>
</template>

<script setup>
    import IconCross from '../icons/IconCross-b2.w8.h8.vue'
</script>

<script>
	export default {
	    name: 'GalleryPicker',
	    props: ['modelValue', 'viewMode', 'max', 'size', 'label'],
	    data: () => ({
        uniq: null,
        addText: null,
        maxLen: null,
	    }),
      created () {
        this.uniq = "id" + Math.random().toString(16).slice(2);
        this.addText = this.label ? this.label : 'Добавить фото';
        this.maxLen = Number(this.max) ? this.max : 30;
      },

	    methods: {
	    	deleteImage(idx) {
		      let files = this.modelValue;
		      files.splice(idx, 1);
	    		this.$emit('update:modelValue', files);
	    	},
	    	async onFileChanged (e) {
		        const input = e.currentTarget;
		        if(input.files.length > 0) {
		        	let files = this.modelValue;
		          	Array.from(input.files).forEach(async file => {
		          		let base64 = await this.$helpers.toBase64(file);
		          		files.push(base64);
	    				    this.$emit('update:modelValue', files);
		          	});
		        }
		    },
	    },

    }
</script>

<style scoped>

	.gallery__button {
		height: 100%;
		width: 100%;
    position: relative;
    border: 1px dashed var(--cozh-light-grey);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-out;
    cursor: pointer;
    overflow: hidden;
    border-radius: 8px;
    padding: 10px;
	}

	.gallery__item input {
		opacity: 0;
       	position: absolute;
       	z-index: -1;
	}

	  .gallery__button:hover {
        border-color: var(--cozh-grey);
    }


    .gallery__ico {
        width: 36px;
        height: 36px;
        border: 1px solid rgba(var(--cozh-grey-code), .25);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .2s ease-out;
        z-index: 2;
    }

    .gallery__text {
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 0.07em;
        color: var(--cozh-grey);
        text-transform: uppercase;
        margin-top: 6px;
        transition: all .2s ease-out;
        z-index: 2;
        text-align: center;
    }






  .gallery {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
    overflow: hidden;
  }
  .gallery__item {
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 120px;
    width: 170px;
    position: relative;
  }
  .gallery__image {
    height: 100%;
    width: 100%;
    background-origin: border-box;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    display: flex;
    box-sizing: border-box;
    transition: unset;
  }
  .gallery__image::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    transition: all .2s ease-out;
  }
  .gellery--delete .gallery__image::before {
    background: rgba(var(--cozh-black-code),.1);
  }
  
  .gallery__image::after {
    content: url('../../assets/img/scale.svg');
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease-out;
  }
  .gallery__image:hover {
    border-color: rgba(var(--cozh-blue-code), .75);
  }
  .gallery__image:hover::before {
    background-color: rgba(var(--cozh-blue-code), .75);
  }
  .gallery__image:hover::after {
    opacity: 1;
    visibility: visible;
  }

  .gallery__delete {
    user-select: none;
    position: absolute;

    top: 5px;
    right: 5px;

    height: 24px;
    width: 24px;
    background: rgba(var(--cozh-black-code), .15); 
    border-radius: 12px; 
    backdrop-filter: blur(18px);
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .gallery__delete:deep(svg path) {
    transition: all .2s ease-out;
    stroke: rgba(var(--cozh-white-code), 1);
  }
/*  .gallery__delete:hover:deep(svg path) {
    stroke: rgba(var(--cozh-blue-code), 1);
  }*/




  .gellery--xs .gallery__item {
    height: 70px;
    width: 100px;
  }
  .gellery--xs .gallery__image {
    border-radius: 8px;
  }
  .gellery--xs .gallery__button {
    border-radius: 8px;
  }
  .gellery--xs .gallery__ico {
    width: auto;
    height: auto;
    border: none;
  }
  .gellery--xs .gallery__text {
    font-size: 9px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.45px;
  }

  @media (max-width: 767px) {
    .gallery__item {
      width: calc(50% - 10px);
    }
  }
</style>