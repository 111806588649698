<template>

  	<TopMenu :headerText="null" />

	<MessageDialogue ref="messageDialogue" />
	<ConfirmDialogue ref="confirmDialogue" />

	<Chat :from="auth_id" :to="user_id"/>

</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue';
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
  import Chat from '@/components/support/Chat.vue';
</script>

<script>
  export default {
    name: 'RootChat',
    data: () => ({}),
    computed: {
    	user_id () {
    		return this.$route.params.user_id;
    	},
    	auth_id () {
        	return this.$store.state.auth.user.profile.id;
    	}
    },
  }
</script>
