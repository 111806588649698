<template>
  		
  <TopMenu :headerText="headerText" />
			
	<MessageDialogue ref="messageDialogue" />
	<ConfirmDialogue ref="confirmDialogue" />

	<!-- Профиль пользователя -->
	<div class="block">
    <div class="block__header"> 
    	Личная информация
    </div>
    <div class="block__body">
    	<div class="content">
    		<div class="content__left">
    			<div class="field">
    				<div class="field__left">
    					Фамилия
    				</div>
    				<div class="field__right">
    					<span v-if="!isEdit"> {{ profile.surname }} </span>
    					<input v-else class="form-control" type="text" placeholder="Фамилия" v-model="profile.surname">
    				</div>
    			</div>
    			<div class="field">
    				<div class="field__left">
    					Имя
    				</div>
    				<div class="field__right">
    					<span v-if="!isEdit"> {{ profile.name }} </span>
    					<input v-else class="form-control" type="text" placeholder="Имя" v-model="profile.name">
    				</div>
    			</div>
    			<div class="field">
    				<div class="field__left">
    					Отчество
    				</div>
    				<div class="field__right">
    					<span v-if="!isEdit"> {{ profile.middle_name }} </span>
    					<input v-else class="form-control" type="text" placeholder="Отчество" v-model="profile.middle_name">
    				</div>
    			</div>
    			<div class="field">
    				<div class="field__left">
    					Телефон
    				</div>
    				<div class="field__right">
    					<span v-if="!isEdit"> {{ profile.phone }} </span>
    					<input v-else class="form-control" type="text" placeholder="Телефон" v-model="profile.phone" v-mask="'+7 (###) ### ##-##'">
    				</div>
    			</div>
    			<div class="field">
    				<div class="field__left">
    					Электронная почта
    				</div>
    				<div class="field__right">
    					<span v-if="!isEdit"> {{ profile.email }} </span>
    					<input v-else class="form-control" type="text" placeholder="Электронная почта" v-model="profile.email">
    				</div>
    			</div>
    		</div>
    		<div class="content__right">
					<ImagePicker v-model="profile.avatar" :viewMode="!isEdit"/>
    		</div>
    	</div>
    </div>
    <div class="block__footer">
    	<div class="btn-group" v-if="!isEdit">
    		<a class="btn btn--default btn--md me-1" @click="isEdit = true">
    			<div class="btn--text">Редактировать</div>
    			<div class="btn--loader"></div>
    		</a>
    		<a class="btn btn--outline btn--md" @click="isChangePassword = true" v-if="!isChangePassword">
    			<div class="btn--text">Изменить пароль</div>
    			<div class="btn--loader"></div>
    		</a>
    	</div>
    	<div class="btn-group btn-group--row" v-if="isEdit">
    		<a class="btn btn--default btn--md me-1" @click="saveInfo($event)">
    			<div class="btn--text">Сохранить</div>
    			<div class="btn--loader"></div>
    		</a>
    		<a class="btn btn--outline btn--md" @click="cancelInfo()">
    			<div class="btn--text">Отменить</div>
    			<div class="btn--loader"></div>
    		</a>
    	</div>
    </div>
  </div>


	<!-- Пароли -->
  <div class="block mt-1" v-if="isChangePassword">
    <div class="block__header"> 
    	Изменить пароль
    </div>
    <div class="block__body">
      <div class="content">
        <div class="content__left">
					<div class="field">
						<div class="field__left">
							Старый пароль
						</div>
						<div class="field__right">
							<div class="form-group">
		  					<input class="form-control form-control--password" type="password" placeholder="Старый пароль" v-model="secure.old_password">
		  					<span class="password-watch"></span>
							</div>
						</div>
					</div>
					<div class="field">
						<div class="field__left">
							Новый пароль
						</div>
						<div class="field__right">
	  					<div class="form-group">
	  						<input class="form-control form-control--password" type="password" placeholder="Новый пароль" v-model="secure.new_password">
		  					<span class="password-watch" ref="passwordWatchNew"></span>
                <a class="link-default-dashed password-generate" @click="generatePassword($event)"> 
                  <div class="btn--loader"> </div>
                  <span class="btn--text"> Сгенерировать <div class="visible-md visible-lg" style="margin-left: 4px;"> пароль </div> </span> 
                </a>
	  					</div>
						</div>
					</div>
					<div class="field">
						<div class="field__left">
							Подтвердите новый пароль
						</div>
						<div class="field__right">
							<div class="form-group">
								<input class="form-control form-control--password" type="password" placeholder="Подтвердите новый пароль" v-model="secure.confirm_password">
		  					<span class="password-watch" ref="passwordWatchConfirm"></span>
							</div>
						</div>
					</div>
				</div>
			</div>
    </div>
    <div class="block__footer">
    	<div class="btn-group btn-group--row">
    		<a class="btn btn--default btn--md me-1" :class="{'disabled': isDisabledSavePassword}" @click="savePassword($event)">
    			<div class="btn--text">Сохранить</div>
    			<div class="btn--loader"></div>
    		</a>
    		<a class="btn btn--outline btn--md" @click="cancelSecure()">
    			<div class="btn--text">Отменить</div>
    			<div class="btn--loader"></div>
    		</a>
    	</div>
    </div>
  </div>

	<!-- Данные по организации -->
	<OrganizationCard :data="organization"  v-if="id_role !== 0" :isEdit="false"/>

</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue';
  import OrganizationCard from '@/components/organizations/Card.vue';

  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
  import ImagePicker from '@/components/ux/ImagePicker.vue'
</script>

<script>
  export default {
    
    name: 'PageProfile',

    data: () => ({
      headerText: null,

      isEdit: false,
      isChangePassword: false,

      profile: null,

      secure: {
      	old_password: null,
      	new_password: null,
      	confirm_password: null,
      },

    }),

    created () {
    	// При создании клонируем объект профиля в локальную переменную
    	this.profile = { ...this.$store.getters.authUser.profile };
    },

    computed: {
    	isDisabledSavePassword () {
    		const data = [];
        for (let key in this.secure) {
          data.push(this.secure[key] == null ? '' : this.secure[key]);
        }
        return data.includes('') ? true : false;
    	},
      organization () {
        return this.$store.getters.authUser.organization;
      },
      id_role () {
        return this.$store.state.auth.user.profile.id_role;
      },
    },

    methods: {

      beforeRender() {},

      cancelInfo () {
    		this.isEdit = false;
    		// При отмене редактирования возвращаем в локальную переменную клон профиля
    		this.profile = { ...this.$store.getters.authUser.profile };
    	},

    	cancelSecure () {
        this.isChangePassword = false;
        this.secure.old_password = null;
        this.secure.new_password = null;
        this.secure.confirm_password = null;
      },

    	async saveInfo (e) {
    		const btn = e.currentTarget;
    		btn.classList.add('btn--load');
    		try {
          const profile = await this.$store.dispatch('patchProfile', this.profile);
          this.$store.commit('setProfile', {profile: profile});
          this.isEdit = false;
    			this.$refs.messageDialogue.show({message: 'Данные сохранены', okButton: 'Подтвердить'});
    		} catch (e) {
    			this.$refs.messageDialogue.show({message: e.message, okButton: 'Подтвердить'});
    		}
    		btn.classList.remove('btn--load');
    	},

    	async generatePassword (e) {
    		const btn = e.currentTarget;
    		btn.classList.add('btn--load');
        await new Promise(r => setTimeout(r, 300));
    		try {
          const { password } = await this.$store.dispatch('getPassword');
  				this.secure.new_password = password;
  				this.secure.confirm_password = password;
  				if(!this.$refs.passwordWatchNew.classList.contains('active')) {
  					this.$refs.passwordWatchNew.click();
  				}
					if(!this.$refs.passwordWatchConfirm.classList.contains('active')) {
  					this.$refs.passwordWatchConfirm.click();
					}

    		} catch (e) {
    			this.$refs.messageDialogue.show({message: e.message, okButton: 'Подтвердить'});
    		}
    		btn.classList.remove('btn--load');
    	},

    	async savePassword (e) {
    		const btn = e.currentTarget;
        if (this.secure.new_password !== this.secure.confirm_password) {
					this.$refs.messageDialogue.show({
						message: 'Пароли не совпадают',
						text: 'Попробуйте заново указать пароль или нажмите кнопку «Сгенерировать пароль»',
						okButton: 'Подтвердить',
					});
    			return true;
    		}
        btn.classList.add('btn--load');
    		try {
          await this.$store.dispatch('changePasswordByAuthUser', this.secure);
    			this.$refs.messageDialogue.show({
						message: 'Новый пароль установлен',
						text: 'Новый пароль: ' + this.secure.new_password,
						okButton: 'Подтвердить',
					});
          this.secure.old_password = null;
          this.secure.new_password = null;
          this.secure.confirm_password = null;
          this.isChangePassword = false;
    		} catch (e) {
    			this.$refs.messageDialogue.show({message: e.response.data.detail, okButton: 'Подтвердить'});
    		}
    		btn.classList.remove('btn--load');
    	}

    },

  }
</script>

<style scoped>

  .block {
    width: 100%;
  }

  .content {
    display: flex;
      flex-direction: row;
  }
  .content__right {
      padding: 20px;
      border-left: 1px solid var(--cozh-light-grey);
      min-width: 300px;
  }
  .content__left {
      width: 100%;
      padding: 20px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
  }


  .field {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    min-height: 40px;
    padding: 0px 20px;
  }    
  .field__left {
    min-width: 400px;
    font-size: 13px;
    font-weight: 500;
  }
  .field__right {
    width: 100%;
    font-size: 13px;
    font-weight: 500;
  }
  .field:not(:last-child) {
    margin-bottom: 10px;
  }

  @media (max-width: 1200px) {
    .field__left {
      min-width: 200px;
    }
  }

  @media (max-width: 1000px) {
    .field {
      flex-direction: column;
      gap: 5px;
    }
    .field__left {
      width: 100%;
      color: rgba(var(--cozh-black-code), .5);
    }
    .field__right {
      font-weight: 600;
    }
    .field:not(:last-child) {
      margin-bottom: 15px;
    }
    .content__right {
      min-width: 350px;
    }
  }

  @media (max-width: 767px) {

    .block__footer {
      flex-direction: column-reverse;
    }
    .content {
      flex-direction: column-reverse;
    }
    .content__right {
      border-left: none;
      border-bottom: 1px solid var(--cozh-light-grey);
      min-width: auto;
    }
    .btn-group {
      flex-direction: column;
      width: 100%;
    }
    .block__footer a {
      width: 100%;
    }
    .block__footer > a:first-child {
      margin-top: 10px;
    }
    .btn-group a:first-child {
      margin-right: 0px;
    }
    .btn-group a:last-child:not(:first-child) {
      margin-top: 10px;
    }

    .btn-group--row {
      flex-direction: row;
    }
    .btn-group--row a:last-child:not(:first-child) {
      margin-top: 0px;
      margin-left: 10px;
    }
  }
</style>