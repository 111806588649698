<template>
  <div class="auth">
    <div class="auth__form">
      <h1 class="auth__title"> Войти в личный кабинет </h1>
      <form @submit.prevent="onSubmit($event)">
        <div class="form-group" :class="{ 'error': form.username.error.status }">
          <input class="form-control form-control--lg" type="text" :placeholder="form.username.label" v-model="form.username.value">
          <div class="form-error"> {{ form.username.error.message }} </div>
        </div>
        <div class="form-group" :class="{ 'error': form.password.error.status }">
          <input class="form-control form-control--lg form-control--password" type="password" :placeholder="form.password.label" v-model="form.password.value">
          <span class="password-watch"></span>
          <div class="error-text"> {{ form.password.error.message }} </div>
        </div>
        <transition name="slide-fade">
          <div class="error-text" v-if="errorMessage != ''"> {{ errorMessage }} </div>
        </transition>
        <button type="submit" class="btn btn--lg btn--default btn--inline" style="margin-top: 15px;">
          <div class="btn--text"> Войти </div>
          <div class="btn--loader"></div>
        </button>
      </form>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'PageLogin',
    data: () => ({
      errorMessage: '',
      form: {
        username: {
          label: 'Логин',
          value: 'test2@test.ru',
          required: {
            status: true,
            message: 'Заполните поле',
          },
          validate: [
            {regexp: /.{5,}/, msg: 'Минимум 5 символов'}, 
          ],
          error: {
            status: false,
            message: null,
          }
        },
        password: {
          label: 'Пароль',
          value: 'T/l6^D=',
          required: {
            status: true,
            message: 'Заполните поле',
          },
          validate: [
            {regexp: /.{6,}/, msg: 'Минимум 6 символов'}, 
          ],
          error: {
            status: false,
            message: null,
          }
        },
      }
    }),
    watch: {
      'form.username.value': function () {
        this.form.username.error.status = false;
      },
      'form.password.value': function () {
        this.form.password.error.status = false;
      },
    },

    methods: {
      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },
      async onSubmit (e) {
        let btn = e.submitter;
        btn.classList.add('btn--load');
        this.errorMessage = '';
        // Валидация формы, готовность к отправке
        let isReady = true; //this.$helpers.validateFields(this.form);
        if(isReady) {
          const username = this.form.username.value;
          const password = this.form.password.value;
          const isAuth = await this.$store.dispatch('login', { username, password });
          if (isAuth) {
            this.$router.push({ name: 'PageHome' });
          } else {
            this.errorMessage = 'Неверный логин или пароль';
          }
        }
        btn.classList.remove('btn--load');
      },
    },
  }
</script>

<style scoped>

  .auth {
    height: 100dvh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }

  .auth__form {
    background-color: var(--cozh-white);
    padding: 40px;
    width: 550px;
    box-shadow: 0px 25px 75px rgba(11, 40, 54, 0.15);
    border-radius: 12px;
  }

  .auth__title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
    text-align: center;
  }

  .form-group {
    margin-bottom: 10px;
  }

  @media (max-width: 767px) {
    .auth__form {
      padding: 20px;
    }
    .auth__title {
      font-size: 20px;
      margin-bottom: 15px;
    }
  }
</style>