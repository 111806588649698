<template>

	<TopMenu :headerText="null" />

  <Breadcrumbs :breadcrumbs="breadcrumbs" />
  <MessageDialogue ref="messageDialogue" />
  <ConfirmDialogue ref="confirmDialogue" />

	<div class="block">
  	<div class="block__body">
    	<div class="content">
      		<div class="content__left">
      			<template v-if="isEditBase">
	            <div class="field">
	            	<div class="row middle-xs">
	            		<div class="col-xs-12 col-sm-12 col-md-5">
	            			<div class="label"> Введите полный адрес объекта </div>
	            		</div>
	            		<div class="col-xs-12 col-sm-12 col-md-7">
	            			<DadataPicker label="Начните ввод" type="address" @setValue="setValue"/>
	            		</div>
	            	</div>
	            </div>
	        		<hr />
      			</template>
      			<template v-if="!isEditBase">
      				<div class="hidden-xs">
		            <div class="field">
									<div class="c-title" v-if="data !== null"> {{ $helpers.mkd_address(data) }} </div>
		            </div>
		        		<hr />
      				</div>
      			</template>
            <div class="c-dadata">
	            <div class="field">
	            	<div class="row">
	            		<div class="col-xs-6 col-sm-6 col-md-5">
	            			<div class="label"> Регион </div>
	            		</div>
	            		<div class="col-xs-6 col-sm-6 col-md-7">
	            			<div class="c-value"> {{ mkd.region_name == null ? '-' : mkd.region_name }} </div>
	            		</div>
	            	</div>
	            </div>
	            <div class="field">
	            	<div class="row">
	            		<div class="col-xs-6 col-sm-6 col-md-5">
	            			<div class="label"> Город </div>
	            		</div>
	            		<div class="col-xs-6 col-sm-6 col-md-7">
	            			<div class="c-value"> {{ mkd.city_name == null ? '-' : mkd.city_name }} </div>
	            		</div>
	            	</div>
	            </div>
	            <div class="field">
	            	<div class="row">
	            		<div class="col-xs-6 col-sm-6 col-md-5">
	            			<div class="label"> Улица </div>
	            		</div>
	            		<div class="col-xs-6 col-sm-6 col-md-7">
	            			<div class="c-value"> {{ mkd.mkd_street == null ? '-' : mkd.mkd_street }} </div>
	            		</div>
	            	</div>
	            </div>
	            <div class="field">
	            	<div class="row">
	            		<div class="col-xs-6 col-sm-6 col-md-5">
	            			<div class="label"> Дом </div>
	            		</div>
	            		<div class="col-xs-6 col-sm-6 col-md-7">
	            			<div class="c-value"> {{ mkd.mkd_building == null ? '-' : mkd.mkd_building }} </div>
	            		</div>
	            	</div>
	            </div>
	            <div class="field">
	            	<div class="row">
	            		<div class="col-xs-6 col-sm-6 col-md-5">
	            			<div class="label"> Координаты объекта </div>
	            		</div>
	            		<div class="col-xs-6 col-sm-6 col-md-7">
	            			<div class="c-value"> {{ mkd.lat == null || mkd.long == null ? '-' : mkd.lat + ', ' + mkd.long }} </div>
	            		</div>
	            	</div>
	            </div>
            </div>
      		</div>
      		<div class="content__right">
    				<ImagePicker v-model="mkd.image" :viewMode="!isEditBase" />
      		</div>
    	</div>
    </div>
    <div class="block__footer" v-if="id_role == 0">
    	<a class="btn btn--default btn--md" @click="isEditBase = true" v-if="!isEditBase">
          <div class="btn--text"> Редактировать </div>
          <div class="btn--loader"></div>
      </a>
      <div class="btn-group" v-if="isEditBase">
        <a class="btn btn--default btn--md me-1" :class="{'disabled': isDisabledSave}" @click="saveBaseInfo($event)">
            <div class="btn--text">Сохранить</div>
            <div class="btn--loader"></div>
        </a>
        <a class="btn btn--outline btn--md" @click="cancelEdit">
            <div class="btn--text">Отменить</div>
            <div class="btn--loader"></div>
        </a>
      </div>
  	</div>
	</div>

  <div class="block mt-1 mb-1">
    <div class="block__header block__header--btn">
      Обследования
      <router-link class="btn btn--grey btn--sm" :to="{name: 'PageCheckupCreate', params: {mkd_id: mkd_id}}">
        <img class="btn--ico btn--ico--left" src="@/assets/img/plus-blue-small.svg">
        <div class="btn--text"> Добавить  <span class="hidden-xs"> обследование </span> </div>
      </router-link>
    </div>
    <div class="block__body">
      <grid-table :options="options">
        <template v-slot:default="{ item, reload }">
          <tr>
            <td v-if="id_role == 0" width="1px">{{item.id}}</td>
            <td v-if="id_role == 0">
            	<router-link class="link-default" :to="{name: 'PageOrganization', params: {organization_id: item.id_organization}}">
                <span> {{item.organization_name}} </span>
              </router-link>
            </td>
            <td>
              <div class="clock">
                <div class="clock__item">
                  <router-link :to="{name: 'PageCheckupCustomer', params: { mkd_id: item.id_mkd, checkup_id: item.id }}" class="link-default">
                    <img class="btn--ico--left" src="@/assets/img/min-calendar-grey.svg">
                    <span> {{ $moment(item.create_at).format('DD.MM.YYYY') }} </span>
                  </router-link>
                </div>
                <div class="clock__item">
                  <img class="btn--ico--left" src="@/assets/img/min-clock-grey.svg">
                  {{ $moment(item.create_at).format('HH:mm:ss') }}
                </div>
              </div>
            </td>
            <td class="nowrap">
              {{ item.number }}
            </td>
            <td style="min-width: 350px;">
              {{ item.customer }} (ИНН: {{ item.customer_inn }})
            </td>
            <td>
              <span v-if="item.id_checkup_status == 1"> В работе </span>
              <span v-if="item.id_checkup_status == 2"> Завершен </span>
            </td>
            <td style="width: 1px;" v-if="id_role == 0">
              <a class="link-default ms-3 fs-12" @click="deleteCheckup($event, item.id, reload)">
                <img class="btn--ico btn--ico--left" src="@/assets/img/cross-blue.svg">
                <span class="btn--text"> Удалить </span>
                <div class="btn--loader"></div>
              </a>
            </td>
          </tr>
        </template>
      </grid-table>
    </div>
	</div>

	<AdditionalInfo :mkd_id="mkd_id" />

  <div class="block mt-1">
      <div class="block__footer" style="border-top: none;">
        <a class="btn btn--outline btn--md" @click="$router.go(-1)">
        	<img class="btn--ico btn--ico--left" src="@/assets/img/6.svg">
            <div class="btn--text">Вернуться</div>
            <div class="btn--loader"></div>
        </a>
    </div>
  </div>

</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue'
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
  import ImagePicker from '@/components/ux/ImagePicker.vue'
  import DadataPicker from '@/components/ux/DadataPicker.vue'
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
  import GridTable from '@/components/ux/GridTable.vue';
  import AdditionalInfo from '@/components/object/AdditionalInfo.vue'
</script>

<script>
	export default {
		name: 'PageObjectsView',

		data: () => ({
			breadcrumbs: [],
			data: null,
			organizations: [],
		
    	options: {
        method: 'getCheckups',
        display: 5,
        fields: [
          {key:'create_at', name:'Дата / время'},
          {key:'number', name:'№ договора'},
          {key:'customer', name: 'Заказчик'},
          {key:'id_checkup_status', name: 'Статус'},
        ],
        filters: [
          {key: 'number', type: 'text'},
          {key: 'id_checkup_status', type: 'select', list: [{id: 1, name: 'В работе'}, {id: 2, name: 'Завершен'}]},
        ],
        defaultFilter: [],
      },

			mkd: {
		  	region_name: null,
		  	city_name: null,
		  	mkd_street: null,
		  	mkd_building: null,
		  	lat: null,
		  	long: null,
		  	image: null,
			},

			required: ['region_name', 'city_name', 'mkd_street', 'mkd_building', 'lat', 'long'],
			
			isEditBase: false,
    
    }),

    created () {

      // Чекапы конкретного дома
      this.options.defaultFilter.push({key: 'id_mkd', value: this.mkd_id});

      // Для рута
      if(this.id_role == 0) {

        // Выводим ID
        this.options.fields.splice(0, 0, {key: 'id', name: 'ID'})
        // Выводим Организацию
        this.options.fields.splice(1, 0, {key: 'id_organization', name:'Организация'})
        // Выводим пустой для управления
        this.options.fields.splice(6, 0, {key: false, name:''})

        // Фильтр по организациям
        this.options.filters.splice(1, 0, { key: 'id_organization', type: 'select', list: [] });

        Promise.all([this.loadOrganizations()])
          
          .then(() => {
            // Данные для фильтра по организациям
            this.options.filters[1].list = this.organizations;
          })

          .catch(()=> {})
      
      }

      Promise.all([this.loadMkd()])

        .then(() => {
          // Данные для фильтра по организациям
          this.beforeRender();
        })

        .catch(() => {})

    },

    computed: {
    	mkd_id () {
	      return this.$route.params.mkd_id;
	    },
	    id_role () {
      	return this.$store.state.auth.user.profile.id_role;
    	},
    	isDisabledSave () {
    		for(let key in this.required) {
    			if(this.mkd[this.required[key]] == null) {
    				return true;
    			}
    		}
    		return false;
    	},
    },
    
    	methods: {
    		beforeRender () {

  				this.breadcrumbs = [
	        	{name: this.$helpers.mkd_address(this.data), link: ''},
		    	];

    			if(this.id_role == 2) {
		    		// Для обследователя - на главную
		        this.breadcrumbs.unshift({name: 'Главная', link: {name: 'PageHome'}});
    			} else {
		    		// Для всех остальных на объекты
		        this.breadcrumbs.unshift({name: 'Объекты', link: {name: 'PageObjects'}});
    			}

    		},
    		cancelEdit () {
    			this.isEditBase = false; 
    			this.mkd = {... this.data};
    		},

	    	async loadOrganizations () {
	        const { items } = await this.$store.dispatch('getOrganizations', {display: 1000, filter: {}, order: {}, page: 1});
	        this.organizations = items;
	      },

    		async loadMkd () {
    			this.data = await this.$store.dispatch('getMkd', {id_mkd: this.mkd_id});
					this.mkd = {... this.data};
    		},

    		setValue (item) {
    			if(item !== null) {
    				this.mkd.region_name = item.data.region;
    				this.mkd.city_name = item.data.city;
    				this.mkd.mkd_street = item.data.street;
    				this.mkd.mkd_building = item.data.house;
    				this.mkd.lat = item.data.geo_lat;
    				this.mkd.long = item.data.geo_lon;
    			}
    		},

    		async saveBaseInfo (e) {
    			const btn = e.currentTarget;
	        btn.classList.add('btn--load');
	        const param = {
	        	id: this.mkd_id,
	        	city: this.mkd.city_name,
	        	mkd_street: this.mkd.mkd_street,
	        	mkd_building: this.mkd.mkd_building,
	        	lat: this.mkd.lat,
	        	long: this.mkd.long,
	        	image: this.mkd.image,
	        }
    			try {
	        	const data = await this.$store.dispatch('updateMkd', param);
	        	this.data = data;
						this.mkd = {... this.data};
	          this.$refs.messageDialogue.show({message: 'Данные сохранены', okButton: 'Подтвердить'});
	          this.isEditBase = false;
	        } catch (e) {
	          this.$refs.messageDialogue.show({message: e.message, okButton: 'Подтвердить'});
	        }
		      btn.classList.remove('btn--load');
    		},

    		async deleteCheckup (e, id, reload) {
	        const btn = e.currentTarget;
	        btn.classList.add('btn--load');
	        const confirm = await this.$refs.confirmDialogue.show({
	          message: 'Вы уверены, что хотите удалить это обследование?',
	          okButton: 'Удалить',
	        });
	        if(confirm) {
	          try {
	            await this.$store.dispatch('deleteCheckup', {id_checkup: id});
	            reload();
	          } catch (e) {
	              this.$refs.messageDialogue.show({message: e.message, okButton: 'Подтвердить'});
	          }
	        }
	        btn.classList.remove('btn--load');
		    },

    		
    	},
	}
</script>

<style scoped>

	.block__footer {
		/*justify-content: flex-start;*/
	}
  	.content {
    	display: flex;
      	flex-direction: row;
 	}
  	.content__right {
     	padding: 20px;
      	border-left: 1px solid var(--cozh-light-grey);
      	min-width: 300px;
  	}
  	.content__left {
      	width: 100%;
      	padding: 20px 0px;
      	padding-bottom: 10px;
  	}

  	.field:not(:last-child) {
    	margin-bottom: 20px;
  	}

  	.field {
  		padding: 0px 20px;
  	}

  	.label {
  		margin-bottom: 0px;
  	}
  	.c-value {
  		font-size: 13px;
    	font-weight: 500;
  	}
  	.c-title {
	    padding: 8px 0px;
	  
	    font-size: 18px;
    	font-weight: 600;
  	}
  	
  	.clock {
	    display: flex;
	    flex-direction: row;
	    align-items: center;
	    justify-content: flex-start;
	  }
	  .clock__item {
	    display: flex;
	    flex-direction: row;
	    align-items: center;
	    justify-content: flex-start;
	  }
	  .clock__item:first-child {
	    margin-right: 20px;
	  }

	@media (max-width: 992px) {
		.label {
		  margin-bottom: 6px;
		}
		.c-dadata .label {
    	color: var(--cozh-dark-grey);
		}
		.field:not(:last-child) {
    	margin-bottom: 10px;
  	}
  	.c-title {
	    padding: 0px;
  	}
	}

	@media (max-width: 767px) {
	  	.content {
	  		flex-direction: column;
		}
	  	.content__right {
	  		border-left: none;
	  		border-top: 1px solid var(--cozh-light-grey);
	  	}
	  	.btn-group {
	  		width: 100%;
	  	}
	  	.block__footer a {
	  		width: 100%;
	  	}
	}
</style>