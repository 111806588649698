<template>

  <TopMenu :headerText="null" />
  <MessageDialogue ref="messageDialogue" />
  <ConfirmDialogue ref="confirmDialogue" />

  <!-- Договоры -->
  <div class="block mt-1">
    <div class="block__header block__header--btn">
      Договоры
      <router-link :to="{name: 'PageContractsCreate'}" class="btn btn--grey btn--sm" v-if="id_role == 0">
        <img class="btn--ico btn--ico--left" src="@/assets/img/plus-blue-small.svg">
        <div class="btn--text"> Добавить  <span class="hidden-xs"> договор </span> </div>
      </router-link>
    </div>
    <div class="block__body">
      <grid-table :options="options">
        <template v-slot:default="{ item, reload }">
          <tr>
            <td width="1px" v-if="id_role == 0">{{item.id}}</td>
            <td>
              <span class="nowrap"> {{$moment(item.date).format("DD MMMM YYYY")}} </span>
            </td>
            <td>
              <span class="nowrap"> {{item.number}} </span>
            </td>
            <td>
              {{item.number_licenses}}
            </td>
            <td>
              {{item.available_count}}
            </td>
            <td>
              <a class="link-default" :href="item.file" target="blank"> 
                <span> 
                  {{ 'Договор №' + item.number + ' от ' + $moment(item.date).format("DD.MM.YYYY") }} 
                </span>
              </a>
            </td>
            <td v-if="id_role == 0">
              <router-link class="link-default" :to="{name: 'PageOrganization', params: {organization_id: item.id_organization}}">
                <span> {{item.organization_name}} </span>
              </router-link>
            </td>
            <td style="width: 1px;" v-if="id_role == 0">
              <div class="c-filed-row">
                <router-link class="link-default fs-12" :to="{name: 'PageContractsEdit', params: {contract_id: item.id}}">
                  <img class="btn--ico btn--ico--left" src="@/assets/img/edit-blue.svg" style="margin-bottom: 2px;">
                  <span class="btn--text"> Изменить </span>
                  <div class="btn--loader"></div>
                </router-link>
                <a class="link-default ms-3 fs-12" @click="deleteContract($event, item.id, reload)">
                  <img class="btn--ico btn--ico--left" src="@/assets/img/cross-blue.svg">
                  <span class="btn--text"> Удалить </span>
                  <div class="btn--loader"></div>
                </a>
              </div>
            </td>

          </tr>
        </template>
      </grid-table>
    </div>
  </div>

</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue'
  import GridTable from '@/components/ux/GridTable.vue';
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
</script>

<script>
    export default {
      name: 'PageContracts',
      data: () => ({
        options: {
          method: 'getContracts',
          display: 10,
          fields: [
            {key:'date', name:'Дата'},
            {key:'number', name:'№ договора'},
            {key:'number_licenses', name:'Всего <br /> лицензий'},
            {key:'available_count', name:'Доступно <br /> обследований'},
            {key:'file', name:'Подписанный документ'},
          ],
          filters: [
            {key: 'number', type: 'text'},
          ],
          organizations: [],
        },
      }),
      created () {

        // Для рута
        if(this.id_role == 0) {
          
          // Выводим ID
          this.options.fields.splice(0, 0, {key: 'id', name: 'ID'})

          // Выводим Организацию
          this.options.fields.splice(6, 0, {key: 'id_organization', name:'Организация'})

          // Выводим пустой для управления
          this.options.fields.splice(7, 0, {key: false, name:''});

          // Фильтр по организациям
          this.options.filters.splice(1, 0, { key: 'id_organization', type: 'select', list: [] });

          Promise.all([this.loadOrganizations()])
          
          .then(() => {
            // Данные для фильтра по организациям
            this.options.filters[1].list = this.organizations;
          })

          .catch(()=> {})
        }

      },
      computed: {
        id_role () {
          return this.$store.state.auth.user.profile.id_role;
        },
      },
      methods: {
        async loadOrganizations () {
          const { items } = await this.$store.dispatch('getOrganizations', {display: 1000, filter: {}, order: {}, page: 1});
          this.organizations = items;
        },

        async deleteContract (e, id, reload) {
          const btn = e.currentTarget;
          btn.classList.add('btn--load');
          const confirm = await this.$refs.confirmDialogue.show({
            message: 'Вы уверены, что хотите удалить этот договор?',
            okButton: 'Удалить',
          });
          if(confirm) {
            try {
              await this.$store.dispatch('deleteContract', {id: id});
              reload();
            } catch (e) {
              this.$refs.messageDialogue.show({message: this.$helpers.getErrorMessage(e), okButton: 'Подтвердить'});
            }
          }
          btn.classList.remove('btn--load');
        },


      },
    }
</script>

<style scoped>
  .c-filed-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
  }
</style>