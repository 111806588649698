<template>

	<TopMenu :headerText="null" />
  <Breadcrumbs :breadcrumbs="breadcrumbs" />
	<CheckupNavigator />
	<MessageDialogue ref="messageDialogue" />
	<ConfirmDialogue ref="confirmDialogue" />

	<div class="load-container" :class="{'load' : isLoad}">
    <div class="content" v-if="!isLoad && !isError">

	    <div class="block mb-1 block--first">
	    	<div class="block__header">
	    		{{ path.name }}
	    	</div>
	    	<div class="block__body">
	    		<div class="field">
	    			<div class="row">
		    			<div class="col-xs-12 col-sm-6 col-md-6">
		    				<div class="label">Общее количество участков на схеме</div>
		    			</div>
		    			<div class="col-xs-12 col-sm-6 col-md-6">
		    				<input class="form-control" type="text" v-model="data.count_regions" placeholder="Общее количество участков на схеме">
		    			</div>
		    		</div>
	    		</div>
	    		<div class="field" v-if="schemes.length > 0">
		    		<div class="row">
		    			<div class="col-xs-12 col-sm-6 col-md-6">
		    				<div class="label">Схемы</div>
		    			</div>
		    			<div class="col-xs-12 col-sm-6 col-md-6">
		    				<GalleryPicker v-model="schemes" size="xs" viewMode="true" />
		    			</div>
		    		</div>
	    		</div>
	    	</div>
	    </div>

      <div class="collapse" v-for="(fragment, index) in regions" :key="index">
        <div class="collapse__header js-collapse">
          <div class="collapse__title">
            Единичный фрагмент {{ index + 1 }} 
          </div>
          <div class="collapse__buttons">
            <a class="btn btn--outline btn--sm" @click.prevent="deleteRegionByElement($event, fragment, index)">
              <div class="btn--text"> Удалить <span class="hidden-xs"> фрагмент </span> </div>
              <div class="btn--loader"></div>
            </a>
          </div>
        </div>
        <div class="collapse__content">
        	<div class="field">
	    			<div class="row">
		    			<div class="col-xs-12 col-sm-6 col-md-6">
		    				<div class="label">Индекс единичного фрагмента</div>
		    			</div>
		    			<div class="col-xs-12 col-sm-6 col-md-6">
		    				<input class="form-control" type="text" v-model="fragment.index_region" placeholder="Индекс единичного фрагмента">
		    			</div>
		    		</div>
	    		</div>
	    		<div class="field">
	    			<div class="row">
		    			<div class="col-xs-12 col-sm-6 col-md-6">
		    				<div class="label">Примечание</div>
		    			</div>
		    			<div class="col-xs-12 col-sm-6 col-md-6">
		    				<input class="form-control" type="text" v-model="fragment.comment" placeholder="Примечание">
		    			</div>
		    		</div>
	    		</div>
	    		<div class="field">
		    		<div class="row">
		    			<div class="col-xs-12 col-sm-6 col-md-6">
		    				<div class="label">Фото</div>
		    			</div>
		    			<div class="col-xs-12 col-sm-6 col-md-6">
		    				<GalleryPicker v-model="fragment.images" />
		    			</div>
		    		</div>
	    		</div>
	    		<div class="field mt-2">
		    		<div class="label">Выберите повреждения:</div>
		    		<CheckboxesPicker :list="defects" :label="null" v-model="fragment.defects"/>
	    		</div>
        </div>
      </div>

	    <div class="block">
	    	<div class="block__footer">
	    		<div class="btn-group">
		        <router-link class="btn btn--outline btn--md me-1" :to="{
		        	name: 'PageCheckupElements', 
		        	params: {
		        		mkd_id: mkd_id, 
		        		checkup_id: checkup_id, 
		        		module_id: module_id
		        	}
		        }">
		        	<img class="btn--ico btn--ico--left" src="@/assets/img/6.svg">
		        	<div class="btn--text">Вернуться</div>
		        </router-link>
		        <a class="btn btn--default btn--md" @click="patchRegionByElement($event)">
		        	<img class="btn--ico btn--ico--left" src="@/assets/img/check-white.svg">
	          		<div class="btn--text">Сохранить</div>
	          		<div class="btn--loader"></div>
		        </a>
	    		</div>
    			<a class="btn btn--default btn--md" @click="createRegionByElement($event)">
	        	<IconPlus class="btn--ico btn--ico--left" />
        		<div class="btn--text">Добавить <span class="hidden-xs">единичный</span> фрагмент</div>
        		<div class="btn--loader"></div>
	        </a>
	      </div>
	  	</div>

		</div>
  	<ErrorContent v-else/>
	</div>

</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue';
  import CheckupNavigator from '@/components/checkups/CheckupNavigator.vue';
  import ErrorContent from '@/components/ErrorContent.vue';
  import GalleryPicker from '@/components/ux/GalleryPicker.vue';
  import CheckboxesPicker from '@/components/ux/CheckboxesPicker.vue';
  import IconPlus from '@/components/icons/IconPlus-b1.5_w8_h8_white.vue';
  import Breadcrumbs from '@/components/Breadcrumbs.vue'

  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
</script>

<script>
	export default {
		name: 'PageCheckupElement',
		data: () => ({
    	isLoad: true,
    	isError: false,
	    breadcrumbs: [],

    	info: null,
    	path: null,

    	data: null,
    	defects: null,

    	regions: [],
		}),
		created () {
	      Promise.all([
	      	this.loadInfo(), 
	      	this.loadPath(), 
	      	this.loadData(), 
	      	this.loadDefects()])
	      	.then(() => {
	        	this.beforeRender();
	        	this.isLoad = false;
	      	})
	      	.catch((e)=> {
	        	console.log(e);
	        	this.isError = true;
	        	this.isLoad = false;
	      	})
	    },

		computed: {
			mkd_id () {
      	return this.$route.params.mkd_id;
    	},
			checkup_id () {
      	return this.$route.params.checkup_id;
    	},
    	module_id () {
      	return this.$route.params.module_id;
    	},
    	id_role () {
  			return this.$store.state.auth.user.profile.id_role;
  		},
    	element_id () {
      	return this.$route.params.element_id;
    	},
    	schemes () {
    		const schemes = [];
				this.data.images.forEach((img) => {
      		schemes.push(img.path); 
      	});
      	return schemes;
    	}
		},

		methods: {
			beforeRender() {

      	// to do пушим regions
        this.data.regions.forEach((fragment) => {
        	let defects = [];
        	fragment.defects.forEach((defect) => {
        		if(defect.checked) {
        			defects.push(defect.id);
        		}
        	});
          this.regions.push({
	    			id: fragment.id,
	      		index_region: fragment.index_region,
	      		comment: fragment.comment,
	      		images: fragment.images ? fragment.images : [],
	      		defects: defects,
	    		});
        });
        this.breadcrumbs = [
          {name: this.info.address, link: {name: 'PageObjectsView', params: {mkd_id: this.mkd_id}}},
          {
          	name: 'Обследование №' + this.info.checkup_id + ' от ' + this.$moment(this.info.contract_date).format('DD.MM.YYYY'), 
          	link: {name:'PageCheckupCustomer', params: {mkd_id: this.mkd_id, checkup_id: this.checkup_id}}
          },
    			{name: 'Модули', link: { name: 'PageCheckupModules', params: {mkd_id: this.mkd_id, checkup_id: this.checkup_id}}},
        	{name: this.path.module.name, link: {name: 'PageCheckupElements', params: {mkd_id: this.mkd_id, checkup_id: this.checkup_id, module_id: this.module_id}}},
        	{name: this.path.name, link: ''},
        ];

        if(this.id_role == 2) {
          // Для обследователя - на главную
          this.breadcrumbs.unshift({name: 'Главная', link: {name: 'PageHome'}});
        } else {
          // Для всех остальных на объекты
          this.breadcrumbs.unshift({name: 'Объекты', link: {name: 'PageObjects'}});
        }
	        
    	},

    	async createRegionByElement (e) {
    		const btn = e.currentTarget;
    		btn.classList.add('btn--load');
    		try {
        	const region = await this.$store.dispatch('createRegionByElement', {id_checkup: this.checkup_id, id_element: this.element_id});
     	 		this.regions.push({
	    			id: region.id,
	      		index_region: null,
	      		comment: null,
	      		images: [],
	      		defects: [],
	    		});
    		} catch (e) {
      		this.$refs.messageDialogue.show({ message: e.message, okButton: 'Подтвердить' });
    		}
    		btn.classList.remove('btn--load');
    	},

    	async deleteRegionByElement (e, fragment, index) {
    		const btn = e.currentTarget;
  			// Схема Уже есть в БД
  			const confirm = await this.$refs.confirmDialogue.show({
        	message: 'Вы уверены, что хотите удалить этот фрагмент?',
        	okButton: 'Удалить',
      	});	
     	 	if(!confirm) { return; }
    		btn.classList.add('btn--load');
   	 		try {
   	 			await this.$store.dispatch('deleteRegionByElement', {id_region: fragment.id});
					this.regions.splice(index, 1);
   	 		} catch (e) {
      		this.$refs.messageDialogue.show({ message: e.message, okButton: 'Подтвердить' });
   	 		}
    		btn.classList.remove('btn--load');
    	},

    	async patchRegionByElement (e) {
    		const btn = e.currentTarget;
    		const regions = [];
  			// Пушим на вставку не пустые
    		this.regions.forEach((fragment) => {
    			const defects = []
    			// Смотрим оригинальный список дефектов
    			this.defects.forEach((defect) => {
    				let d = { ... defect };
    				const idx = fragment.defects.findIndex((id) => { return id == d.id });
    				// Данный дефект есть в списке отмеченных у фрагмента
    				d.checked = (idx != -1) ? true : false;
    				defects.push(d);
    			})
    			regions.push({
        		id: fragment.id,
	      		index_region: fragment.index_region,
	      		comment: fragment.comment,
	      		images: fragment.images,
	      		defects: defects,
    			});
    		})
  			btn.classList.add('btn--load');
   	 		try {
   	 			await this.$store.dispatch('patchRegionByElement', {
	    			id_checkup: this.checkup_id, 
	    			id_element: this.element_id,
	    			count_regions: this.data.count_regions,
	    			regions: regions,
	    		});
        	this.$refs.messageDialogue.show({ message: 'Данные сохранены', okButton: 'Подтвердить' });
   	 		} catch (e) {
      		this.$refs.messageDialogue.show({ message: e.message, okButton: 'Подтвердить' });
   	 		}
  			btn.classList.remove('btn--load');
    	},
    	async loadInfo () {
    		this.info = await this.$store.dispatch('getInfoByCheckup', {id_checkup: this.checkup_id});
    	},
    	async loadPath () {
        this.path = await this.$store.dispatch('getPath', {id_element: this.element_id});
    	},
    	async loadData () {
        this.data = await this.$store.dispatch('getRegionsByElement', {id_element: this.element_id, id_checkup: this.checkup_id, id_module: this.module_id });
	    },
    	async loadDefects () {
        this.defects = await this.$store.dispatch('getDefectsByElement', {id_element: this.element_id});
	    },
		}
	}
</script>

<style scoped>
	.block--first {
    border-radius: 0px 0px 8px 8px;
	}
	.block__footer {
		border: none;
  	justify-content: space-between;
	}
	.block__body {
		padding: 20px;
	}
	.collapse {
		background-color: #fff;
		border: none;
		margin-bottom: 10px;
	}
	.collapse__content {
    border-top: 1px solid var(--cozh-light-grey);
		padding: 20px;
	}
	.label {
	    margin-top: 11px;
	}
	.field:not(:last-child) {
		margin-bottom: 10px;
	}

  @media (max-width: 767px) {
		.label {
    	margin-top: 0px;
    	margin-bottom: 8px;
		}
		.block__footer {
			flex-direction: column-reverse;
		}
		.btn-group {
			width: 100%;
			margin-top: 10px;
		}
		.block__footer a {
			width: 100%;
		}
  }

</style>