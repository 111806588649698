<template>
	<div class="component">
		<div class="component__left">
			<div class="block">
				<div class="block__header">
					Организации

					<a class="link-default-dashed ms-3" @click="selected = []" v-if="selected.length > 0">
						<img class="btn--ico btn--ico--left" src="@/assets/img/cross-blue.svg">
						<span class="btn--text"> Сбросить выбор </span>
					</a>

				</div>
				<div class="block__body">
					<div 
						v-for="org in data" 
						class="c-filter"
						:class="{'selected': selected.includes(org.organization_id)}"
						:key="org.organization_id" 
						@click="selectOrg(org.organization_id)">
						<div class="c-filter__container">
							<div class="c-filter__badge" :style="{backgroundColor: org.color.color}">
								{{ org.mkds.length }}
							</div>
							<div class="c-filter__name">
								{{ org.organization_name }}
							</div>
						</div>
						<div class="c-filter__label">
							<svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1.5 5.5L3.5 7.5L9.5 1.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="component__right">
			
			<yandex-map 
				ref="mapsapps"
				style="width: 100%; height: 100%"
				v-if="true"
				:settings="settings" 
				:coordinates="[58.013612, 56.20103]"
				@geo-objects-updated="mapUpdate"
				:zoom="12">

				<template v-for="org in data">
					<template v-if="selected.length == 0 || selected.includes(org.organization_id)">

						<yandex-marker 
							v-for="item in org.mkds"
							:coordinates="[item.mkd_lat, item.mkd_long]" 
							:options="{preset: org.color.dot}"
							:marker-id="item.id" 
							:key="item.id"
							:properties="{hintContent: item.mkd_street + ' ' + item.mkd_building }"
							type="Point">

							<template #component>
								<div class="c-baloon">
									<div class="c-baloon__header">
										{{ item.city_name }}, ул. {{ item.mkd_street }}, {{ item.mkd_building}}
									</div>
									<div class="c-baloon__body">
										<div class="c-baloon__item" v-if="item.checkup_date">
											<div class="row">
												<div class="col-xs-6 col-sm-8 col-md-8">
													<div class="c-baloon__label">
														Дата последнего обследования
													</div>
												</div>
												<div class="col-xs-6 col-sm-4 col-md-4">
													<div class="c-baloon__value">
														{{ $moment(item.checkup_date).format('DD.MM.YYYY') }}
													</div>
												</div>
											</div>
										</div>
										<div class="c-baloon__item">
											<div class="row">
												<div class="col-xs-6 col-sm-8 col-md-8">
													<div class="c-baloon__label">
														Организация
													</div>
												</div>
												<div class="col-xs-6 col-sm-4 col-md-4">
													<div class="c-baloon__value">
														{{ org.organization_name }}
													</div>
												</div>
											</div>
										</div>
										<div class="c-baloon__item">
											<div class="row">
												<div class="col-xs-6 col-sm-8 col-md-8">
													<div class="c-baloon__label">
														Всего обследований
													</div>
												</div>
												<div class="col-xs-6 col-sm-4 col-md-4">
													<div class="c-baloon__value">
														{{ item.checkup_count }}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</template>
						</yandex-marker>
					</template>
				</template>
			</yandex-map>
		</div>
	</div>
</template>


<script setup>
  import { YandexMap, YandexMarker } from 'vue-yandex-maps/dist/vue-yandex-maps.esm';
</script>

<script>
  export default {
    
    name: 'RootMap',

    data: () => ({
			data: [],
			selected: [],
			settings: {
			  apiKey: '59cbac06-aec3-4721-bc5a-6cf5e1d7be57',
			  lang: 'ru_RU',
			  coordorder: 'latlong',
			  debug: false,
			  version: '2.1',
			},
			colors: [
				{dot: 'islands#blueCircleDotIcon', color: '#1e98ff'},
				{dot: 'islands#darkGreenCircleDotIcon', color: '#1bad04'},
				{dot: 'islands#redCircleDotIcon', color: '#ed4543'},
				{dot: 'islands#violetCircleDotIcon', color: '#b51eff'},
				{dot: 'islands#darkOrangeCircleDotIcon', color: '#e6761b'},
				{dot: 'islands#blackCircleDotIcon', color: '#595959'},
				{dot: 'islands#nightCircleDotIcon', color: '#0f4779'},
				{dot: 'islands#yellowCircleDotIcon', color: '#ffd21f'},
				{dot: 'islands#darkBlueCircleDotIcon', color: '#177bc9'},
				{dot: 'islands#greenCircleDotIcon', color: '#56db40'},
				{dot: 'islands#pinkCircleDotIcon', color: '#f371d1'},
				{dot: 'islands#orangeCircleDotIcon', color: '#ff931e'},
				{dot: 'islands#grayCircleDotIcon', color: '#b3b3b3'},
				{dot: 'islands#lightBlueCircleDotIcon', color: '#82cdff'},
				{dot: 'islands#brownCircleDotIcon', color: '#793d0f'},
				{dot: 'islands#oliveCircleDotIcon', color: '#97a101'},
			],
    }),
    
    created () {
    	this.loadMap();
    },


    methods: {

    	mapUpdate (e) {
    		const map = e._map;
    		const points = map.geoObjects.getBounds();
    		if (points !== null) {
	    		map.container.fitToViewport();
	    		map.setBounds(points);
	    		let zoom = map.getZoom();
	    		if(zoom > 16) {
	    			zoom = 16;
	    		}
	       	map.setZoom(zoom, {checkZoomRange: true});
    		}
    	},

    	async loadMap () {
    		const organizations = await this.$store.dispatch('getMap');
    		let lastIndexColor = 0;
    		organizations.forEach((org) => {
    			if (lastIndexColor > 16) { lastIndexColor = 0; }
    			let color = this.colors[lastIndexColor];
    			org.color = color;
    			lastIndexColor++;
    		});
    		this.data = organizations;
    	},

    	selectOrg (id) {
    		const idx = this.selected.findIndex((item) => { return item == id });
    		if(idx !== -1) {
    			this.selected.splice(idx, 1);
    		} else {
    			this.selected.push(id);
    		}
    	},

    }
  }
</script>


<style scoped>

	.component {
    height: calc(100dvh - 110px);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
	}
	.component__left {
		height: 100%;
    width: 100%;
    margin-right: 10px;
    min-width: 360px;
    max-width: 360px;
	}
	.component__right {
		height: 100%;
		width: 100%;
    overflow: hidden;
    border-radius: 12px;
	}



	.block {
    height: 100%;
	}
	.block__body {
		padding: 20px;
	}



	.c-filter {
		border-radius: 6px;
		padding: 8px 10px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
	}
	.c-filter:not(:last-child) {
		margin-bottom: 5px;
	}
	.c-filter__badge {
		height: 24px;
		width: 24px;
		border-radius: 50%;
		background-color: var(--cozh-blue);
		color: #fff;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		font-size: 11px;
		font-weight: 600;
	}
	.c-filter__container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}
	.c-filter__name {
		font-size: 13px;
		margin-left: 10px;
		font-weight: 500;
	}
	.c-filter__label {
		height: 18px;
		width: 18px;
		background-color: var(--cozh-light-grey);
		border-radius: 4px;
		display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
		transition: all .1s ease-out;
	}
	.c-filter__label svg {
		opacity: 0;
		visibility: hidden;
	}
	.c-filter.selected {
		background-color: rgba(var(--cozh-blue-code), .08);
	}
	.c-filter.selected .c-filter__label {
		background-color: var(--cozh-blue);
	}
	.c-filter.selected .c-filter__label svg {
		opacity: 1;
		visibility: visible;
	}




	.c-baloon {
	  width: 100%;
	}
	.c-baloon__header {
		font-size: 16px;
    font-weight: 600;
    font-family: 'Inter';
    width: 100%;
    border-bottom: 1px solid var(--cozh-light-grey);
    padding: 13px 20px;
	}
	.c-baloon__body {
		padding: 20px;
	}
	.c-baloon__item:not(:last-child) {
		margin-bottom: 10px;
	}
	.c-baloon__label {
		font-weight: 500;
    font-size: 12px;
    font-family: 'Inter';
    color: rgba(var(--cozh-black-code), .5);
    line-height: 1.3;
	} 
	.c-baloon__value {
		font-weight: 500;
    font-size: 12px;
    font-family: 'Inter';
    color: rgba(var(--cozh-black-code), 1);
    line-height: 1.3;
	}

	.block__header .link-default-dashed {
		font-size: 12px;
	}

	@media (max-width: 767px) {
		.component {
			flex-direction: column-reverse;
    	height: calc(100dvh - 150px);
    }
		.component__left {
			min-width: 100%;
			max-width: 100%;
			margin-right: 0px;
			margin-top: 10px;
			height: 50%;
		}
		.block__body {
		  padding: 10px;
		  height: calc(100% - 53px);
		  overflow-y: auto;
		}
		.c-filter {
  		padding: 6px 10px;
  	}
  	.c-baloon__item:not(:last-child) {
		    margin-bottom: 5px;
		}
		.c-baloon__header {
		    font-size: 14px;
		    padding: 8px 10px;
		}
		.c-baloon__body {
		    padding: 10px 10px;
		}

	}
</style>

<style>
		@media (max-width: 767px) {
			ymaps[class*="balloon_layout_panel"] {
				margin-bottom: 70px;
	    	width: calc(100% - 30px);
	    	margin-left: 15px;
			}
	}
</style>