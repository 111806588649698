<template>
	<div class="state" :class="{'open': isOpen, 'light': is_light}">
		<div class="state__top">
			
			<div class="state__img" :style="{backgroundImage: mkd.image !== null ? 'url(' + mkd.image + ')' : ''}"></div>

			<div class="state__container">
				<div class="state__left">
					<div class="state__info">
						<div class="state__name">
							{{ mkd.city_name }}, {{ mkd.mkd_street }},&nbsp;{{ mkd.mkd_building }}
						</div>
						<div class="state__date">
							<div class="state__label">
								Дата последнего изменения
							</div>
							<div class="state__group">
								<div class="state__text me-1">
									{{ $moment(mkd.checkup_date).format('DD.MM.YYYY') }}
								</div>
								<router-link
									class="state__link link-default" 
									:to="{ name: 'PageArchiveCheckup', params: { mkd_id: mkd.mkd_id, checkup_id: mkd.last_checkup_id } }"
									v-if="!is_light"> 
									<span> Подробнее </span> 
								</router-link>
							</div>
						</div>
						<div class="state__report" v-if="mkd.report_link">
							<a class="state__link link-default" :href="mkd.report_link" target="blank"> 
								<img class="btn--ico btn--ico--left" src="@/assets/img/copy-white.svg">
								<span> Скачать отчёт  </span> 
							</a>
						</div>
					</div>
					<a class="link-default-dashed state__more-top" @click="isOpen = !isOpen" v-if="!is_light"> 
						<span v-if="!isOpen"> Показать все обследования </span>
						<span v-if="isOpen"> Скрыть все обследования </span>
						<img class="btn--ico btn--ico--right" src="@/assets/img/arror-down-blue.svg">
					</a>
				</div>
				<!--  v-if="mkd.metrics && mkd.metrics.length == 3" -->
				<div class="state__right" v-if="mkd.metrics">
					<div class="state__hint">
						Категории потребности капитального ремонта конструктивных элементов <br class="c-br" /> или внутридомовых инженерных систем
					</div>
					<div class="c-numbers ">
						<div class="c-numbers__item c-numbers--grey">
							<div class="c-numbers__round">
								{{ mkd.metrics[0].value }}
							</div>
							<div class="c-numbers__label">
								Не требуется
							</div>
						</div>
						<div class="c-numbers__item c-numbers--blue">
							<div class="c-numbers__round">
								{{ mkd.metrics[1].value }}
							</div>
							<div class="c-numbers__label">
								требуется
							</div>
						</div>
						<div class="c-numbers__item c-numbers--orange">
							<div class="c-numbers__round">
								{{ mkd.metrics[2].value }}
							</div>
							<div class="c-numbers__label">
								требуется в  <br /> приоритетном порядке
							</div>
						</div>
					</div>
					<a class="link-default-dashed state__more-bottom" @click="isOpen = !isOpen"> 
						<span v-if="!isOpen"> Показать все обследования </span>
						<span v-if="isOpen"> Скрыть все обследования </span>
						<img class="btn--ico btn--ico--right" src="@/assets/img/arror-down-blue.svg">
					</a>
				</div>
			</div>
		</div>
		<div class="state__checkups" v-if="isOpen && !is_light">
			<div class="table-responsive">
                <table class="table-custom">
                  	<thead>
	                    <tr>
	                      <th class="nowrap">Номер</th>
	                      <th class="nowrap">Дата / время</th>
	                      <th class="nowrap">Отчёт</th>
	                      <th></th>
	                    </tr>
                  	</thead>
                  	<tbody>
	                    <tr v-for="(item) in mkd.checkups" :key="item.id">
	                      	<td> {{ item.id }} </td>
	                      	<td>
		                        <div class="clock">
		                          	<div class="clock__item">
			                            <router-link :to="{name: 'PageArchiveCheckup', params: { mkd_id: mkd.mkd_id, checkup_id: item.id }}" class="link-default">
			                              	<img class="btn--ico--left" src="@/assets/img/min-calendar-grey.svg">
			                              	<span> {{ $moment(item.create_at).format('DD.MM.YYYY') }} </span>
			                            </router-link>
		                          	</div>
		                          	<div class="clock__item">
		                              	<img class="btn--ico--left" src="@/assets/img/min-clock-grey.svg">
		                            	{{ $moment(item.create_at).format('HH:mm:ss') }}
		                          	</div>
		                        </div>
	                      	</td>
	                      	<td>
	                      		<a class="link-default fs-12" :href="item.report_link" target="blank" v-if="item.report_link"> 
									<img class="btn--ico btn--ico--left" src="@/assets/img/copy-white.svg">
									<span> Скачать отчёт </span> 
								</a>
								<span v-else>
									-
								</span>
	                      	</td>
	                      	<td width="1px;">
	                      		<router-link class="link-default fs-12" :to="{name: 'PageCheckupCustomer', params: { mkd_id: mkd.mkd_id, checkup_id: item.id }}">
	                      			<img class="btn--ico btn--ico--left" src="@/assets/img/edit-blue.svg" style="margin-bottom: 2px;">
	                      			<span> Редактировать </span>
	                      		</router-link>
	                      	</td>

	                    </tr>
                  	</tbody>
                </table>
            </div>
            <div class="state__more-checkups">
            	<a class="link-default-dashed" @click="isOpen = !isOpen"> 
					<span v-if="!isOpen"> Показать все обследования </span>
					<span v-if="isOpen"> Скрыть все обследования </span>
					<img class="btn--ico btn--ico--right" src="@/assets/img/arror-down-blue.svg">
				</a>
            </div>
		</div>
	</div>
</template>

<script>
  	export default {
    	name: 'MkdState',
    	props: ['mkd', 'is_light'],

    	data: () => ({
    		isOpen: false,
    	}),
   	}
</script>

<style scoped>

	.state {
		background-color: var(--cozh-white);
		border-radius: 8px;
		overflow: hidden;
	}

	.state:not(:last-child) {
		margin-bottom: 10px;
	}

	.state__top {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		align-items: stretch;
	}
	
	.state__img {
		min-width: 240px;
		background-color: var(--cozh-light-grey);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
	.state__container {
    	width: 100%;
    	display: flex;
    	flex-direction: row;
    	align-items: center;
    	justify-content: flex-start;
	}
	.state__left {
		display: flex;
    	flex-direction: column;
    	align-items: flex-start;
    	justify-content: space-between;
    	height: 100%;
    	min-width: 370px;
    	max-width: 370px;
		padding: 25px 30px 25px 30px;
	}

	.state__right {
    	display: flex;
    	flex-direction: column;
    	align-items: flex-start;
    	justify-content: space-between;
    	height: 100%;
    	width: 100%;
    	max-width: 730px;
		padding: 25px 30px 25px 30px;
		border-left: 1px solid var(--cozh-light-grey);
	}

	.state__group {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		margin-top: 3px;
	}
	.state__info {
		margin-bottom: 35px;
	}

	.state__name {
		font-size: 18px;
		font-weight: 600;
		line-height: 1.3;
	}
	.state__link {
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 600;
		letter-spacing: 0.05em;
	}

	.state__label {
		font-size: 10px;
		font-weight: 500;
		line-height: 1.3;
		letter-spacing: 0.05em;
		color: var(--cozh-grey);
		text-transform: uppercase;
	}

	.state__text {
		font-size: 13px;
		font-weight: 500;
		line-height: 1.3;
	}

	.state__date {
		margin-top: 20px;
	}

	.state__hint {
		font-size: 13px;
		font-weight: 400;
		line-height: 1.3;
		margin-bottom: 15px;
	}

	.state__checkups {
		border-top: 1px solid var(--cozh-light-grey);
	}

	.c-numbers {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		flex-wrap:wrap;
		width: 100%;
	}
	.c-numbers__item {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		width:calc((100% - 0px) / 3);
	}
	.c-numbers__round {
		height: 50px;
		width: 50px;
		min-height: 50px;
		min-width: 50px;
		border-radius: 50%;
		color: var(--cozh-white);
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		font-weight: 600;
		line-height: 1;
		margin-right: 10px;
	}
	.c-numbers__label {
		color: rgba(var(--cozh-black-code), .5);
		font-size: 10px;
		font-weight: 600;
		text-transform: uppercase;
		line-height: 1.3;
		letter-spacing: 0.50px;
		word-wrap: break-word;
	}
	.c-numbers--grey .c-numbers__round {
		background-color: var(--cozh-grey);
	}
	.c-numbers--blue .c-numbers__round {
		background-color: var(--cozh-blue);
	}
	.c-numbers--orange .c-numbers__round {
		background-color: var(--cozh-orange);
	}

	.link-default-dashed {
		font-size: 10px;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: 0.50px;
	}
	.link-default-dashed .btn--ico {
		transition: all .2s ease-out;
	}
	.state.open .link-default-dashed .btn--ico {
		transform: rotate(180deg);
	}

	.clock {
	    display: flex;
	    flex-direction: row;
	    align-items: center;
	    justify-content: flex-start;
	}
	.clock__item {
	    display: flex;
	    flex-direction: row;
	    align-items: center;
	    justify-content: flex-start;
	}
  	.clock__item:first-child {
    	margin-right: 20px;
  	}
  	.state__more-top {
  		display: flex;
  	}
  	.state__more-bottom {
  		display: none;
  		margin-top: 20px;
  	}
  	.state__more-checkups {
  		padding: 20px 25px;
  		border-top: 1px solid var(--cozh-light-grey);
  	}
  	.state.light .state__info {
  		margin-bottom: 0px;
  		height: 100%;
  		display: flex;
  		flex-direction: column;
  		align-items: flex-start;
  		justify-content: space-between;
  	}
  	.state.light .state__hint {
  		margin-bottom: 35px;
  	}
  	.state.light .state__more-bottom {
  		display: none;
  	}

  	.state__report {
  		margin-top: 20px;
  	}
	@media (max-width: 1300px) {
		.state__container {
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
		}
		.state__more-top {
	  		display: none;
	  	}
	  	.state__more-bottom {
	  		display: flex;
	  	}
		.state__left {
			width: 100%;
			max-width: 100%;
			min-width: 100%;
		}
		.state__right {
			padding-top: 0px;
		}
		.state__info {
			margin-bottom: 0px;
		}
		.state__right {
			border-left: none;
		}
		.state.light .state__hint {
  			margin-bottom: 15px;
  		}
	}

	@media (max-width: 950px) {
		.c-numbers {
			flex-direction: column;
		}
		.c-numbers__item {
			width: 100%;
		}
		.c-numbers__item:not(:last-child) {
			margin-bottom: 10px;
		}
		.c-br {
			display: none;
		}
	}


	@media (max-width: 767px) {
		.state__top {
			flex-direction: column;
		}
		.state__img {
		    aspect-ratio: 6/4;
		}
		.state__left {
		    padding-left: 20px;
		    padding-right: 20px;
		}
		.state__right {
		    padding-left: 20px;
		    padding-right: 20px;
		}
	}


</style>