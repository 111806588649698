<template>

  <TopMenu :headerText="null" />

  <MessageDialogue ref="messageDialogue" />
  <ConfirmDialogue ref="confirmDialogue" />

  <!-- Объекты -->
  <div class="block mt-1">
    <div class="block__header block__header--btn">
      Объекты
      <router-link class="btn btn--grey btn--sm" :to="{name: 'PageObjectsCreate'}">
        <img class="btn--ico btn--ico--left" src="@/assets/img/plus-blue-small.svg">
        <div class="btn--text"> Добавить  <span class="hidden-xs"> объект </span> </div>
      </router-link>
    </div>
    <div class="block__body">
      <grid-table :options="options">
        <template v-slot:default="{ item, reload }">
          <tr>
            <td v-if="id_role == 0" width="1px">{{item.id}}</td>
            <td>
              <span class="nowrap"> {{item.address}} </span>
            </td>
            <td>
              <!-- Редактировать -->
              <router-link class="link-default ms-3 fs-12" :to="{name:'PageObjectsView', params: {mkd_id:item.id}}">
                <img class="btn--ico btn--ico--left" src="@/assets/img/edit-blue.svg" style="margin-bottom: 2px;">
                <span> Исходные данные </span>
              </router-link>
            </td>
            <td style="width: 1px;">
              <div class="c-filed-row">

                <router-link class="link-default ms-3 fs-12" :to="{name: 'PageCheckupCreate', params: {mkd_id: item.id}}">
                  <img class="btn--ico btn--ico--left" src="@/assets/img/plus-blue-small.svg" style="margin-bottom: 1px;">
                  <span class="btn--text"> Новое обследование </span>
                  <div class="btn--loader"></div>
                </router-link>

                <router-link 
                  :to="{name: 'PageObjectsCheckupList', params: {mkd_id: item.id}}" 
                  class="link-default ms-3 fs-12"
                  v-if="(id_role == 1 || id_role == 2) && item.is_exist_checkups || id_role == 0"
                >
                  <img class="btn--ico btn--ico--left" src="@/assets/img/copy-white.svg">
                  <span> Обследования </span>
                </router-link>

                <span v-else class="ms-3 empty-text">
                  Нет обследований
                </span>

                <a class="link-default ms-3 fs-12" @click="deleteMkd($event, item.id, reload)" v-if="id_role == 0">
                  <img class="btn--ico btn--ico--left" src="@/assets/img/cross-blue.svg">
                  <span class="btn--text"> Удалить </span>
                  <div class="btn--loader"></div>
                </a>

              </div>
            </td>
          </tr>
        </template>
      </grid-table>
    </div>
  </div>

</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue'
  import GridTable from '@/components/ux/GridTable.vue';
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
</script>

<script>
    export default {
      name: 'PageObjects',
      data: () => ({

        organizations: [],

        options: {
          method: 'getObjects',
          display: 10,
          fields: [
            {key:'address', name:'Наименование'},
            {key: false, name:''},
            {key: false, name:''},
          ],
          filters: [
            {key: 'address', type: 'text'},
          ],
          defaultFilter: [],
        }
      }),
      
      created () {

        // Для админа организации
        if(this.id_role == 1) {
          // Добавляем в фильтр "Объекты организации"
          this.options.filters.push({key: 'id_organization', type: 'checkbox', label: 'Объекты организации', value: this.id_organization });
        }

        // Для рута
        if(this.id_role == 0) {
          
          // Выводим ID
          this.options.fields.splice(0, 0, {key: 'id', name: 'ID'})

          // Фильтр по организациям
          this.options.filters.splice(1, 0, { key: 'id_organization', type: 'select', list: [], label: 'Выберите организацию' });

          Promise.all([this.loadOrganizations()])
          
            .then(() => {
              // Данные для фильтра по организациям
              this.options.filters[1].list = this.organizations;
            })

            .catch(()=> {})

        }


      },

      computed: {
        id_role () {
          return this.$store.state.auth.user.profile.id_role;
        },
        id_organization () {
          return this.$store.state.auth.user.profile.id_organization;
        },
        id_user () {
          return this.$store.state.auth.user.profile.id;
        },
      },
      methods: {
        
        async loadOrganizations () {
          const { items } = await this.$store.dispatch('getOrganizations', {display: 1000, filter: {}, order: {}, page: 1});
          this.organizations = items;
        },

        async deleteMkd (e, id, reload) {
          const btn = e.currentTarget;
          btn.classList.add('btn--load');
          const confirm = await this.$refs.confirmDialogue.show({
            message: 'Вы уверены, что хотите удалить этот объект?',
            okButton: 'Удалить',
          });
          if(confirm) {
            try {
              await this.$store.dispatch('deleteMkd', {id_mkd: id});
              reload();
            } catch (e) {
              this.$refs.messageDialogue.show({message: this.$helpers.getErrorMessage(e), okButton: 'Подтвердить'});
            }
          }
          btn.classList.remove('btn--load');
        },
      },
    }
</script>

<style scoped>
  .c-filed-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
  }

  .empty-text {
    font-size: 12px;
    white-space: nowrap;
    color: var(--cozh-grey);
  }
</style>