<template>

  	<TopMenu :headerText="headerText" />
    <Breadcrumbs :breadcrumbs="breadcrumbs" />

    <MessageDialogue ref="messageDialogue" />
    <ConfirmDialogue ref="confirmDialogue" />

    <div class="block">
      <div class="block__body">
        <div class="content">
          <!-- Левая часть -->
          <div class="content__left">
            <!-- Поле ввода Фамилия -->
            <div class="field">
              <div class="field__left">
                Фамилия
              </div>
              <div class="field__right">
                <input class="form-control" type="text" placeholder="Фамилия" v-model="form.surname">
              </div>
            </div>
            <!-- Поле ввода Имя -->
            <div class="field">
              <div class="field__left">
                Имя
              </div>
              <div class="field__right">
                <input class="form-control" type="text" placeholder="Имя" v-model="form.name">
              </div>
            </div>
            <!-- Поле ввода Отчество -->
            <div class="field">
              <div class="field__left">
                Отчество
              </div>
              <div class="field__right">
                <input class="form-control" type="text" placeholder="Отчество" v-model="form.middle_name">
              </div>
            </div>
            <!-- Поле ввода Телефон -->
            <div class="field">
              <div class="field__left">
                Телефон
              </div>
              <div class="field__right">
                <input class="form-control" type="text" placeholder="Телефон" v-model="form.phone" v-mask="'+7 (###) ### ##-##'">
              </div>
            </div>
            <!-- Поле ввода Электронная почта -->
            <div class="field">
              <div class="field__left">
                Электронная почта
              </div>
              <div class="field__right">
                <input class="form-control" type="text" placeholder="Электронная почта" v-model="form.email">
              </div>
            </div>
            <!-- Разделитель  -->
            <hr />
            <!-- Поле ввода Пароль -->
            <div class="field">
              <div class="field__left">
                Пароль
              </div>
              <div class="field__right">
                <div class="form-group">
                  <input class="form-control form-control--password" type="password" placeholder="Пароль" v-model="form.password">
                  <span class="password-watch" ref="passwordWatchNew"></span>
                  <a class="link-default-dashed password-generate" @click="generatePassword($event)"> 
                    <div class="btn--loader"> </div>
                    <span class="btn--text"> Сгенерировать <div class="visible-md visible-lg" style="margin-left: 4px;"> пароль </div> </span> 
                  </a>
                </div>
                
              </div>
            </div>
            <!-- Подтвердите пароль -->
            <div class="field">
              <div class="field__left">
                Подтвердите пароль
              </div>
              <div class="field__right">
                <div class="form-group">
                  <input class="form-control form-control--password" type="password" placeholder="Подтвердите пароль" v-model="form.confirm_password">
                  <span class="password-watch" ref="passwordWatchConfirm"></span>
                </div>
              </div>
            </div>
            <template v-if="id_role == 0">
              <hr />
              <div class="field" v-if="!organization_id">
                <div class="field__left">
                  Организация
                </div>
                <div class="field__right">
                  <SelectPicker :label="'Организация'" :list="organizations" v-model="form.id_organization"/>
                </div>
              </div>
              <div class="field">
                <div class="field__left">
                  Роль в системе
                </div>
                <div class="field__right">
                  <SelectPicker :label="'Роль в системе'" :list="roles" v-model="form.id_role"/>
                </div>
              </div>
            </template>
          </div>
          <!-- Правая часть -->
          <div class="content__right">
            <ImagePicker v-model="form.avatar" :viewMode="false"/>
          </div>
        </div>
      </div>
      <div class="block__footer">
        <div class="btn-group">
          <a class="btn btn--default btn--md me-1" @click="register" :class="{'disabled': isDisabledRegister}">
            <div class="btn--text">Зарегистрировать</div>
            <div class="btn--loader"></div>
          </a>
          <a class="btn btn--outline btn--md" @click="$router.go(-1)">
            <div class="btn--text">Отменить</div>
            <div class="btn--loader"></div>
          </a>
        </div>
      </div>
    </div>	
</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue';
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
  import ImagePicker from '@/components/ux/ImagePicker.vue'
  import SelectPicker from '@/components/ux/SelectPicker.vue'
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
</script>

<script>
  export default {

    name: 'PageProfileEmployeeCreate',

    data: () => ({
      headerText: null,
      organizations: [],
      roles: [],
      breadcrumbs: [],
      form: {
        id_role: null,
        id_organization: null,
        surname: null,
        name: null,
        middle_name: null,
        email: null,
        avatar: null,
        password: null,
        phone: null,
        confirm_password: null,
      },
      required: ['surname', 'name', 'middle_name', 'email', 'phone', 'password', 'confirm_password'],
      organization: null,
    }),

    created () {

      // Админ ЦОЖ
      if(this.id_role == 0) {

        const loads = [this.loadRoles()];
        if(this.organization_id) {
          loads.push(this.loadOrganization());
        } else {
          loads.push(this.loadOrganizations());
        }

        Promise.all(loads)
          .then(() => {
            this.beforeRender();
          })
          .catch((e)=> {
            console.log(e);
          })

        // Эти поля становятся обязательными к заполнению
        this.required.push('id_role');
        this.required.push('id_organization');

      } else {

        this.beforeRender();

      }

    },

    computed: {
      isDisabledRegister () {
        const data = [];
        this.required.forEach((key) => {
          data.push(this.form[key] == null ? '' : this.form[key]);
        })
        return data.includes('') ? true : false;
      },
      id_role () {
        return this.$store.state.auth.user.profile.id_role;
      },
      id_organization () {
        return this.$store.state.auth.user.profile.id_organization;
      },
      organization_id () {
        return this.$route.params.organization_id;
      },
    },

    methods: {

      beforeRender () {

        if(!this.organization_id) {

          this.breadcrumbs = [
            {name: 'Пользователи', link: {name: 'PageUsers'}},
            {name: 'Добавить пользователя', link: ''},
          ];

        } else {

          this.breadcrumbs = [
            {name: this.organization.name, link: {name: 'PageOrganization', params: {organization_id: this.organization.id}}},
            {name: 'Добавить пользователя', link: ''},
          ];

        }

        // Админ организации
        if(this.id_role == 1) {

          // Создаёт обычного пользователя
          this.form.id_role = 2;
          // Ставим его организацию
          this.form.id_organization = this.id_organization;
        
        }

        if(this.id_role == 0 && this.organization_id) {

          this.form.id_organization = this.organization.id;

        }

      },

      async loadOrganizations () {
      	const {items} = await this.$store.dispatch('getOrganizations', {display: 1000, filter: {}, order: {}, page: 1});
        this.organizations = items;
      },
      async loadRoles () {
        this.roles = await this.$store.dispatch('getRoles');
      },
      async loadOrganization () {
        this.organization = await this.$store.dispatch('getOrganization', {id: this.organization_id});
      },

      async register (e) {
        const btn = e.currentTarget;
        btn.classList.add('btn--load');
        await new Promise(r => setTimeout(r, 500));
        if (this.form.password !== this.form.confirm_password) {
          this.$refs.messageDialogue.show({
            message: 'Пароли не совпадают',
            text: 'Попробуйте заново указать пароль или нажмите кнопку «Сгенерировать»',
            okButton: 'Подтвердить',
          });
          btn.classList.remove('btn--load');
          return true;
        }
        const data = {...this.form};
        delete data.confirm_password;
        try {
        	await this.$store.dispatch('createUser', data);
            this.$router.push({name: 'PageUsers'});
        } catch (e) {
          this.$refs.messageDialogue.show({message: e.message, okButton: 'Подтвердить'});
        }
        btn.classList.remove('btn--load');
      },
      async generatePassword (e) {
        const btn = e.currentTarget;
        btn.classList.add('btn--load');
        try {
          const { password } = await this.$store.dispatch('getPassword');
          await new Promise(r => setTimeout(r, 300));
          this.form.password = password;
          this.form.confirm_password = password;
          if(!this.$refs.passwordWatchNew.classList.contains('active')) {
            this.$refs.passwordWatchNew.click();
          }
          if(!this.$refs.passwordWatchConfirm.classList.contains('active')) {
            this.$refs.passwordWatchConfirm.click();
          }
        } catch (e) {
          this.$refs.messageDialogue.show({message: e.message, okButton: 'Подтвердить'});
        }
        btn.classList.remove('btn--load');
      },
    },
  }
</script>


<style scoped>
  .content {
    display: flex;
      flex-direction: row;
  }
  .content__right {
      padding: 20px;
      border-left: 1px solid var(--cozh-light-grey);
      min-width: 300px;
  }
  .content__left {
      width: 100%;
      padding: 20px 0px;
  }


  .field {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    min-height: 40px;
    padding: 0px 20px;
  }    
  .field__left {
    min-width: 400px;
    font-size: 13px;
    font-weight: 500;
  }
  .field__right {
    width: 100%;
    font-size: 13px;
    font-weight: 500;
  }
  .field:not(:last-child) {
    margin-bottom: 10px;
  }

    @media (max-width: 1200px) {
    .field__left {
      min-width: 200px;
    }
    }

    @media (max-width: 1000px) {
    .field {
      flex-direction: column;
      gap: 5px;
    }
    .field__left {
      width: 100%;
      font-weight: 400;
    }
    .field__right {
      font-weight: 600;
    }
    .field:not(:last-child) {
      margin-bottom: 15px;
    }
    .content__right {
      min-width: 350px;
    }

    }

    @media (max-width: 767px) {
      .content {
        flex-direction: column-reverse;
      }
      .content__right {
        border-left: none;
        border-bottom: 1px solid var(--cozh-light-grey);
        min-width: auto;
      }
      .btn-group {
        flex-direction: column;
        width: 100%;
      }
      .btn-group a {
        width: 100%;
      }
      .btn-group a:first-child {
        margin-right: 0px;
      }
      .btn-group a:last-child:not(:first-child) {
        margin-top: 10px;
      }

    }


</style>