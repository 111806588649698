<template>
	<div class="input-calendar" :class="{'binding': modelValue !== null}" @click="openCalendar()">
		<div class="input-calendar__label" v-if="modelValue == null"> {{ label }} </div>
		<div class="input-calendar__value" v-if="modelValue != null"> 
			{{ $moment(modelValue).format('DD.MM.YYYY') }} 
		</div>
		<img class="input-calendar__ico" src="@/assets/img/calendar.svg">
	</div>
	<CalendarDialogue ref="calendarDialogue" @setDate="setDate" />
</template>

<script setup>
  import CalendarDialogue from '@/components/modals/CalendarDialogue.vue';
</script>

<script>
	export default {
	    name: 'DatePicker',
	    props: [ 'modelValue', 'label' ],
	    methods: {
	    	openCalendar () {
      		this.$refs.calendarDialogue.show({
            label: this.label,
            date: this.modelValue,
          });
	    	},
	    	setDate (date) {
	    		this.$emit('update:modelValue', date);
	    	},
	    },
    }
</script>

<style scoped>

	.input-calendar {
		background-color: rgba(240, 245, 248, 1);
    border: none;
    border-radius: 8px;
    color: var(--cozh-black);
    padding: 0px 15px;
    width: 100%;
    font-size: 13px;
    font-weight: 600;
    transition: all .2s ease;
    height: 40px;
    min-height: 40px;
    box-sizing: border-box;
    border: 1px solid transparent;
    position: relative;
    padding-left: 45px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
	}

	.input-calendar__ico {
		left: 14px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
	}

	.input-calendar__value {

	}

	.input-calendar:hover {
    background-color: rgb(174 193 204 / 35%);
	}


</style>