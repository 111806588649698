<template>

  	<TopMenu :headerText="null" />
  	<Breadcrumbs :breadcrumbs="breadcrumbs" />
  	<CheckupNavigator />
  	<ConfirmDialogue ref="confirmDialogue" />
  	<MessageDialogue ref="messageDialogue" />

  	<div class="block">
  		<div class="block__header">
  			Информация о заказчике
  		</div>
  		<div class="block__body">
  			<div class="load-container" :class="{'load' : isLoad}">
			    <div class="content" v-if="!isLoad && !isError">
			    	<template v-if='id_role == 0'>
			    		<div class="field">
			    			<div class="row">
				    			<div class="col-xs-12 col-sm-6 col-md-6">
				    				<div class="label">Организация</div>
				    			</div>
				    			<div class="col-xs-12 col-sm-6 col-md-6">
				    				<div class="form-group">
	                    <SelectPicker :label="'Выберите организацию'" :list="organizations" v-model="info.id_organization"/>
	                  </div>
				    			</div>
				    		</div>
			    		</div>
			    		<div class="field" v-if="info.id_organization !== null">
			    			<div class="row">
				    			<div class="col-xs-12 col-sm-6 col-md-6">
				    				<div class="label">Обследователь</div>
				    			</div>
				    			<div class="col-xs-12 col-sm-6 col-md-6">
				    				<div class="form-group">
	                    <SelectPicker :label="'Выберите обследователя'" :list="users" v-model="info.id_user"/>
	                  </div>
				    			</div>
				    		</div>
			    		</div>
				    	<hr>
			    	</template>
			    	<div class="field" v-if="info.id_organization !== null">
		    			<div class="row">
			    			<div class="col-xs-12 col-sm-6 col-md-6">
			    				<div class="label">Договор</div>
			    			</div>
			    			<div class="col-xs-12 col-sm-6 col-md-6">
			    				<div class="form-group">
                    <SelectPicker :label="'Выберите договор'" :list="contracts" v-model="info.id_contract"/>
                  </div>
			    			</div>
			    		</div>
		    		</div>

		    		<div class="field">
		    			<div class="row">
			    			<div class="col-xs-12 col-sm-6 col-md-6">
			    				<div class="label">Наименование организации</div>
			    			</div>
			    			<div class="col-xs-12 col-sm-6 col-md-6">
		            	<DadataPicker label="Начните ввод" type="party" @setValue="setCustomer"/>
			    			</div>
			    		</div>
		    		</div>
		    		<div class="field field--data">
		    			<div class="row">
			    			<div class="col-xs-12 col-sm-6 col-md-6">
			    				<div class="label">ИНН</div>
			    			</div>
			    			<div class="col-xs-12 col-sm-6 col-md-6">
  								<div class="label"> {{ info.customer_inn == null ? '-' : info.customer_inn }} </div>
			    			</div>
			    		</div>
		    		</div>

			    </div>
			    <ErrorContent v-else/>
		  	</div>
  		</div>
    	<div class="block__footer">
        <a class="btn btn--outline btn--md me-1" @click="$router.go(-1)">
          <img class="btn--ico btn--ico--left" src="@/assets/img/6.svg">
          <div class="btn--text">Вернуться</div>
        </a>
        <a class="btn btn--default btn--md" :class="{'disabled': isDisabledBtn}" @click="createCheckup($event)">
          <div class="btn--text">Далее</div>
          <img class="btn--ico btn--ico--right" src="@/assets/img/arror-right-white.svg">
        </a>
      </div>
  	</div>
</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue';
  import CheckupNavigator from '@/components/checkups/CheckupNavigator.vue';
  import ErrorContent from '@/components/ErrorContent.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import Breadcrumbs from '@/components/Breadcrumbs.vue'
  import SelectPicker from '@/components/ux/SelectPicker.vue'
  import DadataPicker from '@/components/ux/DadataPicker.vue'
</script>

<script>
	export default {
	    name: 'PageCheckupCreate',

	    data: () => ({
	      isLoad: true,
	      isError: false,
	      mkd: null,
	      breadcrumbs: [],
	      info: {
				  id_organization: null,
				  id_user: null,
				  id_contract: null,
				  customer: null,
				  customer_inn: null,
				},
	      contracts: [],
	      organizations: [],
	      users: [],
	    }),
	    computed: {
	    	mkd_id () {
        	return this.$route.params.mkd_id;
      	},
      	id_role () {
      		return this.$store.state.auth.user.profile.id_role;
      	},
      	id_organization_auth () {
      		return this.$store.state.auth.user.profile.id_organization;
      	},
      	id_user_auth () {
      		return this.$store.state.auth.user.profile.id;
      	},
      	isDisabledBtn () {
      		if(this.info.id_organization == null || this.info.id_user == null || this.info.id_contract == null) {
      			return true;
      		}
      		if(this.info.customer == null || this.info.customer_inn == null) {
      			return true;
      		}
      		return false;
      	},
	    },
	    created () {

	    		const methods = [this.loadMkd()];

	    		if(this.id_role == 0) {
	    			methods.push(this.getOrganizations());
	    		} else {
	    			this.info.id_organization = this.id_organization_auth;
	    			this.info.id_user = this.id_user_auth;
	    		}

	      	Promise.all(methods)
	      	.then(() => {
	        	this.beforeRender();
	        	this.isLoad = false;
	      	})
	      	.catch((e)=> {
	        	console.log(e);
	        	this.isError = true;
	        	this.isLoad = false;
	      	})
	    },
	    watch: {
	    	'info.id_organization': function () {
	    		this.getContractsActive();
	    		this.info.id_contract = null;
	    		if(this.id_role == 0) {
	    			this.getUsers();
	    			this.info.id_user = null;
	    		}
	    	},
	    },
	    methods: {
      	beforeRender() {
        	this.breadcrumbs = [
		      	{name: this.$helpers.mkd_address(this.mkd), link: {name: 'PageObjectsView', params: {mkd_id: this.mkd_id}}},
		        {name: 'Новое обследование', link: ''},
          ];

          if(this.id_role == 2) {
	          // Для обследователя - на главную
	          this.breadcrumbs.unshift({name: 'Главная', link: {name: 'PageHome'}});
	        } else {
	          // Для всех остальных на объекты
	          this.breadcrumbs.unshift({name: 'Объекты', link: {name: 'PageObjects'}});
	        }
        
      	},

      	setCustomer (item) {
					if(item == null) {
						this.info.customer = null;
						this.info.customer_inn = null;
					} else {
						this.info.customer = item.value;
						this.info.customer_inn = item.data.inn;
					}
				},

      	async loadMkd () {
        	this.mkd = await this.$store.dispatch('getMkd', {id_mkd: this.mkd_id});
      	},
      	async getOrganizations () {
      		const param = {display: 1000, filter: {}, order: {}, page: 1};
      		const { items } = await this.$store.dispatch('getOrganizations', param);
      		this.organizations = items;
      	},
      	async getUsers () {
      		this.users = [];
      		const param = { display: 1000, page: 1, order: [], filter: {id_organization: this.info.id_organization}};
      		const { items } = await this.$store.dispatch('getUsers', param);
      		items.forEach((item) => {
      			if (item.id_role == 2) {
      				this.users.push({id: item.id, name: item.surname + ' ' + item.name + ' ' + item.middle_name});
      			}
      		})
      	},
      	async getContractsActive () {
      		this.contracts = [];
      		const param = this.id_role == 0 ? '?id_organization=' + this.info.id_organization : '';
      		const items = await this.$store.dispatch('getContractsActive', param);
      		items.forEach((item) => {
      			this.contracts.push({id: item.id, name: 'Договор №' + item.number + ' от ' + this.$moment(item.date).format('DD MMMM YYYY')});
      		})
      	},
      	async createCheckup (e) {
      		const btn = e.currentTarget;
      		btn.classList.add('btn--load');
      		let param = {
      			id_mkd: this.mkd_id,
      			id_contract: this.info.id_contract,
      			customer: this.info.customer,
      			customer_inn: this.info.customer_inn,
      			id_user: this.info.id_user,
      		}
      		try {
      			const checkup = await this.$store.dispatch('createCheckup', param);
          	this.$router.push({name: 'PageCheckupModules', params: {checkup_id: checkup.id, mkd_id: checkup.id_mkd}});
      		} catch (e) {
          	this.$refs.messageDialogue.show({ message: e.message, okButton: 'Подтвердить' });
        	}
      	}
	    }
	}
</script>

<style scoped>
	.block {
    border-radius: 0px 0px 8px 8px;
	}
	.block__body {
		padding: 20px 0px;
	}

	.label {
	  margin-top: 11px;
	}
	.field {
		padding: 0px 20px;
	}
	.field:not(:last-child) {
		margin-bottom: 10px;
	}

  @media (max-width: 767px) {
		.label {
    	margin-top: 0px;
    	margin-bottom: 8px;
		}
		.block__footer a {
			width: 50%;
		}	
	}

</style>
