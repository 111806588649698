<template>
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4729_14244)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8284 4.17157C11.2663 2.60948 8.73367 2.60948 7.17157 4.17157C5.60948 5.73367 5.60948 8.26633 7.17157 9.82843C7.65495 10.3118 8.53455 11.1738 9.29716 11.9186C9.55147 12.167 9.79219 12.4017 10 12.6043C10.2079 12.4017 10.4486 12.1668 10.703 11.9184C11.4657 11.1735 12.3453 10.3116 12.8284 9.82843C14.3905 8.26633 14.3905 5.73367 12.8284 4.17157ZM10 14L9.30289 14.717L8.88721 14.3124C8.63181 14.0637 8.28106 13.7218 7.89972 13.3494C7.1387 12.6061 6.25053 11.7358 5.75736 11.2426C3.41421 8.89949 3.41421 5.1005 5.75736 2.75736C8.10051 0.414213 11.8995 0.414214 14.2426 2.75736C16.5858 5.10051 16.5858 8.8995 14.2426 11.2426C13.7497 11.7356 12.8615 12.6059 12.1005 13.3492C11.7191 13.7217 11.3683 14.0636 11.1129 14.3123L10.6976 14.7165L10 14ZM10 14L10.6976 14.7165C10.3094 15.0939 9.69101 15.0943 9.30289 14.717L10 14Z" fill="#AEC1CC"/>
        <path d="M11 7C11 7.55228 10.5523 8 10 8C9.44772 8 9 7.55228 9 7C9 6.44772 9.44772 6 10 6C10.5523 6 11 6.44772 11 7Z" fill="#AEC1CC"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.31624 11.0516C3.84018 11.2262 4.12334 11.7926 3.94869 12.3165L2.38743 17.0003H17.6126L16.0513 12.3165C15.8767 11.7926 16.1598 11.2262 16.6838 11.0516C17.2077 10.8769 17.774 11.1601 17.9487 11.684L19.9487 17.684C20.0503 17.989 19.9992 18.3242 19.8113 18.585C19.6233 18.8458 19.3215 19.0003 19 19.0003H1.00001C0.678564 19.0003 0.376716 18.8458 0.188765 18.585C0.000813857 18.3242 -0.0503253 17.989 0.051324 17.684L2.05132 11.684C2.22597 11.1601 2.79229 10.8769 3.31624 11.0516Z" fill="#AEC1CC"/>
        </g>
        <defs>
        <clipPath id="clip0_4729_14244">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
    </svg>
</template>