<template>

  <div class="breadcrumbs">
    <div class="breadcrumbs__item">
      <component :is="ico" class="breadcrumbs__ico"></component>
      <div class="breadcrumbs__text"> {{ name }} </div>
    </div>
    <div class="breadcrumbs__item" v-if="headerText != null">
      <div class="breadcrumbs__text"> {{ headerText }} </div>
    </div>
  </div>

</template>

<script setup>
  import IconPageMain from '@/components/icons/pages/IconPageMain.vue';
  import IconPageCheckup from '@/components/icons/pages/IconPageCheckup.vue';
  import IconPageMap from '@/components/icons/pages/IconPageMap.vue';
  import IconPageArchive from '@/components/icons/pages/IconPageArchive.vue';
  import IconPageFAQ from '@/components/icons/pages/IconPageFAQ.vue';
  import IconPageSupport from '@/components/icons/pages/IconPageSupport.vue';
  import IconPageProfile from '@/components/icons/pages/IconPageProfile.vue';
  import IconPageUsers from '@/components/icons/pages/IconPageUsers.vue';
  import IconPageObjects from '@/components/icons/pages/IconPageObjects.vue';
  import IconPageContracts from '@/components/icons/pages/IconPageContracts.vue';
</script>


<script>
  export default {
    name: 'BreadcrumbMain',
    props: ['headerText'],
    components: {
      IconPageMain,
      IconPageCheckup,
      IconPageMap,
      IconPageArchive,
      IconPageFAQ,
      IconPageSupport,
      IconPageProfile,
      IconPageUsers,
      IconPageObjects,
      IconPageContracts,
    },
    data: () => ({
    }),
    computed: {
      name () {
        return this.$route.meta.name;
      },
      ico () {
        return this.$route.meta.ico;
      },
    },
    methods: {
    },
  }
</script>

<style scoped>

  .breadcrumbs {
    padding: 0px 20px;
    background-color: var(--cozh-white);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 60px;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .breadcrumbs::-webkit-scrollbar {
      display: none;
  }

  .breadcrumbs__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
  }

  .breadcrumbs__text {
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .breadcrumbs__ico {
    margin-right: 10px;
  }

  .breadcrumbs__item:first-child .breadcrumbs__text {
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  .breadcrumbs__item:not(:last-child) {
    padding-right: 25px;
  }

  .breadcrumbs__item:not(:last-child)::before {
    content: '';
    position: absolute;
    right: 10px;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background-color: var(--cozh-blue);
  }

  @media (max-width: 767px) {

    .breadcrumbs {
      height: 50px;
      padding: 0px 15px;
    }

  }

</style>