<template>

  	<TopMenu :headerText="headerText" />
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <MessageDialogue ref="messageDialogue" />
    <ConfirmDialogue ref="confirmDialogue" />

    <div class="block">
    	<div class="block__header">
    		Добавить договор
    	</div>
    	<div class="block__body">

    		<template v-if="!organization_id">
			  	<div class="field">
	  				<div class="row start-xs middle-xs">
	  					<div class="col-xs-12 col-sm-5 col-md-5">
	  						<div class="label">
	  							Организация
	  						</div>
	  					</div>
	  					<div class="col-xs-12 col-sm-7 col-md-7">
	              <SelectPicker :label="'Организация'" :list="organizations" v-model="contract.id_organization"/>
	  					</div>
	  				</div>
	  			</div>
		  		<hr />
    		</template>

        <div class="field">
  				<div class="row start-xs middle-xs">
  					<div class="col-xs-12 col-sm-5 col-md-5">
  						<div class="label">
  							Номер договора
  						</div>
  					</div>
  					<div class="col-xs-12 col-sm-7 col-md-7">
  						<div class="form-group">
  							<input class="form-control" type="text" placeholder="Номер договора" v-model="contract.number">
  						</div>
  					</div>
  				</div>
  			</div>
  			<div class="field">
  				<div class="row start-xs middle-xs">
  					<div class="col-xs-12 col-sm-5 col-md-5">
  						<div class="label">
  							Общее количество лицензий
  						</div>
  					</div>
  					<div class="col-xs-12 col-sm-7 col-md-7">
  						<div class="form-group">
  							<input class="form-control" type="number" placeholder="Общее количество лицензий" v-model="contract.number_licenses">
  						</div>
  					</div>
  				</div>
  			</div>
  			<div class="field">
  				<div class="row start-xs middle-xs">
  					<div class="col-xs-12 col-sm-5 col-md-5">
  						<div class="label">
  							Дата подписания
  						</div>
  					</div>
  					<div class="col-xs-12 col-sm-7 col-md-7">
  						<div class="form-group">
  							<DatePicker v-model="contract.date" :label="'Дата подписания'"/>
  						</div>
  					</div>
  				</div>
  			</div>
  			<div class="field">
  				<div class="row start-xs middle-xs">
  					<div class="col-xs-12 col-sm-5 col-md-5">
  						<div class="label">
  							Подписанный документ
  						</div>
  					</div>
  					<div class="col-xs-12 col-sm-7 col-md-7">
  						<FilePicker v-model="contract.file" />
  					</div>
  				</div>
  			</div>
	  		
    	</div>
    	<div class="block__footer">
    		<a class="btn btn--default btn--md me-1" @click="saveContract($event)" :class="{'disabled': isDisabledBtn}">
          	<div class="btn--text"> Сохранить </div>
          	<div class="btn--loader"></div>
        </a>
        <a class="btn btn--outline btn--md" @click="$router.go(-1)">
          	<div class="btn--text"> Отменить </div>
          	<div class="btn--loader"></div>
        </a>
    	</div>
    </div>
</template>

<script setup>
  	import TopMenu from '@/components/TopMenu.vue';
  	import Breadcrumbs from '@/components/Breadcrumbs.vue';
  	import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  	import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';

    import FilePicker from '@/components/ux/FilePicker.vue';
  	import DatePicker from '@/components/ux/DatePicker.vue';
  	import SelectPicker from '@/components/ux/SelectPicker.vue'
</script>

<script>
  export default {

    name: 'PageContractsCreate',

    data: () => ({
      headerText: null,
      breadcrumbs: [],
      contract: {
	    	number: null,
	    	number_licenses: null,
	    	file: null,
	    	date: null,
				id_organization: null,
			},
			organizations: [],
    }),

    created () {
      const loads = [];
      if(this.organization_id) {
        loads.push(this.loadOrganization());
      } else {
        loads.push(this.loadOrganizations());
      }
    	Promise.all(loads)
        .then(() => {
          this.beforeRender();
        })
        .catch((e)=> {
          console.log(e);
        })
    },

    computed: {
    	id_role () {
      	return this.$store.state.auth.user.profile.id_role;
    	},
   	 	organization_id () {
      	return this.$route.params.organization_id;
    	},
      isDisabledBtn () {
        for(let key in this.contract) {
          if(this.contract[key] == null || this.contract[key] + ''.trim() == '') {
            return true;
          }
        }
        return false;
      },
    },

    methods: {

    	beforeRender () {

    		this.contract.date = this.$moment().format('YYYY-MM-DD');

        if(!this.organization_id) {
        	
        	this.breadcrumbs = [
		      	{name: 'Договоры', link: {name: 'PageContracts'}},
		      	{name: 'Добавить договор', link: ''},
		    	];

        } else {

        	this.contract.id_organization = this.organization_id;

          this.breadcrumbs = [
            {name: this.organization.name, link: {name: 'PageOrganization', params: {organization_id: this.organization.id }}},
	      		{name: 'Добавить договор', link: ''},
          ];

        }

    	},


    	async saveContract (e) {
    		const btn = e.currentTarget;
    		btn.classList.add('btn--load');
      	const contract = { ...this.contract };
				contract.file = await this.$helpers.toBase64(contract.file);
  			try {
  				await this.$store.dispatch('createContract', contract);
          this.$router.push({name: 'PageContracts'});
  			} catch (e) {
          this.$refs.messageDialogue.show({message: e.message, okButton: 'Подтвердить'});
  			}
    		btn.classList.remove('btn--load');
      },

      async loadOrganization () {
        this.organization = await this.$store.dispatch('getOrganization', {id: this.organization_id});
        console.log(this.organization);
      },

      async loadOrganizations () {
      	const param = {display: 1000, filter: {}, order: {}, page: 1};
    		const { items } = await this.$store.dispatch('getOrganizations', param);
    		this.organizations = items;
    	},
    	
    },
  }
</script>


<style scoped>
	.block__body {
		padding: 20px 0px;
	}
	.block__footer {
		justify-content: flex-start;
	}
	.field {
		padding: 0px 20px;
	}
  .field:not(:last-child) {
    margin-bottom: 10px;
  }
  .field--data {
    margin-top: 20px;
  }
  @media (max-width: 767px) {
    .label {
      margin-top: 0px;
      margin-bottom: 8px;
    }
    .field:not(:last-child) {
      margin-bottom: 15px;
    }
  }
</style>