<template>

  	<TopMenu :headerText="null" />

	<MessageDialogue ref="messageDialogue" />
	<ConfirmDialogue ref="confirmDialogue" />

	<Chat :from="auth_id" :to="root_id"/>

</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue';
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
  import Chat from '@/components/support/Chat.vue';
</script>

<script>
  export default {
    name: 'RootChat',
    data: () => ({
		root_id: 13,
    }),
    computed: {
    	auth_id () {
        	return this.$store.state.auth.user.profile.id;
    	}
    },
  }
</script>
