<template>

  	<TopMenu :headerText="null" />

	<MessageDialogue ref="messageDialogue" />
	<ConfirmDialogue ref="confirmDialogue" />

	<router-link class="dialog" v-for="dialog in dialogs" :key="dialog.id_user" :to="{name: 'PageRootChat', params: {user_id: dialog.id_user}}">
		<div class="dialog__left">
			<div class="dialog__avatar" :style="{ backgroundImage: 'url(' + dialog.avatar + ')' }">
				
				<div class="dialog__badge" v-if="dialog.count_unread_messages > 0"> {{ dialog.count_unread_messages }}</div>

			</div>
			<div class="dialog__info">
				<div class="dialog__name">
					{{ dialog.user_surname + ' ' + dialog.user_name + ' ' + dialog.user_middlename }}
				</div>
				<div class="dialog__sup">
					<span> {{ dialog.role }} </span>
					<span> {{ dialog.organization }} </span>
				</div>
			</div>
		</div>
		<div class="dialog__right">
			<span> Последнее сообщение </span>
			<span> {{ $moment(dialog.date_last_message).format('DD.MM.YYYY — HH:mm') }} </span>
		</div>
	</router-link>

</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue';
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
</script>

<script>
  export default {
    name: 'RootDialogs',
    data: () => ({
			dialogs: [],
			timer: null,
    }),
    created () {
    	this.timer = setInterval(() => {
    			this.lodaDialogs();
    	}, 10000);
    	this.lodaDialogs();
    },
    beforeUnmount () {
			clearInterval(this.timer);
    },
    methods: {
    	async lodaDialogs () {
    		this.dialogs = await this.$store.dispatch('getDialogsRoot');
    	},
    },
  }
</script>

<style scoped>

	.dialog {
		background: #fff;
    	padding: 15px;
    	border-radius: 8px;
    	display: flex;
    	flex-direction: row;
    	align-items: flex-start;
    	justify-content: space-between;
	}

	.dialog:not(:last-child) {
		margin-bottom: 10px;
	}

	.dialog__left {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}
	.dialog__avatar {
		height: 70px;
		width: 70px;
		min-height: 70px;
		min-width: 70px;

		background-color: var(--cozh-light-grey);
		border-radius: 50%;
  	background-position: center;
  	background-size: cover;
  	background-repeat: no-repeat;
  	background-origin: border-box;
  	position: relative;
	}

	.dialog__badge {
		position: absolute;
		right: 0px;
		top: 0px;
		height: 20px;
		width: 20px;
		border-radius: 50%;
		background-color: var(--cozh-blue);
		font-size: 9px;
		font-weight: 700;
		color: #fff;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.dialog__info {
		display: flex;
		margin-left: 20px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}

	.dialog__name {
		font-size: 18px;
		font-weight: 500;
		line-height: 1.2;
	}

	.dialog__sup {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		margin-top: 10px;
	}

	.dialog__sup span {
		font-size: 15px;
		color: rgba(var(--cozh-black-code), .5);
		position: relative;
	}

	.dialog__sup span:first-child {
		margin-right: 30px;
	}	

	.dialog__sup span:first-child::before {
		position: absolute;
		content: '';
		height: 4px;
		width: 4px;
		border-radius: 50%;
		background-color: var(--cozh-grey);
		right: -15px;
		top: 50%;
		transform: translateY(-50%);
	}

	.dialog__right span:first-child {
		font-size: 13px;
		color: rgba(var(--cozh-black-code), .5);
		position: relative;
	}

	.dialog__right span:last-child {
		font-size: 13px;
		font-weight: 500;
		margin-left: 10px;
	}


  	@media (max-width: 767px) {

  		.dialog {
  			flex-direction:  column;
  		}
  		.dialog__sup {
  			flex-direction:  column;
  			align-items: flex-start;
  		}
  		.dialog__sup span:first-child {
		    margin-right: 0px;
		    margin-bottom: 5px;
			}
			.dialog__sup span:first-child::before {
				display: none;
			}
			.dialog__right {
				margin-top: 10px;
    		display: flex;
    		flex-direction: column;
    		margin-left: 85px;
			}
			.dialog__right span:last-child {
		    margin-left: 0px;
		    margin-top: 5px;
			}
			.dialog__left {
			  align-items: flex-start;
			}
			.dialog__info {
				margin-left: 15px;
			}


  	}
</style>
