import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  
  // admin
  // AuFsgbk3crq9

  // state.auth.user.profile.id_role
  // 0 - root (глава цож)
  // 1 - admin (Управляющий УК)
  // 2 - user (Обычный пользователь)

  state: {
    api_endpoint: 'https://тест.верньер.цож.рф/api',
    auth: {
      tokens: {
        access: localStorage.getItem('access_token') || null,
        refresh: localStorage.getItem('refresh_token') || null,
      },
      user: {
        profile: JSON.parse(localStorage.getItem('profile')) || null,
        organization: JSON.parse(localStorage.getItem('organization')) || null,
      },
    },
    modules: null,
    regions: null,
    unreadMessages: 0,
  },

  getters : {
    // bool статус авторизации
    isLoggedIn: state => !!state.auth.tokens.access,
    // Текущий пользователь
    authUser: state => state.auth.user,
  },
  

  mutations: {

    setTokens(state, {access_token, refresh_token}) {
      state.auth.tokens.access = access_token;
      state.auth.tokens.refresh = refresh_token;
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);
    },

    setProfile(state, {profile}) {
      state.auth.user.profile = profile;
      localStorage.setItem('profile', JSON.stringify(profile));
    },

    setOrganization(state, {organization}) {
      state.auth.user.organization = organization;
      localStorage.setItem('organization', JSON.stringify(organization));
    },

    logout(state) {

      state.auth.tokens.access = null;
      state.auth.tokens.refresh = null;
      state.auth.user.profile = null;
      state.auth.user.organization = null;

      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('profile');
      localStorage.removeItem('organization');
      
      delete axios.defaults.headers.common['Authorization'];
      
    },

    setModules(state, { modules }) {
      state.modules = modules;
    },
    setRegions(state, { regions }) {
      state.regions = regions;
    },
    setUnreadMessages(state, { count }) {
      state.unreadMessages = count;
    },
  },

  actions: {

    // Список модулей в системе
    async getModules ({commit, state}) {
      if (state.modules === null) {
        const r = await axios.get(state.api_endpoint + '/manual/modules');
        const modules = r.data;
        commit('setModules', { modules });
      }
    },

    // Количество непрочитанных сообщений
    async getUnreadMessages ({commit, state}) {
      const r = await axios.get(state.api_endpoint + '/chat/unread-messages');
      const count = r.data;
      commit('setUnreadMessages', {count});
    },
    
    // // Список регионов
    // async getRegions ({commit, state}) {
    //   if (state.regions === null) {
    //     const r = await axios.get(state.api_endpoint + '/manual/mkd_regions');
    //     const regions = r.data;
    //     commit('setRegions', { regions });
    //   }
    // },
    // // Список городов по региону
    // async getCities ({state}, id_region) {
    //   const r = await axios.get(state.api_endpoint + '/manual/regions/' + id_region + '/cities');
    //   return r.data;
    // },

    async getDialogsRoot ({state}) {
      const r = await axios.get(state.api_endpoint + '/chat/users-messages');
      return r.data;
    },

    async getDialogsMessages ({state}, param) {
      const r = await axios.get(state.api_endpoint + '/chat/last-messages/' + param.user_id);
      return r.data;
    },

    async setMessagesByRead ({state}, ids) {
      const r = await axios.patch(state.api_endpoint + '/chat/message-status', {ids: ids});
      return r.data;
    },




    // Получить данные для вопрос / ответ
    async getQuestions ({state}) {
      const r = await axios.get(state.api_endpoint + '/question/all');
      return r.data;
    },

    // Получить данные для вопрос / ответ
    async getMap ({state}) {
      const r = await axios.get(state.api_endpoint + '/map');
      return r.data;
    },



    // Получить дома
    async getObjects ({state}, param) {
      const r = await axios.post(state.api_endpoint + '/mkd', param);
      return r.data;
    },
    // Получить данные о доме
    async getMkd ({state}, param) {
      const r = await axios.get(state.api_endpoint + '/mkd/' + param.id_mkd);
      return r.data;
    },
    // Удалить дом
    async deleteMkd ({state}, param) {
      const r = await axios.delete(state.api_endpoint + '/mkd/' + param.id_mkd);
      return r.data;
    },
    // Создать дом
    async createMkd ({state}, param) {
      const r = await axios.post(state.api_endpoint + '/mkd/create', param);
      return r.data;
    },
    // Изменить базовую информацию о доме
    async updateMkd ({state}, param) {
      const r = await axios.patch(state.api_endpoint + '/mkd/' + param.id + '/update', param);
      return r.data;
    },



    // Получить все обследования в зависимости от роли
    async getCheckups ({state}, param) {
      const r = await axios.post(state.api_endpoint + '/mkd/checkups', param);
      return r.data;
    },
    // Создать обследование
    async createCheckup ({state}, param) {
      const r = await axios.post(state.api_endpoint + '/checkup/create', param);
      return r.data;
    },
    // Удалить обследование
    async deleteCheckup ({state}, param) {
      const r = await axios.delete(state.api_endpoint + '/checkup/' + param.id_checkup);
      return r.data;
    },
    


    // Получить информацию о заказчике обследования
    async getInfoByCheckup ({state}, param) {
      const r = await axios.get(state.api_endpoint + '/checkup/' + param.id_checkup + '/info');
      return r.data;
    },
    // Обновить информацию о заказчике обследования
    async updateInfoByCheckup ({state}, param) {
      const r = await axios.patch(state.api_endpoint + '/checkup/' + param.id + '/info', param);
      return r.data;
    },
    // Получить привязанные к обследованию модули
    async getModulesByCheckup ({state}, param) {
      const r = await axios.get(state.api_endpoint + '/checkup/' + param.id_checkup + '/modules');
      return r.data;
    },
    // Добавить модули для обследования
    async createModuleByCheckup ({state}, param) {
      const r = await axios.post(state.api_endpoint + '/checkup/modules', param);
      return r.data;
    },
    // Удалить модули для обследования
    async deleteModuleByCheckup ({state}, param) {
      const r = await axios.delete(state.api_endpoint + '/checkup/modules/', {data: param});
      return r.data;
    },



    // Получить список элементов у конкретного модуля
    async getElementsByModules ({state}, param) {
      const r = await axios.get(state.api_endpoint + '/manual/modules/' + param.id_module + '/elements');
      return r.data;
    },
    // Получить все элементы у обследования у модуля
    async getElementsByModulesByCheckup ({state}, param) {
      const r = await axios.get(state.api_endpoint + '/checkup/' + param.id_checkup + '/' + param.id_module + '/elements');
      return r.data;
    },
    // Добавить елемент у обследования
    async createElementByModuleByCheckup ({state}, param) {
      const r = await axios.post(state.api_endpoint + '/checkup/elements', param);
      return r.data;
    },
    // Удалить елемент у обследования
    async deleteElementByModuleByCheckup ({state}, param) {
      const r = await axios.delete(state.api_endpoint + '/checkup/elements/', {data: param});
      return r.data;
    },



    // Актуализировать схемы у обследования
    async patchSchemeByCheckup ({state}, param) {
      const r = await axios.patch(state.api_endpoint + '/checkup/modules/image', param);
      return r.data;
    },
    // Удалить схему у обследования
    async deleteSchemeByCheckup ({state}, param) {
      const r = await axios.delete(state.api_endpoint + '/checkup/modules/' + param.id_image + '/image');
      return r.data;
    },




    // Получить все ед. фрагменты у элемента 
    async getRegionsByElement ({state}, param) {
      const r = await axios.get(state.api_endpoint + '/checkup/' + param.id_checkup + '/' + param.id_module + '/' + param.id_element + '/regions');
      return r.data;
    },
    // Добавить ед фрагменты на элемент
    async createRegionByElement ({state}, param) {
      const r = await axios.post(state.api_endpoint + '/checkup/regions', param);
      return r.data;
    },
    // Удалить ед фрагменты на элементе
    async deleteRegionByElement ({state}, param) {
      const r = await axios.delete(state.api_endpoint + '/checkup/regions/', {data: param});
      return r.data;
    },
    // Изменить ед фрагменты на элементе
    async patchRegionByElement ({state}, param) {
      const r = await axios.patch(state.api_endpoint + '/checkup/regions', param);
      return r.data;
    },


    // Получить аналитику по всем МКД
    async getMkdByArchive ({state}) {
      const r = await axios.get(state.api_endpoint + '/archive/mkds');
      return r.data;
    },
    // Получить аналитику по определенному обследованию
    async getCheckupByArchive ({state}, param) {
      const r = await axios.get(state.api_endpoint + '/archive/checkup/' + param.id_checkup);
      return r.data;
    },
    // Получить аналитику по элементам конкретного модуля
    async getElementsByArchive ({state}, param) {
      const r = await axios.get(state.api_endpoint + '/archive/mkds/' + param.id_checkup + '/' + param.id_module);
      return r.data;
    },



    //  ====== Договоры =====
    
    // Получить активные договоры организации авторизованного пользователя
    async getContractsActive ({state}, param) {
      const r = await axios.get(state.api_endpoint + '/contracts/active' + param);
      return r.data;
    },
    // Получить список договоров
    async getContracts ({state}, param) {
      const r = await axios.post(state.api_endpoint + '/contracts', param);
      return r.data;
    },
    // Создать новый договор
    async createContract ({state}, param) {
      const r = await axios.post(state.api_endpoint + '/contracts/create', param);
      return r.data;
    },
    // Удалить договор
    async deleteContract ({state}, param) {
      const r = await axios.delete(state.api_endpoint + '/contracts/' + param.id);
      return r.data;
    },
    // Получить договор по ID
    async getContractById ({state}, param) {
      const r = await axios.get(state.api_endpoint + '/contracts/' + param.id);
      return r.data;
    },
    // Обновить данные договора
    async updateContract ({state}, param) {
      const r = await axios.patch(state.api_endpoint + '/contracts/' + param.id, param.data);
      return r.data;
    },



    // Получить список дефектов для конкретного элемента
    async getDefectsByElement ({state}, param) {
      const r = await axios.get(state.api_endpoint + '/manual/elements/' + param.id_element + '/defects');
      return r.data;
    },
    // Сформировать отчет
    async getReport ({state}, param) {
      const r = await axios.get(state.api_endpoint + '/reports/pdf?id_checkup=' + param.id_checkup );
      return r.data;
    },
    // Хлебные крошки 
    async getPath ({state}, param) {
      const r = await axios.post(state.api_endpoint + '/paths/full', param);
      return r.data;
    },



    // Сгенерировать пароль
    async getPassword ({state}) {
      const r = await axios.get(state.api_endpoint + '/lk/password-generate');
      return r.data;
    },
    // Изменить инфу о авторизованом пользователе
    async patchProfile ({state}, param) {
      const r = await axios.patch(state.api_endpoint + '/lk/profile', param);
      return r.data;
    },
    // Изменить пароль у авторизованного юзера
    async changePasswordByAuthUser ({state}, param) {
      const r = await axios.patch(state.api_endpoint + '/lk/change-password', param);
      return r.data;
    },

    

    // Получить список пользователей 
    async getUsers ({state}, param) {
      const r = await axios.post(state.api_endpoint + '/users', param);
      return r.data;
    },
    // Получить конкретного пользователя 
    async getUser ({state}, param) {
      const r = await axios.get(state.api_endpoint + '/users/' + param.id_user);
      return r.data;
    },
    // Изменить пароль у пользователя
    async changeUserPassword ({state}, param) {
      const r = await axios.patch(state.api_endpoint + '/users/' + param.id_user + '/change-password', {new_password: param.new_password});
      return r.data;
    },
    // Изменить профиль пользователя
    async changeUserProfile ({state}, param) {
      const r = await axios.patch(state.api_endpoint + '/users/' + param.id_user, param.data);
      return r.data;
    },
    // Зарегистрировать нового пользователя
    async createUser ({state}, param) {
      const r = await axios.post(state.api_endpoint + '/users/create', param);
      return r.data;
    },
    // Удалить пользователя
    async deleteUser ({state}, param) {
      const r = await axios.delete(state.api_endpoint + '/users/' + param.id_user);
      return r.data;
    },




    // Получить список организаций
    async getOrganizations ({state}, param) {
      const r = await axios.post(state.api_endpoint + '/manual/organizations', param);
      return r.data;
    },
    // Получить организацию по id(Для Рута)
    async getOrganization ({state}, param) {
      const r = await axios.get(state.api_endpoint + '/lk/root/organization/' + param.id);
      return r.data;
    },
    // Отредактировать
    async changeOrganization ({state}, param) {
      const r = await axios.patch(state.api_endpoint + '/lk/root/organization', param);
      return r.data;
    },
    // Создать нову организацию (Для Рута)
    async createOrganization ({state}, param) {
      const r = await axios.post(state.api_endpoint + '/lk/root/organization', param);
      return r.data;
    },





    // Получить список ролей в системе
    async getRoles ({state}) {
      const r = await axios.get(state.api_endpoint + '/manual/user/roles');
      return r.data;
    },
    // Получить метрики на главную
    async getMetrics ({state}) {
      const r = await axios.get(state.api_endpoint + '/metrics');
      return r.data;
    },


    // Выход из системы
    async logout({commit}) {
      commit('logout');
      return true;
    },

    // Авторизация
    async login({commit, state}, param ) {
      try {
        // Запрос авторизации
        const tokenResponse = await axios.post(state.api_endpoint + '/auth/token', param, {headers:{'Content-Type': 'application/x-www-form-urlencoded'}});
        // Всё прошло нормально
        if (tokenResponse.status == 200) {
          // Устанавливаем токены
          const access_token = tokenResponse.data.access_token;
          const refresh_token = tokenResponse.data.refresh_token;
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
          commit('setTokens', {access_token, refresh_token});

          // Информация о пользователе 
          const meResp = await axios.get(state.api_endpoint + '/lk/profile');
          
          const profile = meResp.data.profile;
          const organization = meResp.data.organization;

          commit('setProfile', {profile});
          commit('setOrganization', {organization});

          return true;
        }
      } catch (e) {e}
      commit('logout');
      return false;
    },

    // Обновление токенов
    async refresh ({commit, state}) {
      try {
        // Запрос обмена токенов
        const refreshResponse = await axios.post(state.api_endpoint + '/auth/refresh', { refresh_token: state.auth.tokens.refresh }, {headers:{'Content-Type': 'application/x-www-form-urlencoded'}});
        // Всё прошло нормально
        if (refreshResponse.status == 200) {
          // Устанавливаем токены
          const access_token = refreshResponse.data.access_token;
          const refresh_token = refreshResponse.data.refresh_token;
          commit('setTokens', {access_token, refresh_token});
          // Глобально в axios  
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
          return true;
        }
      } catch (e) {e}
      return false;
    },

  },

});
