<template>
    <popup-modal ref="popup" :size="'xs'" @close="close">
      <div class="popup">
				<div class="calendar">
					<div class="calendar__title"> {{ label }} </div>
					<!-- :lowerLimit="new Date($moment().add(1, 'days'))" -->
					<Datepicker v-model="picked" :locale="localeRU"/>
					<a @click="confirm" class="btn btn--default btn--sm btn--inline calendar__btn"> 
						<img class="btn--ico btn--ico--left" src="@/assets/img/check-white.svg">
						<div class="btn--text"> Выбрать </div>
						<div class="btn--loader"></div>
					</a>
				</div>
			</div>
    </popup-modal>
</template>

<script>

	import Datepicker from 'vue3-datepicker';
	import PopupModal from './PopupModal.vue';
	import { ru } from 'date-fns/locale';

  export default {
    name: 'CalendarDialogue',
    components: { PopupModal, Datepicker },

    data: () => ({
    	picked: null,
    	label: null,
    	date: null,
    	localeRU: ru,
    }),
    
    methods: {
    	show(opts = {}) {
    		
          this.label = opts.label;
          this.date = opts.date;

          this.$refs.popup.open();
      		this.sync();
      },
    	sync () {
      	this.picked = this.date !== null ? new Date(this.date) : null;
    	},
    	confirm () {
    		let date = this.picked !== null ? this.$moment(this.picked).format('YYYY-MM-DD') : null;
    		this.$emit('setDate', date);
    		this.close();
    	},
      close() {
        this.$refs.popup.close();
      }
    },
  };
</script>

<style scoped>
	.popup {
		padding: 20px;
    padding-top: 45px;
	}
	
	.calendar {
	}
	.calendar__title {
		font-size: 20px;
		font-weight: 500;
		line-height: 1.2;
		text-align: center;
		margin-bottom: 15px;
	}
	.calendar__btn {
		margin-top: 15px;
	}
  @media (max-width: 767px) {}
</style>