<template>
  <MessageDialogue ref="messageDialogue" />

  <div class="load-container" :class="{'load' : isLoad}">
	  <div class="content" v-if="!isLoad && !isError">

	  	<div class="block">
	  		<div class="block__body">

					<div class="block" v-for="(block, index) in fields" :key="index">
						<div class="block__header">
							{{ block.group_name }}
						</div>
						<div class="block__body">
							<div class="field" :class="{'field--view': !isEdit}" v-for="(field) in block.fields" :key="field.column_name">
								<div class="row" v-if="field.type_name != 'image'">
									<div class="col-xs-12 col-sm-6 col-md-6">
										<div class="label">
											{{ field.name }}
										</div>
									</div>
									<div class="col-xs-12 col-sm-6 col-md-6">
										<template v-if="isEdit">

											<div class="form-group" v-if="field.type_name == 'select'">
												<SelectPicker :list="field.choices" :label="field.name" v-model="info[field.column_name]"/>
											</div>
											
											<div class="form-group" v-if="field.type_name == 'text'">
												<input class="form-control" type="text" :placeholder="field.name" v-model="info[field.column_name]">
											</div>
											
											<div class="form-group" v-if="field.type_name == 'date'">
												<!-- <input class="form-control" type="number" :placeholder="field.name" > -->
												<DatePicker v-model="info[field.column_name]" :label="field.name" />
											</div>

											<div class="form-group" v-if="field.type_name == 'number'">
												<input class="form-control" type="number" :placeholder="field.name" v-model="info[field.column_name]">
											</div>

											<div class="form-group" v-if="field.type_name == 'checkbox'">
												<CheckboxesPicker :list="field.choices" :label="field.name" v-model="info[field.column_name]" :uniqname="field.column_name"/>
											</div>

										</template>
										<template v-else>
											<div class="c-value"> {{ info[field.column_name] }} </div>
										</template>
									</div>
								</div>
								<GalleryPicker v-model="info[field.column_name]" v-else :viewMode="!isEdit" />
							</div>
						</div>
					</div>
				</div>
				<div class="block__footer" v-if="id_role == 0 || (id_role !== 0 && data.total_area == null)">
					
        	<a class="btn btn--default btn--md" @click="isEdit = true" v-if="!isEdit">
	          <div class="btn--text"> Редактировать </div>
	          <div class="btn--loader"></div>
	        </a>
	        <div class="btn-group" v-if="isEdit">
		        <a class="btn btn--default btn--md me-1" @click="save($event)">
		          <div class="btn--text">Сохранить</div>
		          <div class="btn--loader"></div>
		        </a>
		        <a class="btn btn--outline btn--md" @click="cancelEdit">
		          <div class="btn--text">Отменить</div>
		          <div class="btn--loader"></div>
		        </a>
	        </div>
	    	</div>
	  	</div>

		</div>
	</div>
</template>

<script setup>
  import SelectPicker from '@/components/ux/SelectPicker.vue'
  import DatePicker from '@/components/ux/DatePicker.vue'
  import CheckboxesPicker from '@/components/ux/CheckboxesPicker.vue'
  import GalleryPicker from '@/components/ux/GalleryPicker.vue'
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
</script>

<script>
	export default {
		
		name: 'AdditionalInfo',

		props: ['mkd_id'],

		data: () => ({
  		isLoad: true,
  		isError: false,
  		isEdit: false,

  		data: null,
  		info: null,
  		fields: null,
    }),

    created () {
      Promise.all([this.loadInfo(), this.loadFields()])
	      .then(() => {
	        this.beforeRender();
	        this.isLoad = false;
	      })
	      .catch((e)=> {
	        console.log(e);
	        this.isError = true;
	        this.isLoad = false;
	      })
    },

    computed: {
	 		id_role () {
      	return this.$store.state.auth.user.profile.id_role;
    	},
    },

    methods: {
    	beforeRender() {

       	this.fields.forEach((block) => {
       		block.fields.forEach((field) => {
       			field.error = {
       				status: false,
       				message: '',
       			}
       			if(field.type_name == 'checkbox' || field.type_name == 'image') {
       				if(this.info[field.column_name] == null) {
       					this.info[field.column_name] = [];
       				}
       			}
       		});
       	});

       	if(this.info.total_area == null) {
  				this.isEdit = true; 
       	}

	    },
	    cancelEdit () {
  			this.isEdit = false; 
  			this.info = JSON.parse(JSON.stringify(this.data));
  		},
	    async loadInfo () {
        const r = await this.axios.get(this.$store.state.api_endpoint + '/mkd/' + this.mkd_id + '/info');
        this.data = r.data;
        this.info = JSON.parse(JSON.stringify(this.data));
	    },
	    async loadFields () {
        const r = await this.axios.get(this.$store.state.api_endpoint + '/mkd/info/fields');
        this.fields = r.data;
	    },
	    async save (e) {
	    	const btn = e.currentTarget;
	    	btn.classList.add('btn--load');
		    try {
		    	await this.axios.post(this.$store.state.api_endpoint + '/mkd/' + this.mkd_id + '/info', this.info);
		    	this.$refs.messageDialogue.show({
            message: 'Данные успешно сохранены!',
            okButton: 'Подтвердить',
          });
        	this.data = {...this.info};
  				this.isEdit = false; 
		    } catch (e) {
		    	this.$refs.messageDialogue.show({
            message: e.message,
            okButton: 'Подтвердить',
          });
		    }
	    	btn.classList.remove('btn--load');
	    },
    }
	}
</script>

<style scoped>

	.block {
		overflow: visible;
	}
	.block:not(:last-child) {
		margin-bottom: 10px;
	}
	.block .block {
		margin-bottom: 0px;
	}

	.block .block .block__body {
		padding: 20px;
	}
	.block .block:not(:first-child) .block__header {
		border-top: 1px solid var(--cozh-light-grey);
	}

	

	.field:not(:last-child) {
	    /*border-bottom: 1px solid var(--cozh-light-grey);*/
	    margin-bottom: 10px;
	}
	.field.field--view:not(:last-child) {
	  margin-bottom: 25px;
	}
	.label {
	  margin-top: 12px;
	}

	.c-value {
		font-size: 13px;
  	font-weight: 500;
	}

	.field--view .label {
	  margin-top: 0px;
	}

	@media (max-width: 767px) {
		.btn-group {
			width: 100%;
		}
		.block__footer a {
			width: 100%;
		}
		.label {
		  margin-top: 0px;
		  margin-bottom: 6px;
    	color: var(--cozh-dark-grey);
		}
		.field:not(:last-child) {
			margin-bottom: 20px;
		}
	}
	
</style>