<template>

	<TopMenu :headerText="null" />
    <Breadcrumbs :breadcrumbs="breadcrumbs" />

    <div class="block">
      	<div class="block__body">
        	<div class="content">
          		<div class="content__left">
		            <div class="field">
		            	<div class="row middle-xs">
		            		<div class="col-xs-12 col-sm-12 col-md-5">
		            			<div class="label"> Введите полный адрес объекта </div>
		            		</div>
		            		<div class="col-xs-12 col-sm-12 col-md-7">
		            			<DadataPicker label="Начните ввод" type="address" @setValue="setValue"/>
		            		</div>
		            	</div>
		            </div>
		            <hr />
		            <div class="field">
		            	<div class="row">
		            		<div class="col-xs-6 col-sm-6 col-md-5">
		            			<div class="label"> Регион </div>
		            		</div>
		            		<div class="col-xs-6 col-sm-6 col-md-7">
		            			<div class="c-value"> {{ mkd.region == null ? '-' : mkd.region }} </div>
		            		</div>
		            	</div>
		            </div>
		            <div class="field">
		            	<div class="row">
		            		<div class="col-xs-6 col-sm-6 col-md-5">
		            			<div class="label"> Город </div>
		            		</div>
		            		<div class="col-xs-6 col-sm-6 col-md-7">
		            			<div class="c-value"> {{ mkd.city == null ? '-' : mkd.city }} </div>
		            		</div>
		            	</div>
		            </div>
		            <div class="field">
		            	<div class="row">
		            		<div class="col-xs-6 col-sm-6 col-md-5">
		            			<div class="label"> Улица </div>
		            		</div>
		            		<div class="col-xs-6 col-sm-6 col-md-7">
		            			<div class="c-value"> {{ mkd.mkd_street == null ? '-' : mkd.mkd_street }} </div>
		            		</div>
		            	</div>
		            </div>
		            <div class="field">
		            	<div class="row">
		            		<div class="col-xs-6 col-sm-6 col-md-5">
		            			<div class="label"> Дом </div>
		            		</div>
		            		<div class="col-xs-6 col-sm-6 col-md-7">
		            			<div class="c-value"> {{ mkd.mkd_building == null ? '-' : mkd.mkd_building }} </div>
		            		</div>
		            	</div>
		            </div>
		            <div class="field">
		            	<div class="row">
		            		<div class="col-xs-6 col-sm-6 col-md-5">
		            			<div class="label"> Координаты объекта </div>
		            		</div>
		            		<div class="col-xs-6 col-sm-6 col-md-7">
		            			<div class="c-value"> {{ mkd.lat == null || mkd.long == null ? '-' : mkd.lat + ', ' + mkd.long }} </div>
		            		</div>
		            	</div>
		            </div>
          		</div>
          		<div class="content__right">
	            	<div class="row">
	            		<div class="col-xs-12 col-sm-12 col-md-12">
        					<ImagePicker v-model="mkd.image" :viewMode="false"/>
	            		</div>
	            	</div>
          		</div>
        	</div>
        </div>
        <div class="block__footer">
	        <a class="btn btn--default btn--md me-1" :class="{'disabled': isDisabledSave}" @click="saveMkd($event)">
	            <div class="btn--text">Сохранить</div>
	            <div class="btn--loader"></div>
	        </a>
	        <a class="btn btn--outline btn--md" @click="$router.go(-1)">
	            <div class="btn--text">Отменить</div>
	            <div class="btn--loader"></div>
	        </a>
	    </div>
    </div>


  	<MessageDialogue ref="messageDialogue" />
  	<ConfirmDialogue ref="confirmDialogue" />

</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue'
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
  import ImagePicker from '@/components/ux/ImagePicker.vue'
  // import SelectPicker from '@/components/ux/SelectPicker.vue'
  import DadataPicker from '@/components/ux/DadataPicker.vue'
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
</script>

<script>
	export default {
		name: 'PageObjectsCreate',

		data: () => ({
  			mkd: {
			  	region: null,
			  	city: null,
			  	mkd_street: null,
			  	mkd_building: null,
			  	lat: null,
			  	long: null,
			  	image: null,
			},
			required: ['region', 'city', 'mkd_street', 'mkd_building', 'lat', 'long'],
    	}),
	    created () {
	    	this.breadcrumbs = [
		        {name: 'Добавить объект', link: ''},
		    ];

		    if(this.id_role == 2) {
          // Для обследователя - на главную
          this.breadcrumbs.unshift({name: 'Главная', link: {name: 'PageHome'}});
        } else {
          // Для всех остальных на объекты
          this.breadcrumbs.unshift({name: 'Объекты', link: {name: 'PageObjects'}});
        }

	    },
	    computed: {
	    	isDisabledSave () {
	    		for(let key in this.required) {
	    			if(this.mkd[this.required[key]] == null) {
	    				return true;
	    			}
	    		}
	    		return false;
	    	},
	    	id_role () {
        	return this.$store.state.auth.user.profile.id_role;
      	},
	    },
    	methods: {
    		async saveMkd (e) {
    			const btn = e.currentTarget;
		        btn.classList.add('btn--load');
    			try {
		        	const {id} = await this.$store.dispatch('createMkd', this.mkd);
		          this.$router.push({name: 'PageObjectsView', params: {mkd_id: id}});
		        } catch (e) {
		          this.$refs.messageDialogue.show({message: e.message, okButton: 'Подтвердить'});
		        }
		        btn.classList.remove('btn--load');
    		},
    		setValue (item) {
    			if(item == null) {
    				this.mkd.region = null;
    				this.mkd.city = null;
    				this.mkd.mkd_street = null;
    				this.mkd.mkd_building = null;
    				this.mkd.lat = null;
    				this.mkd.long = null;
    			} else {
    				this.mkd.region = item.data.region;
    				this.mkd.city = item.data.city;
    				this.mkd.mkd_street = item.data.street;
    				this.mkd.mkd_building = item.data.house;
    				this.mkd.lat = item.data.geo_lat;
    				this.mkd.long = item.data.geo_lon;
    			}
    		}
    	},
	}
</script>

<style scoped>

	.block__footer {
		justify-content: flex-start;
	}
  	.content {
    	display: flex;
      	flex-direction: row;
 	}
  	.content__right {
     	padding: 20px;
      	border-left: 1px solid var(--cozh-light-grey);
      	min-width: 300px;
  	}
  	.content__left {
      	width: 100%;
      	padding: 20px 0px;
      	padding-bottom: 10px;
  	}

  	.field:not(:last-child) {
    	margin-bottom: 20px;
  	}

  	.field {
  		padding: 0px 20px;
  	}

  	.label {
  		margin-bottom: 0px;
  	}
  	.c-value {
  		font-size: 13px;
    	font-weight: 500;
  	}
  	
	@media (max-width: 992px) {
		.label {
		    margin-bottom: 6px;
    		color: var(--cozh-dark-grey);
		}
		.field:not(:last-child) {
	    	margin-bottom: 10px;
	  	}
	}

	@media (max-width: 767px) {
	  	.content {
	  		flex-direction: column;
		}
	  	.content__right {
	  		border-left: none;
	  		border-top: 1px solid var(--cozh-light-grey);
	  	}
	  	.block__footer a {
	  		width: 100%;
	  	}
	}
</style>